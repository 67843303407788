import client from '../../httpClient/clientV2';

export type FetchNotificationsResponse = Pagination & {
  notifications: Notification[];
};

export type Notification = {
  body: string;
  createdAt: string;
  header: string;
  id: string;
  isRead: boolean;
  section: string;
  typeId: string;
  clientName: string;
};

async function fetchNotifications({ language, searchParams }: { language: string; searchParams: string }) {
  const response = await client.get(`/notifications?lang=${language}&${searchParams}`);
  return response.data;
}

async function fetchNotificationById(id: string, language: string) {
  const response = await client.get(`/notifications/${id}?lang=${language}`);
  return response.data;
}

async function readNotifications(ids: string[]) {
  const response = await client.put('/notifications/read', { Ids: ids });
  return response.data;
}

async function fetchUnreadNotificationCount() {
  const response = await client.get('/notifications/newcount');
  return response.data;
}

export default Object.assign({
  fetchNotifications,
  fetchNotificationById,
  readNotifications,
  fetchUnreadNotificationCount,
});
