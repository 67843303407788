import axios from 'axios';
import { toast } from 'react-toastify';

const clientV1 = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

clientV1.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('seq_kk_token');

  if (token) {
    config.headers.Authorization = 'Bearer ' + token;
  }

  return config;
});

clientV1.interceptors.response.use(
  (response) => {
    const { method, url } = response.config;
    console.log(`[Axios ${method?.toUpperCase()} ${url}]`, response);
    return response;
  },
  (error) => {
    let message = String(error);
    console.log(error);
    if (axios.isAxiosError(error)) message = error.message;
    if (error.response?.data?.error?.message === 'grnz is in use already') {
      toast.error(
        localStorage.getItem('lang') === 'en' ? (
          <>
            The license plate number shown is already in use. If you are the owner of this vehicle, please contact us by
            email{' '}
            <a href="mailto:srv-help@z-it.ru" className="feedback-link">
              srv-help@z-it.ru
            </a>
          </>
        ) : (
          <>
            Указанный госномер уже используется. Если вы являетесь владельцем данного транспортного средства, просим Вас
            обратиться на почту{' '}
            <a href="mailto:srv-help@z-it.ru" className="feedback-link">
              srv-help@z-it.ru
            </a>
          </>
        )
      );
    } else if (error.response?.data?.message === '409 Conflict: User exists with same username') {
      toast.error('Пользователь с таким логином уже существует');
      // createBooking
    } else if (error.response?.data?.error?.message?.includes('err_cooldown_not_expired')) {
      toast.error(
        localStorage.getItem('lang') === 'en'
          ? 'Booking is not available. Try again later'
          : 'Бронирование недоступно. Попробуйте записаться позже'
      );
      // createBooking
    } else if (
      error?.response?.data === 'captcha failed' ||
      error?.response?.data?.error?.message === 'captcha failed'
    ) {
      // не показывать нотификацию
    } else if (error.response?.data?.message?.includes('invalidPasswordRegexPatternMessage')) {
      toast.error(
        <div>
          Пароль должен содержать:
          <div>- 10 и более символов;</div>
          <div>- прописные и строчные латинские буквы;</div>
          <div>- цифры;</div>
          <div>- символы {'!@#$%^&*()_|,.<>/?'}</div>
        </div>
      );
    } else if (error.response.status === 413) {
      toast.error(
        'При добавлении файла произошла ошибка. Пожалуйста, проверьте формат и размер файла и повторите попытку'
      );
    } else {
      toast.error(message);
    }
    return Promise.reject(error);
  }
);

export default clientV1;
