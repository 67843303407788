import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';

import InputText from '../../components/InputText';
import Button from '../../components/Button';
import Select from '../../components/Select';
import store from '../../store';
import InputPassword from '../../components/InputPassword';
import { User } from './api';
const { mappStore, userRegistrationStore } = store;

const initUser = {
  surname: '',
  notify_email: '',
  email: '',
  name: '',
  patronymic: '',
  password: '',
  mapp_id: '',
  role: '',
  passwordConfirmation: '',
};

const validateForm = (user: User) => {
  const isFilled = ['surname', 'email', 'name', 'notify_email', 'password', 'role', 'passwordConfirmation'].every(
    (key) => user[key as keyof typeof initUser]
  );
  return isFilled && user.password === user.passwordConfirmation && (user.role !== 'operator' || !!user.mapp_id);
};

function UserRegistration() {
  const { t } = useTranslation(['common', 'registration']);
  const navigate = useNavigate();
  const [user, setUser] = useState<User>(initUser);
  const [formValid, setFormValid] = useState<boolean>(false);

  useEffect(() => {
    mappStore.fetchMapps();
  }, []);

  const onChangeHandler = (key: string, value: string) => {
    const updateValue = { ...user, [key]: value };
    setUser(updateValue);
    setFormValid(validateForm(updateValue));
  };

  const onSaveHandler = async () => {
    const data: Partial<User> = { ...user };
    delete data.passwordConfirmation;
    const isSuccessResponse = await userRegistrationStore.createUser(data);
    if (isSuccessResponse) {
      toast.info(t('success_created', { ns: 'registration' }));
      navigate('/dictionary');
    }
  };

  return (
    <Container className="flex-grow-1 pb-3 pb-sm-5">
      <form autoComplete="one-time-code">
        <Row>
          <Col lg={9}>
            <Link to="/dictionary" className="link-back my-4">
              {t('back', { ns: 'common' })}
            </Link>
            <h2 className="mt-40px mb-4">{t('title', { ns: 'registration' })}</h2>

            <div className="bg-white rounded shadow-main p-4">
              <Row>
                <Col md={6}>
                  <InputText
                    label={t('surname', { ns: 'registration' }) || ''}
                    value={user?.surname as string}
                    onChange={(e: { target: { value: string } }) => onChangeHandler('surname', e.target.value)}
                    clear={() => onChangeHandler('surname', '')}
                    className="pb-4"
                  />
                </Col>
                <Col md={6}>
                  <InputText
                    label={t('email', { ns: 'registration' }) || ''}
                    value={user?.notify_email as string}
                    onChange={(e: { target: { value: string } }) => onChangeHandler('notify_email', e.target.value)}
                    clear={() => onChangeHandler('notify_email', '')}
                    className="pb-4"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <InputText
                    label={t('name', { ns: 'registration' }) || ''}
                    value={user?.name as string}
                    onChange={(e: { target: { value: string } }) => onChangeHandler('name', e.target.value)}
                    clear={() => onChangeHandler('name', '')}
                    className="pb-4"
                  />
                </Col>
                <Col md={6}>
                  <InputText
                    label={t('login', { ns: 'registration' }) || ''}
                    value={user?.email as string}
                    onChange={(e: { target: { value: string } }) => onChangeHandler('email', e.target.value)}
                    clear={() => onChangeHandler('email', '')}
                    className="pb-4"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <InputText
                    label={t('patronymic', { ns: 'registration' }) || ''}
                    value={user?.patronymic as string}
                    onChange={(e: { target: { value: string } }) => onChangeHandler('patronymic', e.target.value)}
                    clear={() => onChangeHandler('patronymic', '')}
                    className="pb-4"
                  />
                </Col>
                <Col md={6}>
                  <InputPassword
                    label={t('password', { ns: 'registration' }) || ''}
                    value={user?.password as string}
                    onChange={(e: { target: { value: string } }) => onChangeHandler('password', e.target.value)}
                    className="pb-4"
                    autoComplete="new-password"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Select
                    className="mb-3"
                    isCollapse
                    isSearchable
                    label={t('mapp_id', { ns: 'registration' }) || ''}
                    placeholder={t('select', { ns: 'common' })}
                    isClearable={true}
                    options={mappStore.mappOptions}
                    value={mappStore.mappOptions.find((option) => option.value === user.mapp_id)}
                    onChange={(e) => onChangeHandler('mapp_id', e?.value || '')}
                  />
                </Col>
                <Col md={6}>
                  <InputPassword
                    label={t('passwordConfirmation', { ns: 'registration' }) || ''}
                    value={user?.passwordConfirmation as string}
                    onChange={(e: { target: { value: string } }) =>
                      onChangeHandler('passwordConfirmation', e.target.value)
                    }
                    autoComplete="new-password"
                    className="pb-4"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex">
                  <Form.Check
                    value="administrator"
                    type="radio"
                    className="me-4"
                    aria-label={t('administrator', { ns: 'registration' }) || ''}
                    label={t('administrator', { ns: 'registration' }) || ''}
                    onChange={(e: { target: { value: string } }) => onChangeHandler('role', e.target.value)}
                    checked={user?.role === 'administrator'}
                  />
                  <Form.Check
                    value="operator"
                    type="radio"
                    aria-label={t('operator', { ns: 'registration' }) || ''}
                    label={t('operator', { ns: 'registration' }) || ''}
                    onChange={(e: { target: { value: string } }) => onChangeHandler('role', e.target.value)}
                    checked={user?.role === 'operator'}
                  />
                </Col>
              </Row>
              <hr />
              <div className="d-flex flex-column flex-sm-row gap-4">
                <Button
                  variant="primary"
                  text={t('save', { ns: 'common' })}
                  onClick={() => onSaveHandler()}
                  disabled={!formValid}
                  isLoading={userRegistrationStore.isLoading}
                />
                <Button
                  variant="outline"
                  text={t('cancel', { ns: 'common' })}
                  onClick={() => navigate('/dictionary')}
                />
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
export default observer(UserRegistration);
