import { useKeycloak } from '@react-keycloak/web';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import Icon from '../Icon';
import NotificationCount from './../NotificationCount';

type Props = {
  isClient: boolean;
  isAdmin: boolean;
  redirectLink: string;
  notificationsCount: number;
};

function ProfileSwitcher({ isClient, isAdmin, redirectLink, notificationsCount }: Props) {
  const { t } = useTranslation('header');
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const logOut = () => {
    localStorage.removeItem('seq_kk_token');
    window.dispatchEvent(new Event('storage'));
    keycloak.logout({ redirectUri: `${process.env.REACT_APP_BASE_URL || ''}${redirectLink}` });
  };

  const hasNotifications = (isClient || isAdmin) && !!notificationsCount;

  return (
    <Dropdown align="end">
      <Dropdown.Toggle
        variant="success"
        id="dropdown-profile"
        className="d-flex bg-transparent text-primary border-0 p-0"
      >
        <div className="d-flex position-relative">
          <Icon name="profile" />
          {hasNotifications && (
            <div
              style={{
                width: 10,
                height: 10,
                background: '#EE3F58',
                border: '2px solid #FFFFFF',
                borderRadius: '50%',
                position: 'absolute',
                top: 14,
                left: 14,
                flexGrow: 0,
              }}
            />
          )}
        </div>
        <Icon name="small_down" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {(isClient || isAdmin) && (
          <>
            <Dropdown.Item onClick={() => navigate('/notification')} className="d-flex">
              <div className="d-flex flex-shrink-0 align-items-center" style={{ width: 40 }}>
                <Icon name="bell" width={16} height={18} color="#66727F" />
              </div>
              <div className="d-flex justify-content-between align-items-center w-100">
                {t('notification')}
                {hasNotifications && <NotificationCount count={notificationsCount} className="ms-3" />}
              </div>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate('/profile/account')} className="d-flex">
              <div className="d-flex align-items-center" style={{ width: 40 }}>
                <Icon
                  name="user"
                  width={22}
                  height={24}
                  color="#66727F"
                  className="position-relative"
                  style={{ left: -1 }}
                />
              </div>
              {t('profile')}
            </Dropdown.Item>
          </>
        )}
        <Dropdown.Item onClick={logOut} className="d-flex">
          <div className="d-flex align-items-center" style={{ width: 40 }}>
            <Icon
              name="sing_out"
              width={22}
              height={24}
              color="#66727F"
              className="position-relative"
              style={{ left: -1 }}
            />
          </div>
          {t('logout')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default observer(ProfileSwitcher);
