import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { observer } from 'mobx-react-lite';
import Select from '../../../../../components/Select';
import store from '../../../../../store';
import { ADMIN_ROLE } from '../../../../../static';
const { bookingStore, filterStore, mappStore, authStore } = store;

type Props = {
  onChange: (key: string, value: string | (Date | null)[]) => void;
  ts_date_start: string;
  ts_date_end: string;
};

function BookingListFilter({ onChange, ts_date_start, ts_date_end }: Props) {
  const { t } = useTranslation(['common']);
  const [dateRange, setDateRange] = useState([null, null] as (Date | null)[]);
  const [startDate, endDate] = dateRange;
  const lang = localStorage.getItem('lang');
  const isAdmin = authStore.role === ADMIN_ROLE;

  const statusOptions: SelectOption[] = [
    {
      label: t('all_statuses', { ns: 'booking' }),
      value: 'all',
    },
    ...bookingStore.getBookingStatuses(lang),
  ];

  const trailerOptions: SelectOption[] = [
    { value: 'all', label: t('all_ts_types', { ns: 'booking' }) },
    { value: 'true', label: t('with_trailer', { ns: 'booking' }) },
    { value: 'false', label: t('trailerless', { ns: 'booking' }) },
  ];

  const mappOptions: SelectOption[] = [
    {
      label: t('all_apps', { ns: 'booking' }),
      value: 'all',
    },
    ...mappStore.mappOptions,
  ];

  useEffect(() => {
    const init = [
      ts_date_start && ts_date_start !== 'ALL_TIME' ? new Date(ts_date_start) : null,
      ts_date_end && ts_date_end !== 'ALL_TIME' ? new Date(ts_date_end) : null,
    ];
    setDateRange(init);
  }, [ts_date_start, ts_date_end]);

  return (
    <>
      {/* <Select
        isCollapse
        options={typeOptions}
        formatOptionLabel={({ label }) => <>{t(label, { ns: 'booking' })}</>}
        className="mb-3 mb-md-4"
        value={typeOptions.find((option) => option.value === filterStore.params.activity)}
        onChange={(e) => e?.value && updateFilter('activity', e.value)}
      /> */}
      <div className="bg-white rounded shadow-main p-3 mb-3 p-md-4 mb-md-4 ps-2">
        <div className="mb-2">{t('selecting_a_date_or_period', { ns: 'booking' })}</div>
        <DatePicker
          selectsRange
          locale={lang || 'ru'}
          startDate={startDate}
          endDate={endDate}
          className="w-100 form-control"
          dateFormat="dd.MM.yyyy"
          onChange={(update) => {
            setDateRange(update);

            if (!!update[0] === !!update[1]) {
              onChange('date', update);
            }
          }}
          isClearable={true}
        />
      </div>
      <div className="bg-white rounded shadow-main p-3 p-md-4 mb-md-4 ps-2">
        <Select
          className="mb-3"
          isCollapse
          options={statusOptions}
          value={statusOptions.find((option) => option.value === filterStore.params.status)}
          onChange={(e) => e?.value && onChange('status', e.value)}
        />
        <Select
          className="mb-3"
          isCollapse
          options={trailerOptions}
          value={trailerOptions.find((option) => option.value === filterStore.params.with_trailer)}
          onChange={(e) => e?.value && onChange('with_trailer', e.value)}
        />
        {isAdmin && (
          <Select
            className="mb-3"
            isCollapse
            isSearchable
            options={mappOptions}
            value={mappOptions.find((option) => option.value === filterStore.params.mapp_ids)}
            onChange={(e) => e?.value && onChange('mapp_ids', e.value)}
          />
        )}
      </div>
      {/* <Select
        isCollapse
        options={timeOptions}
        formatOptionLabel={({ label }) => <>{t(label, { ns: 'booking' })}</>}
        className="mb-3 mb-md-4"
        value={timeOptions.find((option) => option.value === filterStore.params.time)}
        onChange={(e) => e?.value && updateFilter('time', e.value)}
      />
      <Select
        isCollapse
        options={transportClassOptions}
        formatOptionLabel={({ label }) => <>{t(label, { ns: 'transport' })}</>}
        value={transportClassOptions.find((option) => option.value === filterStore.params.carClassId)}
        onChange={(e) => e?.value && updateFilter('carClassId', e.value)}
      /> */}
    </>
  );
}

export default observer(BookingListFilter);
