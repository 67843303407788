import { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import Loader from '../../../../components/Loader';
import InputSearch from './../../../../components/InputSearch';
import PaginationSet from './../../../../components/PaginationSet';
import EmptyResult from '../../../../components/EmptyResult';
import TerminalHeader from '../../../../components/TerminalHeader';

import store from '../../../../store';
import AdminTransportListFilter from './components/AdminTransportListFilter';
import Icon from '../../../../components/Icon';
const { transportStore, filterStore, isFSEQ } = store;

const initialParams: FilterParams = {
  validation_statuses: 'all',
  ts_date_start: 'ALL_TIME',
  ts_date_end: 'ALL_TIME',
  is_kiosk: 'all',
};

function AdminTransportList() {
  const { t } = useTranslation(['header', 'booking', 'common', 'transport']);
  const navigate = useNavigate();

  const { search } = useLocation();
  const [, setSearchParams] = useSearchParams();
  const [transportTypes, setTransportTypes] = useState<TransportType[]>([]);

  const isTerminalClient = isFSEQ();

  const fetchTransportTypes = useCallback(async () => {
    const transportTypes = await transportStore.fetchTransportTypes();
    setTransportTypes(transportTypes);
  }, []);

  useEffect(() => {
    fetchTransportTypes();
  }, [fetchTransportTypes]);

  useEffect(() => {
    filterStore.set(initialParams, search);
    transportStore.fetchTransport(filterStore.searchParams);
  }, [search]);

  const getTransportType = (carTypeId: string | number): string => {
    const type: TransportType | undefined = transportTypes.find(
      (transportType: TransportType) => String(transportType.typeId) === String(carTypeId)
    );

    return type?.name || '';
  };

  const updateFilter = (key: string, value: (string | string[]) | (Date | null)[]) => {
    if (key === 'search' || key === 'limit' || Object.keys(initialParams).includes(key)) {
      filterStore.update('page', '1');
    }
    if (key === 'date' && Array.isArray(value)) {
      const [ts_date_start, ts_date_end] = value;
      Promise.all([
        filterStore.update('ts_date_start', ts_date_start ? format(new Date(ts_date_start), 'yyyy-MM-dd') : 'ALL_TIME'),
        filterStore.update('ts_date_end', ts_date_end ? format(new Date(ts_date_end), 'yyyy-MM-dd') : 'ALL_TIME'),
      ]).then(() => setSearchParams(filterStore.filteredParams));
    } else if (Array.isArray(value)) {
      value.forEach((status) => {
        Promise.all([filterStore.update('validation_statuses', status ? status.toString() : '')]).then(() =>
          setSearchParams(filterStore.filteredParams)
        );
      });
    } else {
      filterStore.update(key, value).then(() => setSearchParams(filterStore.filteredParams));
    }
  };

  const isLoadResult = !transportStore.isLoading && !!transportStore.transportList?.length;
  const isLoadEmptyResult = !transportStore.isLoading && !transportStore.transportList?.length;

  return (
    <Container
      className={`${!isTerminalClient ? 'flex-grow-1 ' : 'm-auto terminal-min-height transform-y-180px '}pb-3 pb-sm-5`}
    >
      {isTerminalClient && <TerminalHeader />}
      {!isTerminalClient && <h2 className="mt-40px mb-4">{t('transport', { ns: 'header' })}</h2>}

      <Row>
        <Col xs={12} lg={isTerminalClient ? 12 : 9}>
          <div className="bg-white rounded shadow-main p-4 mb-4 d-none d-lg-block">
            <InputSearch
              placeholder={t('search', { ns: 'common' }) || ''}
              value={filterStore.params?.search || ''}
              onChange={(newValue: string) => updateFilter('search', newValue)}
            />
          </div>

          <div className={isTerminalClient ? 'd-block mb-3' : 'd-block d-lg-none mb-3'}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t('filters', { ns: 'booking' })}</Accordion.Header>
                <Accordion.Body>
                  <AdminTransportListFilter
                    onChange={updateFilter}
                    ts_date_start={filterStore.params.ts_date_start}
                    ts_date_end={filterStore.params.ts_date_end}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <div className="mb-4">
            {transportStore.isLoading && (
              <div className="bg-white rounded shadow-main">
                <Loader />
              </div>
            )}

            {isLoadResult && (
              <div className="bg-white rounded shadow-main">
                {transportStore.transportList?.map((transport: Transport, index: number) => (
                  <div
                    key={index}
                    className="p-3 p-md-4 border-bottom cursor-pointer"
                    style={{ borderColor: '#E1E1E1' }}
                    onClick={() => navigate(`/transport/${transport.car_id}`)}
                  >
                    <Row>
                      <Col xs={12} md={3}>
                        <span className="ms-3">{getTransportType(transport.car_type_id)}</span>
                      </Col>
                      <Col xs={12} md={isTerminalClient ? 3 : 4}>
                        <span className="ms-3">
                          {transport.name} {transport.model}
                        </span>
                      </Col>
                      <Col xs={12} md={isTerminalClient ? 5 : 3}>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>{transport.grnz}</span>
                        </div>
                      </Col>
                      <Col xs={12} md={isTerminalClient ? 3 : 2}>
                        <div className="d-flex align-items-center gap-2">
                          <Icon
                            name={transportStore.getTransportValidationStatusCode(transport.validation_status)}
                          ></Icon>
                          <span>
                            {t(transportStore.getTransportValidationStatusCode(transport.validation_status), {
                              ns: 'transport',
                            })}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))}
                <PaginationSet
                  items={Number(filterStore.params.total)}
                  page={Number(filterStore.params.page)}
                  pageCount={Number(filterStore.params.limit)}
                  isTerminalClient={isTerminalClient}
                  onPageCountChange={(page) => updateFilter('limit', String(page))}
                  onPageChange={(page) => updateFilter('page', String(page))}
                  className="py-4 px-28px"
                />
              </div>
            )}

            {isLoadEmptyResult && (
              <div className="bg-white rounded shadow-main">
                <EmptyResult text={t('vehicles_were_not_found', { ns: 'transport' })} />
              </div>
            )}
          </div>
        </Col>
        <Col lg={3} className={isTerminalClient ? 'd-none' : 'd-none d-lg-block'}>
          <AdminTransportListFilter
            onChange={updateFilter}
            ts_date_start={filterStore.params.ts_date_start}
            ts_date_end={filterStore.params.ts_date_end}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default observer(AdminTransportList);
