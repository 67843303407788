import { useEffect, useRef, useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { uid } from 'uid';
import useDebounce from '../../hooks/useDebounce';
import Icon from '../Icon';

function InputSearch({
  label = null,
  errorMsg,
  infoMsg,
  clear,
  className,
  onChange,
  value,
  ...props
}: InputExtendedProps) {
  const { current: id } = useRef(uid());

  const inputRef = useRef<HTMLInputElement>(null);

  const [inputText, setInputText] = useState('');

  const debounced = useDebounce(inputText);

  const isCrossVisible = value && !props.disabled;

  useEffect(() => {
    setInputText(value === undefined ? '' : value);
  }, [value]);

  useEffect(() => {
    if (debounced === undefined) return;

    onChange(inputText);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounced]); // TODO: deal with dependencies

  const onClearHandler = () => {
    clear && clear();
    inputRef.current && inputRef.current.focus();
  };

  const crossColor = () => {
    return props.isInvalid ? '#EE3F58' : '#9DACCE';
  };

  const Label = () => <Form.Label htmlFor={id}>{label}</Form.Label>;

  const ErrorMsg = () => <div className="text-danger fs-7 mb-1">{errorMsg}</div>;

  const InfoMsg = () => <div className="mt-2">{infoMsg}</div>;

  return (
    <FormGroup className={className}>
      {label && <Label />}

      {errorMsg && <ErrorMsg />}

      <div className="form-control-wrapper position-relative">
        <Form.Control
          type="search"
          id={id}
          ref={inputRef}
          autoComplete="off"
          spellCheck={false}
          isInvalid={!!errorMsg}
          onChange={(e) => setInputText(e.target.value)}
          value={inputText}
          {...props}
        />

        {isCrossVisible && (
          <Icon
            name="inputCross"
            className="position-absolute invisible"
            width={22}
            height={22}
            style={{ top: '1rem', right: '1.25rem', cursor: 'pointer', color: crossColor() }}
            onClick={onClearHandler}
          />
        )}
      </div>

      {infoMsg && <InfoMsg />}
    </FormGroup>
  );
}

export default InputSearch;
