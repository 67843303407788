import { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Popover, OverlayTrigger } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import Select from './../../../../components/Select';
import InputText from './../../../../components/InputText';
import Button from './../../../../components/Button';

import rootStore from '../../../../store';
import { TransportClassId } from '../../../../static';
import UploadFile from '../../../../components/UploadFile';
import Icon from '../../../../components/Icon';
import { downloadFile, uploadFile } from '../../../../api';
import Utils from './../../../../utils';
import TerminalHeader from '../../../../components/TerminalHeader';
import Modal from '../../../../components/Modal';
const { transportStore, isFSEQ } = rootStore;

type RouterParams = {
  id: string;
};

export type TransportFields = Partial<Transport>;

const initialValue: TransportFields = {
  grnz: '',
  model: '',
  name: '',
  document_uuid: '',
};

function AdminTransportSingle() {
  const { id } = useParams<RouterParams>();
  const { t, i18n } = useTranslation(['transport', 'common']);

  const navigate = useNavigate();
  const isTerminalClient = isFSEQ();

  const [transport, setTransport] = useState<TransportFields>(initialValue);
  const [transportTypes, setTransportTypes] = useState<TransportType[]>([]);
  const [transportOption, setTransportOption] = useState<SelectOption | null>(null);
  const [transportOptions, setTransportOptions] = useState<SelectOption[]>([]);
  const [transportDocument, setTransportDocument] = useState<File | null>(null);
  const [tsDocument, setTsDocument] = useState<Blob | null>(null);
  const [isTSDocumentModalVisible, setIsTSDocumentModalVisible] = useState(false);

  const fetchTransportTypes = useCallback(async () => {
    const transportTypes = await transportStore.fetchTransportTypes();
    setTransportTypes(transportTypes);
  }, []);

  const fetchTransportById = useCallback(async () => {
    if (!id) return;

    const transportById = await transportStore.fetchTransportById(id);

    if (!transportById) return;

    const transportForm = {
      ...transportById,
      document_uuid: transportById.document_uuid,
    };
    delete transportForm.grnzNormalized;

    setTransport(transportForm);
  }, [id]);

  const fetchTransportDocument = useCallback(async () => {
    if (!isTerminalClient && (!id || !transport.document_uuid)) return;
    if (!transport.document_uuid) return;

    const document = await downloadFile(transport.document_uuid);
    setTransportDocument(document);
  }, [id, transport.document_uuid, isTerminalClient]);

  useEffect(() => {
    fetchTransportById();
  }, [fetchTransportById, id]);

  useEffect(() => {
    fetchTransportTypes();
  }, [fetchTransportTypes]);

  useEffect(() => {
    fetchTransportDocument();
  }, [fetchTransportDocument, id]);

  useEffect(() => {
    const transportOptions = transportTypes?.map((transportType: TransportType) => ({
      value: String(transportType.typeId),
      label: transportType[i18n.language === 'en' ? 'nameEng' : 'name'],
    }));
    setTransportOptions(transportOptions);
  }, [i18n.language, transportTypes]);

  useEffect(() => {
    const transportOption: SelectOption | null =
      transportOptions.find(
        (transportOption: SelectOption) => String(transportOption.value) === String(transport.car_type_id)
      ) || null;
    setTransportOption(transportOption);
  }, [transportOptions, transport.car_type_id]);

  const changeStatus = async (status_id: number) => {
    if (transport?.id) {
      const successResponse = await transportStore.changeStatus(transport.id, status_id);
      if (successResponse.status === 200) {
        toast.info(`${t('status_ts_updated', { ns: 'transport' })}`);
        navigate('/transport');
      }
    }
  };

  const handleDownloadFile = async () => {
    try {
      transportStore.setLoading(true);
      const result = await downloadFile(transport.document_uuid || '');
      if (isTerminalClient) {
        const newBlob = new File([result], 'file', { type: result.type });
        setTsDocument(newBlob);
        setIsTSDocumentModalVisible(true);
      } else {
        Utils.downloadFile(result);
      }
    } catch (e) {
      transportStore.setLoading(false);
      console.log('Error downloading');
    } finally {
      transportStore.setLoading(false);
    }
  };

  const handleViewFile = async () => {
    try {
      transportStore.setLoading(true);
      const result = await downloadFile(transport.document_uuid || '');
      const newBlob = new File([result], 'file', { type: result.type });
      setTsDocument(newBlob);
      setIsTSDocumentModalVisible(true);
    } catch (e) {
      transportStore.setLoading(false);
      console.log('Error downloading');
    } finally {
      transportStore.setLoading(false);
    }
  };

  const renderUploadFile = () => {
    return (
      <UploadFile
        allowedFileTypes={['pdf', 'jpg', 'png', 'jpeg']}
        filename={
          (transportOption &&
            (String(transportOption.value) === TransportClassId.TRUCK ? t('truck_sts') : t('trailer_sts'))) ||
          ''
        }
        value={transportDocument}
        onDownload={handleDownloadFile}
        onView={handleViewFile}
        isLoading={transportStore.isLoading}
        isReadonly={true}
      />
    );
  };

  return (
    <>
      <Container
        className={`${
          !isTerminalClient ? 'flex-grow-1 ' : 'm-auto terminal-min-height transform-y-180px '
        }pb-3 pb-sm-5`}
      >
        {isTerminalClient && <TerminalHeader />}
        <Row>
          <Col lg={isTerminalClient ? 12 : 9}>
            <Link to="/transport" className="link-back my-4">
              {t('back', { ns: 'common' })}
            </Link>
            <h3 className="mb-4">{t('vehicle', { ns: 'booking' })}</h3>
            <Form>
              <Row className="bg-white p-4 shadow-main rounded">
                <div className="d-flex align-items-center gap-2 pb-3">
                  <Icon name={transportStore.getTransportValidationStatusCode(transport.validation_status)}></Icon>
                  <span>
                    {t(transportStore.getTransportValidationStatusCode(transport.validation_status), {
                      ns: 'transport',
                    })}
                  </span>
                </div>
                <Col md={7}>
                  <div className="pb-4">
                    <Select
                      label={t('vehicle_type') || ''}
                      placeholder={t('select_the_vehicle') || ''}
                      options={transportOptions}
                      value={transportOption}
                      formatOptionLabel={(option) => <>{t(option.label)}</>}
                      isDisabled={true}
                    />
                  </div>

                  {transportOption && String(transportOption.value) === TransportClassId.TRUCK ? (
                    <Row>
                      <Col md={6}>
                        <InputText
                          label={t('brand') || ''}
                          placeholder={t('enter_the_brand') || ''}
                          value={transport?.name as string}
                          disabled={true}
                          className="pb-4"
                        />
                      </Col>
                      <Col md={6}>
                        <InputText
                          label={t('model') || ''}
                          placeholder={t('enter_the_model') || ''}
                          value={transport?.model as string}
                          disabled={true}
                          className="pb-4"
                        />
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}

                  <Row>
                    <Col md={6}>
                      <div className="d-flex align-items-center gap-1">
                        <Form.Label htmlFor="gosnomer">{t('gosnomer') || ''}</Form.Label>
                      </div>
                      <InputText
                        id="gosnomer"
                        placeholder={t('enter_the_license_plate') || ''}
                        value={transport.grnz as string}
                        disabled={true}
                        className="pb-4"
                        maxLength="20"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="bg-white mt-4 p-4 shadow-main rounded">
                <Col>{renderUploadFile()}</Col>
                <div className="d-flex justify-content-center gap-4 mt-4">
                  <Button
                    type="button"
                    variant="primary"
                    text={t('verified', { ns: 'transport' }) || ''}
                    isLoading={transportStore.isLoading}
                    onClick={() => changeStatus(2)}
                  />
                  <Button
                    variant="outline"
                    text={t('rejected', { ns: 'transport' }) || ''}
                    onClick={() => changeStatus(3)}
                    disabled={transportStore.isLoading}
                  />
                </div>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>

      <Modal
        show={isTSDocumentModalVisible}
        hideCloseButton={false}
        onHide={() => setIsTSDocumentModalVisible(false)}
        header={
          <div className="d-flex justify-content-between align-items-center">
            <div>{t('view_ts', { ns: 'booking' }) || ''}</div>
            <Icon
              name="download"
              className="ms-2 cursor-pointer"
              width={22}
              height={22}
              onClick={() => Utils.downloadFile(tsDocument)}
            />
          </div>
        }
        body={<img className="w-100" src={(tsDocument && URL.createObjectURL(tsDocument as Blob)) || ''} alt="" />}
      />
    </>
  );
}

export default observer(AdminTransportSingle);
