import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Icon from './../Icon';
import { DASHBOARDS, DASHBOARDS_PROD } from '../../static';

function AnalyticMenu() {
  const navigate = useNavigate();
  const { t } = useTranslation('analytic');
  const dashboards = process.env.REACT_APP_BUILD_ENV === 'production' ? DASHBOARDS_PROD : DASHBOARDS;

  return (
    <Dropdown align="end">
      <Dropdown.Toggle className="bg-transparent text-primary border-0 d-flex p-0">
        {t('reports')} <Icon name="small_down" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {dashboards.map((dashboard) => (
          <Dropdown.Item key={dashboard.value} onClick={() => navigate(`/dashboard/${dashboard.value}`)}>
            {t(dashboard.label)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default AnalyticMenu;
