import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/Modal';
import Button from '../../../../../components/Button';
import { forwardRef } from 'react';

type Props = {
  title: string;
  body: string;
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

function ConfirmModal({ title, body, show, onClose, onSuccess }: Props) {
  const { t } = useTranslation(['common']);

  const successHandler = () => {
    onSuccess();
    onClose();
  };

  return (
    <Modal
      show={show}
      onHide={() => onClose()}
      header={title || ''}
      body={<>{body}</>}
      footer={
        <div className="d-flex flex-column flex-sm-row gap-3 gap-lg-4 w-100">
          <Button type="button" text={t('confirm')} onClick={() => successHandler()} />
          <Button type="button" variant="outline" onClick={() => onClose()} text={t('cancel')} />
        </div>
      }
    />
  );
}

export default forwardRef(ConfirmModal);
