import { makeAutoObservable } from 'mobx';

const initialFields: Mapp = {
  id: '',
  address: '',
  part_pp: '',
  part_pp_eng: '',
  name: '',
  platform_address: '',
  platform_address_eng: '',
  time_zone: 0,
  name_eng: '',
  address_eng: '',
  parking_capacity: 0,
  mapp_capacity: 0,
  lanes: 0,
  max_occupancy: 0,
  booking_days: 0,
  scheme_uuid: '',
  schedule: {
    schedule_items: {},
  },
};

type MappKeys = keyof typeof initialFields;

export class AppStore {
  fields: Mapp = initialFields;

  constructor() {
    makeAutoObservable(this);
  }

  initFields(newMapp: Mapp) {
    this.fields = { ...this.fields, ...newMapp };
  }

  changeField(key: MappKeys, value: string | number | { schedule_items: MappSchedule }) {
    if (typeof value === 'string') {
      (this.fields[key] as string) = value;
    } else {
      if (typeof value === 'number') {
        (this.fields[key] as number) = value;
      } else (this.fields[key] as { schedule_items: MappSchedule }) = value;
    }
  }

  clearFields() {
    this.fields = initialFields;
  }
}

const appStore = new AppStore();
export default appStore;
