import { useState } from 'react';
import Modal from '../components/Modal';
import Button from '../components/Button';

function ModalPage() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow} text="Открыть модальное окно" />

      <Modal
        show={show}
        onHide={handleClose}
        header="Отмена бронирования"
        body={<>Подтвердить отмену бронирования 4 августа 2022 года, 12:00-13:00?</>}
        footer={
          <>
            <Button variant="primary" onClick={handleClose} text="Подтвердить" className="me-4" />
            <Button variant="outline" onClick={handleClose} text="Отменить" />
          </>
        }
      />
    </>
  );
}

export default ModalPage;
