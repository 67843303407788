import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import Utils from './../../utils';

export type UploadFileProps = {
  allowedFileTypes: string[];
  filename?: string;
  allowedFileSize?: number;
  value?: File | null;
  documentButtonName?: string | null;
  isLoading?: boolean;
  isReadonly?: boolean;
  onlyView?: boolean;
  onChange?: (file: File, onSuccess: () => void, OnFailure: (error: any) => void) => void;
  onDelete?: (onSuccess: () => void) => void;
  onDownload?: () => void;
  onView?: () => void;
};

function UploadFile({
  allowedFileTypes,
  allowedFileSize = 5,
  filename,
  value,
  documentButtonName,
  isLoading,
  isReadonly,
  onlyView,
  onView,
  onDownload,
  onChange,
  onDelete,
}: UploadFileProps) {
  const [file, setFile] = useState<File | null>(null);
  const [dragging, setDragging] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFileLoading, setFileLoader] = useState<boolean>(false);
  const { t } = useTranslation(['upload', 'common']);

  useEffect(() => {
    setFile(value || null);
  }, [value]);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (validateFileSize(droppedFile) && validateFileType(droppedFile)) {
      setFileLoader(true);
      setFile(droppedFile);
      if (onChange) {
        onChange(
          droppedFile,
          () => {
            setFileLoader(false);
          },
          (error) => {
            setFile(null);
            setFileLoader(false);
          }
        );
        setErrorMessage('');
      }
    } else {
      if (!validateFileSize(droppedFile)) {
        setErrorMessage(`${t('allow_file_size_message', { ns: 'upload' })}`);
      } else if (!validateFileType(droppedFile)) {
        setErrorMessage(`${t('allow_file_types_message', { ns: 'upload' })} ${allowedFileTypes.join(', ')}`);
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      if (validateFileSize(selectedFile) && validateFileType(selectedFile)) {
        setFileLoader(true);
        setFile(selectedFile);
        if (onChange) {
          onChange(
            selectedFile,
            () => {
              setFileLoader(false);
            },
            (error) => {
              setFile(null);
              setFileLoader(false);
            }
          );
          setErrorMessage('');
        }
      } else {
        if (!validateFileSize(selectedFile)) {
          setErrorMessage(`${t('allow_file_size_message', { ns: 'upload' })}`);
        } else if (!validateFileType(selectedFile)) {
          setErrorMessage(`${t('allow_file_types_message', { ns: 'upload' })} ${allowedFileTypes.join(', ')}`);
        }
      }
    }
  };

  const validateFileSize = (file: File) => {
    return file && file.size > 0;
  };

  const validateFileType = (file: File) => {
    const allowedTypes = allowedFileTypes.map((type) => `.${type}`);
    const regex = new RegExp(`(${allowedTypes.join('|')})$`, 'i');
    return regex.test(file.name);
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete(() => {
        setFile(null);
        setErrorMessage('');
      });
    }
  };

  return (
    <>
      <div
        className={`seq-upload-area rounded${!file ? ' text-center' : ''} p-3 ${
          dragging ? 'seq-upload-area--dragging' : ''
        } ${isFileLoading ? 'seq-upload-area--loading spinner' : ''}`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {file ? (
          <div className="seq-upload-area__uploaded">
            <div className="d-flex align-items-center">
              <Icon
                name="badge"
                width={48}
                height={24}
                className={`d-sm-block me-2 extension-${file?.type?.split('/')?.pop()?.toLowerCase()}`}
              />
              {filename || t('default_filename')}
              <i className="text-secondary mx-2">|</i>
              <span className="text-secondary">{Utils.formatBytes(file.size)}</span>
            </div>
            <div className="d-flex gap-4">
              {onlyView ? (
                <button type="button" disabled={isLoading} className="btn btn-link" onClick={onView}>
                  {t('view', { ns: 'common' })}
                </button>
              ) : isReadonly ? (
                <>
                  <button type="button" disabled={isLoading} className="btn btn-link" onClick={onView}>
                    {t('view', { ns: 'common' })}
                  </button>
                  <button type="button" disabled={isLoading} className="btn btn-link" onClick={onDownload}>
                    {t('download', { ns: 'common' })}
                  </button>
                </>
              ) : (
                <>
                  <button type="button" className="btn btn-link text-danger" onClick={handleDelete}>
                    {t('delete', { ns: 'common' })}
                  </button>
                  <button type="button" disabled={isLoading} className="btn btn-link" onClick={onDownload}>
                    {documentButtonName}
                  </button>
                </>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className="mb-3">{t('drag_or_upload', { ns: 'upload' })}</div>
            <div>
              <span className="text-primary d-flex align-items-center justify-content-center">
                <Icon name="clip" width={24} height={24} className="me-1" />
                {t('select_file', { ns: 'upload' })}
              </span>
            </div>
            {errorMessage && <p>{errorMessage}</p>}
            <input type="file" accept={`.${allowedFileTypes.join(', .')}`} onChange={handleFileChange} />
          </div>
        )}
      </div>
      {!isReadonly && (
        <div className="d-flex justify-content-between mt-3">
          <div className="text-secondary">
            {t('file_extension', { ns: 'upload' })} {allowedFileTypes.join(', ')}
          </div>
          <div className="text-secondary">
            {t('file_size', { ns: 'upload' })} - {allowedFileSize} МБ
          </div>
        </div>
      )}
    </>
  );
}

export default forwardRef(UploadFile);
