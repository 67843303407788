function Spacing() {
  return (
    <>
      <section>
        <h4 className="my-3">Bootstrap spacing</h4>
        <p className="p-0 border">p-0 (0px)</p>
        <p className="p-1 border">p-1 (4px)</p>
        <p className="p-2 border">p-2 (8px)</p>
        <p className="p-3 border">p-3 (16px)</p>
        <p className="p-4 border">p-4 (24px)</p>
        <p className="p-5 border">p-5 (48px)</p>
        <h4 className="my-3">Custom spacing</h4>
        <p className="p-2px border">p-2px (2px)</p>
        <p className="p-12px border">p-12px (12px)</p>
        <p className="p-20px border">p-20px (20px)</p>
        <p className="p-28px border">p-28px (28px)</p>
        <p className="p-32px border">p-32px (32px)</p>
        <p className="p-36px border">p-36px (36px)</p>
        <p className="p-40px border">p-40px (40px)</p>
        <p className="p-44px border">p-44px (44px)</p>
      </section>
    </>
  );
}

export default Spacing;
