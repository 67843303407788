import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';

type Props = React.HTMLAttributes<HTMLElement> & {
  list: SelectOption[];
  value: SelectOption;
  onSelectItem: (option: SelectOption) => void;
};

const DropdownList = ({ list, value, onSelectItem, ...props }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('notification');

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onSelectItem]);

  const selectItemHandler = (item: SelectOption) => {
    onSelectItem(item);
    setIsOpen(false);
  };

  const reset = () => {
    onSelectItem(list[0]);
    setIsOpen(false);
  };

  return (
    <div ref={ref} className="position-relative" {...props}>
      <div className="cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
        <span className="me-2">{t('show')}:</span>
        {!isOpen && <span className="text-primary text-nowrap">{t(value.label)}</span>}
      </div>

      {isOpen && (
        <div
          className="position-absolute bg-white shadow-main rounded-4 p-4 gap-4 cursor-pointer"
          style={{ top: isOpen ? '-24px' : 0, left: isOpen ? '-24px' : 0 }}
        >
          <div className="d-flex align-items-start">
            <div>
              <div className="d-flex justify-content-between" onClick={() => setIsOpen(!isOpen)}>
                <span className="me-2">{t('show')}:</span>
              </div>
              {isOpen && (
                <div className="d-flex flex-column gap-3 mt-3">
                  {list.map((item) => (
                    <div key={item.value} onClick={() => selectItemHandler(item)} className="text-primary text-nowrap">
                      {item.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {isOpen && <Icon name="inputCross" className="cursor-pointer text-primary ms-2" onClick={reset} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownList;
