import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/Button';
import InputText from '../../../../components/InputText';
import Spinner from '../../../../components/Spinner';
import Modal from '../../../../components/Modal';

import store from '../../../../store';
const { profileStore } = store;

function EmailBlock() {
  const { t } = useTranslation(['profile', 'common']);
  const [newEmail, setNewEmail] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isFSEQ = store.isFSEQ();

  useEffect(() => {
    if (isEdit) setNewEmail('');
  }, [isEdit]);

  const onUpdateHandler = async () => {
    setIsLoading(true);
    const isSuccessResponse = await profileStore.updateProfile({ notify_email: newEmail });

    if (isSuccessResponse) {
      profileStore.fetchProfile();
      toast.info(t('data_changed', { ns: 'common' }));
      setNewEmail('');
      setIsEdit(false);
      setShowConfirmModal(false);
    }
    setIsLoading(false);
  };

  const Toggle = observer(() => (
    <div>
      <p className="mb-1 text-caption fs-7">Email</p>
      <div className="d-flex">
        {
          <h5 className="my-auto me-4">
            {profileStore.isLoading && <Spinner size={24} className="m-auto text-primary" />}
            {!profileStore.isLoading && profileStore.profile.notify_email}
          </h5>
        }
        <Link to="#" className={`${isFSEQ ? 'btn btn-primary' : 'link-primary'} fs-6`} onClick={() => setIsEdit(true)}>
          {t('change')}
        </Link>
      </div>
    </div>
  ));

  if (!isEdit) return <Toggle />;

  return (
    <div className="py-32px px-4 rounded-1 d-grid gap-32px" style={{ border: '1px dashed rgba(29, 93, 235, 0.18)' }}>
      <h5 className="m-0">
        {t('email_change')} {profileStore.profile.notify_email}
      </h5>

      <div className="d-flex align-items-center">
        <InputText
          label={t('new_email')}
          placeholder={t('enter_email') || ''}
          className="w-50 me-32px"
          value={newEmail}
          onChange={(e: any) => setNewEmail(e.target.value)}
          disabled={profileStore.isLoading}
        />
      </div>
      <div>
        <Button
          variant="primary"
          text={t('save', { ns: 'common' })}
          className="me-4"
          onClick={() => setShowConfirmModal(true)}
          isLoading={profileStore.isLoading}
          disabled={!newEmail}
        />
        <Button variant="outline" text={t('cancel', { ns: 'common' })} onClick={() => setIsEdit(false)} />
      </div>

      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        header={t('confirm_change_title', { ns: 'profile' })}
        body={
          <>
            {t('confirm_change_body', { ns: 'profile' })} {newEmail}?
          </>
        }
        footer={
          <>
            <Button
              variant="primary"
              onClick={onUpdateHandler}
              disabled={isLoading}
              text={t('confirm', { ns: 'common' })}
              className="me-4"
            />
            <Button variant="outline" onClick={() => setShowConfirmModal(false)} text={t('cancel', { ns: 'common' })} />
          </>
        }
      />
    </div>
  );
}

export default observer(EmailBlock);
