import { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Popover, OverlayTrigger } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import Select from './../../../../components/Select';
import InputText from './../../../../components/InputText';
import Button from './../../../../components/Button';

import rootStore from '../../../../store';
import { TransportClassId } from '../../../../static';
import UploadFile from '../../../../components/UploadFile';
import ConfirmModal from './components/ConfirmModal';
import Icon from '../../../../components/Icon';
import { downloadFile, uploadFile } from '../../../../api';
import Utils from './../../../../utils';
import ConfirmScanModal from '../../../../components/ConfirmScanModal/ConfirmScanModal';
import TerminalHeader from '../../../../components/TerminalHeader';
const { transportStore, isFSEQ } = rootStore;

type RouterParams = {
  id: string;
};

export type TransportFields = Partial<Transport>;

const initialValue: TransportFields = {
  grnz: '',
  model: '',
  name: '',
  document_uuid: '',
};

function TransportSingle() {
  const { id } = useParams<RouterParams>();
  const { t, i18n } = useTranslation(['transport', 'common']);

  const navigate = useNavigate();
  const isTerminalClient = isFSEQ();

  const [transport, setTransport] = useState<TransportFields>(initialValue);
  const [transportTypes, setTransportTypes] = useState<TransportType[]>([]);
  const [transportOption, setTransportOption] = useState<SelectOption | null>(null);
  const [transportOptions, setTransportOptions] = useState<SelectOption[]>([]);
  const [transportDocument, setTransportDocument] = useState<File | null>(null);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isConfirmScanModalVisible, setIsConfirmScanModalVisible] = useState(false);
  const [scanBlob, setScanBlob] = useState<Blob | null>(null);
  const [isScanPreview, setIsScanPreview] = useState<boolean>(false);
  const [onDeleteSuccess, setOnDeleteSuccess] = useState<(() => void) | null>(null);

  const fetchTransportTypes = useCallback(async () => {
    const transportTypes = await transportStore.fetchTransportTypes();
    setTransportTypes(transportTypes);
  }, []);

  const fetchTransportById = useCallback(async () => {
    if (!id) return;

    const transportById = await transportStore.fetchTransportById(id);

    if (!transportById) return;

    const transportForm = {
      ...transportById,
      document_uuid: transportById.document_uuid,
    };
    delete transportForm.grnzNormalized;

    setTransport(transportForm);
  }, [id]);

  const fetchTransportDocument = useCallback(async () => {
    if (!isTerminalClient && (!id || !transport.document_uuid)) return;
    if (!transport.document_uuid) return;

    const document = await downloadFile(transport.document_uuid);
    setTransportDocument(document);
  }, [id, transport.document_uuid, isTerminalClient]);

  useEffect(() => {
    fetchTransportById();
  }, [fetchTransportById, id]);

  useEffect(() => {
    fetchTransportTypes();
  }, [fetchTransportTypes]);

  useEffect(() => {
    fetchTransportDocument();
  }, [fetchTransportDocument, id]);

  useEffect(() => {
    const transportOptions = transportTypes?.map((transportType: TransportType) => ({
      value: String(transportType.typeId),
      label: transportType[i18n.language === 'en' ? 'nameEng' : 'name'],
    }));
    setTransportOptions(transportOptions);
  }, [i18n.language, transportTypes]);

  useEffect(() => {
    const transportOption: SelectOption | null =
      transportOptions.find(
        (transportOption: SelectOption) => String(transportOption.value) === String(transport.car_type_id)
      ) || null;
    setTransportOption(transportOption);
  }, [transportOptions, transport.car_type_id]);

  const onChangeHandler = (key: string, value: string) => {
    const updateValue = { ...transport, [key]: value };

    setTransport(updateValue);
  };

  const onCategoryChangeHandler = (options: SingleValue<SelectOption>) => {
    setTransportOption(options);
  };

  const transliterateGRNZ = (text: string) => {
    const transliterationTable: { [key: string]: string } = {
      А: 'A',
      В: 'B',
      Е: 'E',
      К: 'K',
      М: 'M',
      Н: 'H',
      О: 'O',
      Р: 'P',
      С: 'C',
      Т: 'T',
      У: 'Y',
      Х: 'X',
    };

    return text
      .split('')
      .map((char: string) => {
        const transliteratedChar = transliterationTable[char] || char;
        return transliteratedChar;
      })
      .join('');
  };

  const changePlateNumberHandler = (inputValue: string) => {
    const value = inputValue.replace(/[^АВЕКМНОРСТУХA-Za-z0-9]/gi, '').toUpperCase();
    onChangeHandler('grnz', value);
  };

  const changeWithMaskHandler = (field: string, inputValue: string) => {
    const value = inputValue.replace(/[^A-Za-zА-Яа-я0-9]/g, '');
    onChangeHandler(field, value);
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();

    if (transportOption === null) return;

    const requestParams: any = {
      ...transport,
      grnz: transliterateGRNZ(transport.grnz || ''),
      car_type_id: Number(transportOption.value),
      is_kiosk: isTerminalClient,
    };

    if (id) {
      const successResponse = await transportStore.updateTransport(id, requestParams);

      if (successResponse) {
        toast.info(
          `${t('data_ts_updated', { ns: 'common' })} ${successResponse.grnz} ${t('updated', { ns: 'common' })}`
        );
        navigate('/transport');
      }
    } else {
      const successResponse = await transportStore.createTransport(requestParams);

      if (successResponse) {
        toast.info(`${t('ts_add_number', { ns: 'common' })} ${successResponse.grnz}`);
        navigate('/transport');
      }
    }
  };

  const handleChangedFile = async (file: File, onSuccess: () => void, OnFailure: (error: any) => void) => {
    const formData = new FormData();
    formData.append('file', file);
    transportStore.setLoading(true);
    try {
      const result = await uploadFile(formData);
      onChangeHandler('document_uuid', result.file_uuid);
      onSuccess();
    } catch (error) {
      transportStore.setLoading(false);
      OnFailure(error);
    } finally {
      transportStore.setLoading(false);
    }
  };

  const handleDownloadFile = async () => {
    try {
      transportStore.setLoading(true);
      const result = await downloadFile(transport.document_uuid || '');
      if (isTerminalClient) {
        setIsScanPreview(true);
        const newBlob = new File([result], 'file', { type: result.type });
        setScanBlob(newBlob);
        setIsConfirmScanModalVisible(true);
      } else {
        Utils.downloadFile(result);
      }
    } catch (e) {
      transportStore.setLoading(false);
      console.log('Error downloading');
    } finally {
      transportStore.setLoading(false);
    }
  };

  const handleConfirmDelete = (onSuccess: () => void) => {
    setIsConfirmModalVisible(true);
    setOnDeleteSuccess(() => onSuccess);
  };

  const handleDeleteSuccess = () => {
    if (onDeleteSuccess) {
      onDeleteSuccess();
      setOnDeleteSuccess(null);
      onChangeHandler('document_uuid', '');
    }
  };

  const uploadFileHint = (
    <Popover className="seq-popover">
      <Popover.Header as="h6">{t('info_about_sts')}:</Popover.Header>
      <Popover.Body>
        <ul className="seq-popover__small">
          <li>{t('grnz')}</li>
          <li>{t('vin')}</li>
          <li>{t('model_etc')}</li>
        </ul>
      </Popover.Body>
    </Popover>
  );

  const plateNumberHint = (
    <Popover className="seq-popover">
      <Popover.Header as="h6">{t('info_about_number_plate')}:</Popover.Header>
      <Popover.Body>
        <ul className="seq-popover__small">
          <li>{t('license_plate_like_sts')}</li>
          <li>{t('license_plate_rules')}</li>
          <li>{t('license_plate_without_spaces')}</li>
        </ul>
      </Popover.Body>
    </Popover>
  );

  const isValid = () => {
    const isValidFields = transport.grnz !== '' && transport.document_uuid !== '';
    const isValidSelect = transportOption !== null;

    return isValidFields && isValidSelect;
  };

  const handleUploadScan = async () => {
    setIsScanPreview(false);
    const result = await transportStore.scanDocument();
    if (result instanceof Blob) {
      setScanBlob(result);
      setIsConfirmScanModalVisible(true);
    }
  };

  const handleScanAgain = () => {
    handleUploadScan();
    setIsConfirmScanModalVisible(false);
    setScanBlob(null);
  };

  const handleUploadScanSuccess = async () => {
    try {
      transportStore.setLoading(true);
      const formData = new FormData();
      formData.append('file', scanBlob as Blob, 'filename');
      const result = await uploadFile(formData);

      onChangeHandler('document_uuid', result.file_uuid);
      setIsConfirmScanModalVisible(false);
      setScanBlob(null);
    } catch (e) {
      transportStore.setLoading(false);
    } finally {
      transportStore.setLoading(false);
    }
  };

  const renderUploadFile = () => {
    if (isTerminalClient && !transport?.document_uuid) {
      return (
        <div className="d-flex align-items-center">
          <Button text={t('scan_upload')} onClick={handleUploadScan} isLoading={transportStore.scanLoading} />
          {transportStore.scanLoading && <span className="text-secondary px-3">{t('doc_scanning')}</span>}
        </div>
      );
    }
    return (
      <UploadFile
        allowedFileTypes={['pdf', 'jpg', 'png', 'jpeg']}
        filename={
          (transportOption &&
            (String(transportOption.value) === TransportClassId.TRUCK ? t('truck_sts') : t('trailer_sts'))) ||
          ''
        }
        value={transportDocument}
        documentButtonName={isTerminalClient ? t('view', { ns: 'common' }) : t('download', { ns: 'common' })}
        onChange={handleChangedFile}
        onDelete={handleConfirmDelete}
        onDownload={handleDownloadFile}
        isLoading={transportStore.isLoading}
      />
    );
  };

  return (
    <>
      <Container
        className={`${
          !isTerminalClient ? 'flex-grow-1 ' : 'm-auto terminal-min-height transform-y-180px '
        }pb-3 pb-sm-5`}
      >
        {isTerminalClient && <TerminalHeader />}
        <Row>
          <Col lg={isTerminalClient ? 12 : 9}>
            <Link to="/transport" className="link-back my-4">
              {t('back', { ns: 'common' })}
            </Link>
            <h3 className="mb-4">{id ? t('editing_a_vehicle') : t('adding_a_vehicle')}</h3>
            <Form onSubmit={onSubmitHandler}>
              <Row className="bg-white p-4 shadow-main rounded">
                {id && (
                  <div className="d-flex align-items-center gap-2 pb-3">
                    <Icon name={transportStore.getTransportValidationStatusCode(transport.validation_status)}></Icon>
                    <span>
                      {t(transportStore.getTransportValidationStatusCode(transport.validation_status), {
                        ns: 'transport',
                      })}
                    </span>
                  </div>
                )}
                <Col md={7}>
                  <div className="pb-4">
                    <Select
                      label={t('vehicle_type') || ''}
                      placeholder={t('select_the_vehicle') || ''}
                      options={transportOptions}
                      value={transportOption}
                      formatOptionLabel={(option) => <>{t(option.label)}</>}
                      onChange={(option) => onCategoryChangeHandler(option)}
                      isDisabled={transportStore.isLoading}
                    />
                  </div>

                  {transportOption && String(transportOption.value) === TransportClassId.TRUCK ? (
                    <Row>
                      <Col md={6}>
                        <InputText
                          label={t('brand') || ''}
                          placeholder={t('enter_the_brand') || ''}
                          value={transport?.name as string}
                          onChange={(e: { target: { value: string } }) => changeWithMaskHandler('name', e.target.value)}
                          clear={() => onChangeHandler('name', '')}
                          disabled={transportStore.isLoading}
                          className="pb-4"
                        />
                      </Col>
                      <Col md={6}>
                        <InputText
                          label={t('model') || ''}
                          placeholder={t('enter_the_model') || ''}
                          value={transport?.model as string}
                          onChange={(e: { target: { value: string } }) =>
                            changeWithMaskHandler('model', e.target.value)
                          }
                          clear={() => onChangeHandler('model', '')}
                          disabled={transportStore.isLoading}
                          className="pb-4"
                        />
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}

                  <Row>
                    <Col md={6}>
                      <div className="d-flex align-items-center gap-1">
                        <Form.Label htmlFor="gosnomer">{t('gosnomer') || ''}</Form.Label>
                        <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={plateNumberHint}>
                          <div className="d-inline-block">
                            <Icon name="question-mark" width={24} height={24} className="cursor-pointer" />
                          </div>
                        </OverlayTrigger>
                      </div>
                      <InputText
                        id="gosnomer"
                        placeholder={t('enter_the_license_plate') || ''}
                        value={transport.grnz as string}
                        onChange={(e: { target: { value: string } }) => changePlateNumberHandler(e.target.value)}
                        clear={() => onChangeHandler('grnz', '')}
                        disabled={transportStore.isLoading}
                        className="pb-4"
                        maxLength="20"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="bg-white mt-4 p-4 shadow-main rounded">
                <Col>
                  <h5>{t('upload_neccessary_docs')}</h5>
                  <p className="text-secondary">{t('upload_neccessary_docs_hint')}</p>
                  <div className="my-4 fw-bold d-flex align-items-center gap-1">
                    {t('file_must_have_only_face_side')}{' '}
                    <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={uploadFileHint}>
                      <div className="d-inline-block">
                        <Icon name="question-mark" width={24} height={24} className="cursor-pointer" />
                      </div>
                    </OverlayTrigger>
                  </div>
                  {renderUploadFile()}
                </Col>
                <div className="d-flex justify-content-center gap-4 mt-4">
                  <Button
                    type="submit"
                    variant="primary"
                    text={t('save', { ns: 'common' }) || ''}
                    disabled={!isValid()}
                    isLoading={transportStore.isLoading}
                  />
                  <Button
                    variant="outline"
                    text={t('cancel', { ns: 'common' }) || ''}
                    onClick={() => navigate('/transport')}
                    disabled={transportStore.isLoading}
                  />
                </div>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>

      <ConfirmModal
        title={t('delete_sts_file_title')}
        body={t('delete_sts_file_body')}
        show={isConfirmModalVisible}
        onClose={() => setIsConfirmModalVisible(false)}
        onSuccess={handleDeleteSuccess}
      />
      <ConfirmScanModal
        show={isConfirmScanModalVisible}
        imgScr={(scanBlob && URL.createObjectURL(scanBlob as Blob)) || ''}
        isScanPreview={isScanPreview}
        onScanAgain={handleScanAgain}
        onSuccess={handleUploadScanSuccess}
        onClose={() => setIsConfirmScanModalVisible(false)}
        isLoading={transportStore.isLoading}
      />
    </>
  );
}

export default observer(TransportSingle);
