import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import Pagination, { PaginationProps } from '../Pagination';

const pageCounts = [10, 20, 40, 80];

type PaginationSetProps = React.HTMLAttributes<HTMLElement> & {
  pageCount?: number;
  items?: number;
  isTerminalClient?: boolean;
  onPageCountChange: (page: number) => void;
};

function PaginationSet({
  page = 1,
  pageCount = 10,
  items = 0,
  marginPagesDisplayed: margin = 2,
  edgePagesDisplayed: edge = 1,
  onPageChange,
  onPageCountChange,
  ...props
}: PaginationSetProps & PaginationProps) {
  const { t } = useTranslation('common');

  return (
    <div className={`d-flex flex-column flex-lg-row ${props.className}`}>
      <div className="d-flex justify-content-between align-items-center flex-grow-1 me-lg-5">
        {!props.isTerminalClient && (
          <div className="d-flex align-items-end">
            <span className="me-3">{t('show')}:</span>
            <Dropdown align="end">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-language"
                className="bg-transparent text-primary border-0 d-flex gap-2 p-0 fs-9"
                size="sm"
              >
                {pageCount}
                <Icon name="small_down" width={20} height={20} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {pageCounts.map((item: number) => (
                  <Dropdown.Item key={item} onClick={() => onPageCountChange(item)}>
                    {item}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        <span className="fs-7">
          {t('total')} {items}
        </span>
      </div>
      <Pagination
        count={Math.ceil(items / pageCount)}
        page={page}
        marginPagesDisplayed={margin}
        edgePagesDisplayed={edge}
        onPageChange={onPageChange}
        className="mx-auto mx-lg-0 mt-3 mt-lg-0"
      />
    </div>
  );
}

export default PaginationSet;
