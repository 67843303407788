import { CSSProperties } from 'react';
import Icon from '../components/Icon';

function Iconka({ name, width, height, color }: { name: string; width?: number; height?: number; color?: string }) {
  const style: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '.5rem',
    minWidth: '5rem',
    color,
  };

  return (
    <div key={name} style={style}>
      <Icon name={name} width={width} height={height} />
      <span>{name}</span>
    </div>
  );
}

function IconGroup({ names }: { names: string[] }) {
  return (
    <div className="d-flex flex-column">
      {names.map((name) => (
        <Iconka key={name} name={name} />
      ))}
    </div>
  );
}

function IconsShowcase() {
  const groups: { [k: string]: string[] } = {
    small: ['small_down'],
    group1: ['bike', 'bus', 'gruzovie', 'legkovie', 'mobile'],
    group2: ['check', 'close', 'finish', 'time'],
    group3: ['call', 'mail', 'profile', 'language', 'user', 'sing_out', 'bell', 'user_full'],
    group4: ['inputCross', 'eyeClose', 'eyeOpen', 'date', 'menu_points', 'barrier'],
    group5: ['reply', 'save', 'edit', 'calendar', 'trash'],
    group6: ['arrow_left', 'arrow_right'],
  };

  return (
    <div className="text-primary">
      <div className="d-flex">
        <div className="d-grid">
          <Icon name={'accept'} width={80} height={80} />
          accept
          <Icon name={'search_question'} width={96} height={96} />
          search_question
          <Icon name={'small_left'} width={6} height={10} />
          small_left
        </div>

        {Object.keys(groups).map((key) => (
          <IconGroup key={key} names={groups[key]} />
        ))}
      </div>
    </div>
  );
}

export default IconsShowcase;
