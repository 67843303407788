import { Form } from 'react-bootstrap';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { observer } from 'mobx-react-lite';

type Props = {
  show: boolean;
  onClose: () => void;
};

function DeleteModal({ show, onClose }: Props) {
  return (
    <Modal
      show={show}
      onHide={onClose}
      header="Удалить учётную запись"
      body={
        <div className="d-grid gap-4">
          <span>Это приведёт к потере всех ваших данных без возможности восстановления</span>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Введите пароль</Form.Label>
              <Form.Control type="password" />
              {/* onChange={(e) => setPassword(e.target.value)} */}
            </Form.Group>
          </Form>
          <span>Уверены, что хотите удалить вашу учётную запись?</span>
        </div>
      }
      footer={
        <>
          <Button variant="primary" onClick={() => console.log('some action')} text="Удалить" className="me-4" />
          <Button variant="outline" onClick={onClose} text="Оставить" />
        </>
      }
    />
  );
}

export default observer(DeleteModal);
