import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { Helmet } from 'react-helmet';
import Header from './components/Header';
import Footer from './components/Footer';
import ProfileLayout from './entity/Profile';
import Account from './entity/Profile/Account';
import Info from './entity/Profile/Info';
import TransportList from './entity/Transport/pages/list';
import TransportSingle from './entity/Transport/pages/single';
import Booking from './entity/Booking/pages/list';
import BookingSingle from './entity/Booking/pages/single';
import BookingProcess from './entity/BookingProcess';
import NotificationList from './entity/Notification/pages/list';
import NotificationSingle from './entity/Notification/pages/single';
import MappList from './entity/Mapp/pages/list';
import DictionaryList from './entity/Dictionary/pages/list';
import DictionaryCategoryList from './entity/Dictionary/pages/category/list';
import DictionaryCategorySingle from './entity/Dictionary/pages/category/single';
import DictionaryAppList from './entity/Dictionary/pages/app/list';
import DictionaryAppSingle from './entity/Dictionary/pages/app/single';
import MetabaseDashboard from './entity/Metabase/pages/dashboard';
import AdminTransportList from './entity/AdminTransport/pages/list';
import AdminTransportSingle from './entity/AdminTransport/pages/single';

import Loader from './components/Loader';
import ToastifyContainer from './components/ToastifyContainer';
import ClientLayout from './components/ClientLayout';
import UiKit from './UiKit';

import './styles/bs_seq.scss';

import faviconLight from './assets/favicon-light.png';
import faviconDark from './assets/favicon-dark.png';

import { observer } from 'mobx-react-lite';

import store from './store/';
import { ADMIN_ROLE, ANALYTIC_ROLE, CLIENT_ROLE } from './static';
import InactivityWarning from './components/InactivityWarning';
import UserRegistration from './entity/UserRegistration';

const { authStore, isFSEQ } = store;

registerLocale('ru', ru);

function App() {
  const [faviconUrl, setFaviconUrl] = useState('');
  const isTerminalClient: boolean = isFSEQ();

  useEffect(() => {
    const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

    const faviconUrl = isDarkMode ? faviconLight : faviconDark;
    setFaviconUrl(faviconUrl);

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
      const isDarkMode = event.matches;

      const faviconUrl = isDarkMode ? faviconLight : faviconDark;
      setFaviconUrl(faviconUrl);
    });
  }, []);

  useEffect(() => {
    if (isTerminalClient) {
      const root = document.getElementsByTagName('html')[0];
      root.classList.add('fseq');
    }
  }, [isTerminalClient]);

  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return <Loader className="vh-100 d-flex" />;
  }

  if (!keycloak?.authenticated) {
    keycloak?.login();
    return <Loader className="vh-100 d-flex" />;
  }

  const isAdmin = authStore.role === ADMIN_ROLE;
  const isAnalytic = authStore.role === ANALYTIC_ROLE;
  const isClient = authStore.role === CLIENT_ROLE;

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Система резервирования времени подъезда к автомобильному пункту пропуска</title>
        <link rel="icon" type="image/png" href={faviconUrl} sizes="16x16" />
      </Helmet>

      <Router>
        <div className="bg-body d-flex flex-column header-offset px-1" style={{ minHeight: '100vh' }}>
          <Header />

          <Routes>
            {isAdmin && (
              <>
                <Route path="/dictionary" element={<DictionaryList />} />
                <Route path="/dictionary/category" element={<DictionaryCategoryList />} />
                <Route path="/dictionary/category/:id" element={<DictionaryCategorySingle />} />
                <Route path="/dictionary/app" element={<DictionaryAppList />} />
                <Route path="/dictionary/app/:id" element={<DictionaryAppSingle />} />
                <Route path="/dictionary/app/add" element={<DictionaryAppSingle />} />

                <Route path="/booking" element={<Booking />} />
                <Route path="/booking/:id" element={<BookingSingle />} />

                <Route path="/transport" element={<AdminTransportList />} />
                <Route path="/transport/:id" element={<AdminTransportSingle />} />

                <Route path="/dashboard/:id" element={<MetabaseDashboard />} />

                <Route path="/registration" element={<UserRegistration />} />

                <Route path="/notification" element={<NotificationList />} />
                <Route path="/notification/:id" element={<NotificationSingle />} />

                <Route element={<ProfileLayout />}>
                  <Route path="/profile/account" element={<Account />} />
                  {/* <Route path="/profile/info" element={<Info />} /> */}
                </Route>

                <Route path="*" element={<Navigate to="/booking" replace />} />
              </>
            )}

            {isAnalytic && (
              <>
                <Route path="/dashboard/:id" element={<MetabaseDashboard />} />
                <Route path="*" element={<Navigate to="/dashboard/1" replace />} />
              </>
            )}

            {isClient && (
              <Route element={<ClientLayout />}>
                <Route element={<ProfileLayout />}>
                  <Route path="/profile/account" element={<Account />} />
                  <Route path="/profile/info" element={<Info />} />
                </Route>

                <Route path="/transport" element={<TransportList />} />
                {/* <Route path="/transport/add" element={<TransportSingle />} /> */}
                <Route path="/transport/:id" element={<TransportSingle />} />

                <Route path="/booking" element={<Booking />} />
                <Route path="/booking/:id" element={<BookingSingle />} />
                {/* <Route path="/booking-process" element={<BookingProcess />} /> */}

                <Route path="/mapp" element={<MappList />} />

                <Route path="/notification" element={<NotificationList />} />
                <Route path="/notification/:id" element={<NotificationSingle />} />

                <Route path="*" element={<Navigate to={isTerminalClient ? 'booking-process' : '/booking'} replace />} />
              </Route>
            )}

            <Route path="/ui-kit/*" element={<UiKit />} />
          </Routes>
          <Footer />
        </div>
      </Router>
      <ToastifyContainer />
      {isTerminalClient && <InactivityWarning />}
    </div>
  );
}

export default observer(App);
