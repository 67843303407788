import { ReactNode } from 'react';
import { timeZonesNames } from '@vvo/tzdb';
import Icon from './components/Icon';

export const ADMIN_ROLE = 'administrator';
export const ANALYTIC_ROLE = 'analytic';
export const CLIENT_ROLE = 'users';

export const LANGUAGES: SelectOption[] = [
  { label: 'Русский', value: 'ru' },
  { label: 'English', value: 'en' },
];

export const ICON_BY_TRANSPORT_CLASS: Record<string, ReactNode> = {
  '1': <Icon name="gruzovie" />,
  '2': <Icon name="trailer" />,
  // '3': <Icon name="bus" />,
  // '4': <Icon name="bike" />,
};

export const PERIODS = [
  '00:00 - 01:00',
  '01:00 - 02:00',
  '02:00 - 03:00',
  '03:00 - 04:00',
  '04:00 - 05:00',
  '05:00 - 06:00',
  '06:00 - 07:00',
  '07:00 - 08:00',
  '08:00 - 09:00',
  '09:00 - 10:00',
  '10:00 - 11:00',
  '11:00 - 12:00',
  '12:00 - 13:00',
  '13:00 - 14:00',
  '14:00 - 15:00',
  '15:00 - 16:00',
  '16:00 - 17:00',
  '17:00 - 18:00',
  '18:00 - 19:00',
  '19:00 - 20:00',
  '20:00 - 21:00',
  '21:00 - 22:00',
  '22:00 - 23:00',
  '23:00 - 00:00',
];

export const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const USER_TYPE = {
  UserType_UNSPECIFIED: 'Не выбран',
  UserType_INDIVIDUAL: 'Физическое лицо',
  UserType_ENTITY: 'Юридическое лицо',
};

export enum TransportClassId {
  TRUCK = '1',
  TRAILER = '2',
  // BUS = '3',
  // BIKE = '4',
  // PASSENGER = '5',
}

export const classOptions: SelectOption[] = [
  // { value: TransportClassId.PASSENGER, label: 'passenger' },
  { value: TransportClassId.TRUCK, label: 'truck' },
  // { value: TransportClassId.BUS, label: 'bus' },
  // { value: TransportClassId.BIKE, label: 'bike' },
  { value: TransportClassId.TRAILER, label: 'trailer' },
];

export const typeOptions: SelectOption[] = [
  {
    label: 'type_options_all',
    value: 'ALL_ACTIVITY',
  },
  {
    label: 'type_options_active',
    value: 'ACTIVE',
  },
  {
    label: 'type_options_past',
    value: 'PAST',
  },
];

export const timeOptions: SelectOption[] = [
  {
    label: 'time_options_all',
    value: 'ALL_TIME',
  },
  {
    label: 'time_options_week',
    value: 'WEEK',
  },
  {
    label: 'time_options_month',
    value: 'MONTH',
  },
  {
    label: 'time_options_half_year',
    value: 'HALF_YEAR',
  },
  {
    label: 'time_options_year',
    value: 'YEAR',
  },
];

export const STATUSES: statusType = {
  CREATED: { icon: <Icon name="check" style={{ color: 'green' }} />, text: 'created' },
  PLANNED: { icon: <Icon name="check" style={{ color: 'green' }} />, text: 'planned' },
  APPROVED: { icon: <Icon name="check" style={{ color: 'green' }} />, text: 'approved' },
  NOT_ARRIVED: { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'not_arrived' },
  TRANSIT_TO_APP: { icon: <Icon name="check" style={{ color: 'green' }} />, text: 'transit_to_app' },
  COMPLETED: { icon: <Icon name="finish" style={{ color: 'green' }} />, text: 'completed' },
  // '50': { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'cancelled_by_booker' },
  // '60': { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'cancelled_by_operator' },
  CANCELLED: { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'canceled' },
  // '70': { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'cancelled_by_system' },
  // '80': { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'absolutely_invisible' },
  EARLY_ARRIVED: { icon: <Icon name="check" style={{ color: 'green' }} />, text: 'early_arrived' },
  NOT_APPROVED: { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'not_approved' },
};

export const statusOptions: SelectOption[] = [
  {
    label: 'all',
    value: 'ALL_STATUSES',
  },
  {
    label: 'created',
    value: 'CREATED',
  },
  {
    label: 'planned',
    value: 'PLANNED',
  },
  {
    label: 'approved',
    value: 'APPROVED',
  },
  {
    label: 'not_arrived',
    value: 'NOT_ARRIVED',
  },
  {
    label: 'transit_to_app',
    value: 'TRANSIT_TO_APP',
  },
  {
    label: 'completed',
    value: 'COMPLETED',
  },
  // {
  //   label: 'cancelled_by_booker',
  //   value: '50', //
  // },
  // {
  //   label: 'cancelled_by_operator',
  //   value: '60', //
  // },
  { label: 'canceled', value: 'CANCELLED' },
  // {
  //   label: 'cancelled_by_system',
  //   value: '70',
  // },
  // {
  //   label: 'absolutely_invisible',
  //   value: '80',
  // },
  {
    label: 'early_arrived',
    value: 'EARLY_ARRIVED',
  },
  {
    label: 'not_approved',
    value: 'NOT_APPROVED',
  },
];

export const DASHBOARDS: SelectOption[] = [
  { label: 'bookings', value: '7' },
  { label: 'vehicleReservations', value: '9' },
  { label: 'dataFromOperator', value: '13' },
  { label: 'travelStatistics', value: '14' },
];

export const DASHBOARDS_PROD: SelectOption[] = [
  { label: 'bookings', value: '16' },
  { label: 'vehicleReservations', value: '21' },
  { label: 'dataFromOperator', value: '17' },
  { label: 'travelStatistics', value: '20' },
];

export const TIME_LIMIT = 20;

export const NOTIFICATION_CATEGORIES: SelectOption[] = [
  {
    label: 'notificationAll',
    value: 'all',
  },
  {
    label: 'application_creation',
    value: '1',
  },
  {
    label: 'postponement_of_application',
    value: '3',
  },
  {
    label: 'cancellation_of_application',
    value: '2',
  },
  {
    label: 'queue_shift',
    value: '4',
  },
];

// export const NOTIFICATION_CATEGORIES: SelectOption[] = [
//   {
//     label: 'notificationAll',
//     value: 'all',
//   },
//   {
//     label: 'notificationNews',
//     value: 'News',
//   },
//   {
//     label: 'notificationBooking',
//     value: 'Booking',
//   },
//   {
//     label: 'notificationProfile',
//     value: 'Profile',
//   },
// ];

// export enum UserStatus {
//   NOT_ESTABLISHED = 'not_established',
//   PHYSICAL = 'physical',
//   JURIDICAL = 'juridical',
// }

export const userStatusOptions: SelectOption[] = [
  // { label: 'не установлен', value: UserStatus.NOT_ESTABLISHED },
  { label: 'physical', value: 'physical' },
  { label: 'juridical', value: 'juridical' },
];

export const timeZoneOptions: SelectOption[] = timeZonesNames.map((zone) => ({ label: zone, value: zone }));

export const countryCodes: SelectOption[] = [
  { label: 'AFG', value: 'AFG' },
  { label: 'ALB', value: 'ALB' },
  { label: 'ATA', value: 'ATA' },
  { label: 'DZA', value: 'DZA' },
  { label: 'ASM', value: 'ASM' },
  { label: 'AND', value: 'AND' },
  { label: 'AGO', value: 'AGO' },
  { label: 'ATG', value: 'ATG' },
  { label: 'AZE', value: 'AZE' },
  { label: 'ARG', value: 'ARG' },
  { label: 'AUS', value: 'AUS' },
  { label: 'AUT', value: 'AUT' },
  { label: 'BHS', value: 'BHS' },
  { label: 'BHR', value: 'BHR' },
  { label: 'BGD', value: 'BGD' },
  { label: 'ARM', value: 'ARM' },
  { label: 'BRB', value: 'BRB' },
  { label: 'BEL', value: 'BEL' },
  { label: 'BMU', value: 'BMU' },
  { label: 'BTN', value: 'BTN' },
  { label: 'BOL', value: 'BOL' },
  { label: 'BIH', value: 'BIH' },
  { label: 'BWA', value: 'BWA' },
  { label: 'BVT', value: 'BVT' },
  { label: 'BRA', value: 'BRA' },
  { label: 'BLZ', value: 'BLZ' },
  { label: 'IOT', value: 'IOT' },
  { label: 'SLB', value: 'SLB' },
  { label: 'VGB', value: 'VGB' },
  { label: 'BRN', value: 'BRN' },
  { label: 'BGR', value: 'BGR' },
  { label: 'MMR', value: 'MMR' },
  { label: 'BDI', value: 'BDI' },
  { label: 'BLR', value: 'BLR' },
  { label: 'KHM', value: 'KHM' },
  { label: 'CMR', value: 'CMR' },
  { label: 'CAN', value: 'CAN' },
  { label: 'CPV', value: 'CPV' },
  { label: 'CYM', value: 'CYM' },
  { label: 'CAF', value: 'CAF' },
  { label: 'LKA', value: 'LKA' },
  { label: 'TCD', value: 'TCD' },
  { label: 'CHL', value: 'CHL' },
  { label: 'CHN', value: 'CHN' },
  { label: 'TWN', value: 'TWN' },
  { label: 'CXR', value: 'CXR' },
  { label: 'CCK', value: 'CCK' },
  { label: 'COL', value: 'COL' },
  { label: 'COM', value: 'COM' },
  { label: 'MYT', value: 'MYT' },
  { label: 'COG', value: 'COG' },
  { label: 'COD', value: 'COD' },
  { label: 'COK', value: 'COK' },
  { label: 'CRI', value: 'CRI' },
  { label: 'HRV', value: 'HRV' },
  { label: 'CUB', value: 'CUB' },
  { label: 'CYP', value: 'CYP' },
  { label: 'CZE', value: 'CZE' },
  { label: 'BEN', value: 'BEN' },
  { label: 'DNK', value: 'DNK' },
  { label: 'DMA', value: 'DMA' },
  { label: 'DOM', value: 'DOM' },
  { label: 'ECU', value: 'ECU' },
  { label: 'SLV', value: 'SLV' },
  { label: 'GNQ', value: 'GNQ' },
  { label: 'ETH', value: 'ETH' },
  { label: 'ERI', value: 'ERI' },
  { label: 'EST', value: 'EST' },
  { label: 'FRO', value: 'FRO' },
  { label: 'FLK', value: 'FLK' },
  { label: 'SGS', value: 'SGS' },
  { label: 'FJI', value: 'FJI' },
  { label: 'FIN', value: 'FIN' },
  { label: 'ALA', value: 'ALA' },
  { label: 'FRA', value: 'FRA' },
  { label: 'GUF', value: 'GUF' },
  { label: 'PYF', value: 'PYF' },
  { label: 'ATF', value: 'ATF' },
  { label: 'DJI', value: 'DJI' },
  { label: 'GAB', value: 'GAB' },
  { label: 'GEO', value: 'GEO' },
  { label: 'GMB', value: 'GMB' },
  { label: 'PSE', value: 'PSE' },
  { label: 'DEU', value: 'DEU' },
  { label: 'GHA', value: 'GHA' },
  { label: 'GIB', value: 'GIB' },
  { label: 'KIR', value: 'KIR' },
  { label: 'GRC', value: 'GRC' },
  { label: 'GRL', value: 'GRL' },
  { label: 'GRD', value: 'GRD' },
  { label: 'GLP', value: 'GLP' },
  { label: 'GUM', value: 'GUM' },
  { label: 'GTM', value: 'GTM' },
  { label: 'GIN', value: 'GIN' },
  { label: 'GUY', value: 'GUY' },
  { label: 'HTI', value: 'HTI' },
  { label: 'HMD', value: 'HMD' },
  { label: 'VAT', value: 'VAT' },
  { label: 'HND', value: 'HND' },
  { label: 'HKG', value: 'HKG' },
  { label: 'HUN', value: 'HUN' },
  { label: 'ISL', value: 'ISL' },
  { label: 'IND', value: 'IND' },
  { label: 'IDN', value: 'IDN' },
  { label: 'IRN', value: 'IRN' },
  { label: 'IRQ', value: 'IRQ' },
  { label: 'IRL', value: 'IRL' },
  { label: 'ISR', value: 'ISR' },
  { label: 'ITA', value: 'ITA' },
  { label: 'CIV', value: 'CIV' },
  { label: 'JAM', value: 'JAM' },
  { label: 'JPN', value: 'JPN' },
  { label: 'KAZ', value: 'KAZ' },
  { label: 'JOR', value: 'JOR' },
  { label: 'KEN', value: 'KEN' },
  { label: 'PRK', value: 'PRK' },
  { label: 'KOR', value: 'KOR' },
  { label: 'KWT', value: 'KWT' },
  { label: 'KGZ', value: 'KGZ' },
  { label: 'LAO', value: 'LAO' },
  { label: 'LBN', value: 'LBN' },
  { label: 'LSO', value: 'LSO' },
  { label: 'LVA', value: 'LVA' },
  { label: 'LBR', value: 'LBR' },
  { label: 'LBY', value: 'LBY' },
  { label: 'LIE', value: 'LIE' },
  { label: 'LTU', value: 'LTU' },
  { label: 'LUX', value: 'LUX' },
  { label: 'MAC', value: 'MAC' },
  { label: 'MDG', value: 'MDG' },
  { label: 'MWI', value: 'MWI' },
  { label: 'MYS', value: 'MYS' },
  { label: 'MDV', value: 'MDV' },
  { label: 'MLI', value: 'MLI' },
  { label: 'MLT', value: 'MLT' },
  { label: 'MTQ', value: 'MTQ' },
  { label: 'MRT', value: 'MRT' },
  { label: 'MUS', value: 'MUS' },
  { label: 'MEX', value: 'MEX' },
  { label: 'MCO', value: 'MCO' },
  { label: 'MNG', value: 'MNG' },
  { label: 'MDA', value: 'MDA' },
  { label: 'MNE', value: 'MNE' },
  { label: 'MSR', value: 'MSR' },
  { label: 'MAR', value: 'MAR' },
  { label: 'MOZ', value: 'MOZ' },
  { label: 'OMN', value: 'OMN' },
  { label: 'NAM', value: 'NAM' },
  { label: 'NRU', value: 'NRU' },
  { label: 'NPL', value: 'NPL' },
  { label: 'NLD', value: 'NLD' },
  { label: 'CUW', value: 'CUW' },
  { label: 'ABW', value: 'ABW' },
  { label: 'SXM', value: 'SXM' },
  { label: 'BES', value: 'BES' },
  { label: 'NCL', value: 'NCL' },
  { label: 'VUT', value: 'VUT' },
  { label: 'NZL', value: 'NZL' },
  { label: 'NIC', value: 'NIC' },
  { label: 'NER', value: 'NER' },
  { label: 'NGA', value: 'NGA' },
  { label: 'NIU', value: 'NIU' },
  { label: 'NFK', value: 'NFK' },
  { label: 'NOR', value: 'NOR' },
  { label: 'MNP', value: 'MNP' },
  { label: 'UMI', value: 'UMI' },
  { label: 'FSM', value: 'FSM' },
  { label: 'MHL', value: 'MHL' },
  { label: 'PLW', value: 'PLW' },
  { label: 'PAK', value: 'PAK' },
  { label: 'PAN', value: 'PAN' },
  { label: 'PNG', value: 'PNG' },
  { label: 'PRY', value: 'PRY' },
  { label: 'PER', value: 'PER' },
  { label: 'PHL', value: 'PHL' },
  { label: 'PCN', value: 'PCN' },
  { label: 'POL', value: 'POL' },
  { label: 'PRT', value: 'PRT' },
  { label: 'GNB', value: 'GNB' },
  { label: 'TLS', value: 'TLS' },
  { label: 'PRI', value: 'PRI' },
  { label: 'QAT', value: 'QAT' },
  { label: 'REU', value: 'REU' },
  { label: 'ROU', value: 'ROU' },
  { label: 'RUS', value: 'RUS' },
  { label: 'RWA', value: 'RWA' },
  { label: 'BLM', value: 'BLM' },
  { label: 'SHN', value: 'SHN' },
  { label: 'KNA', value: 'KNA' },
  { label: 'AIA', value: 'AIA' },
  { label: 'LCA', value: 'LCA' },
  { label: 'MAF', value: 'MAF' },
  { label: 'SPM', value: 'SPM' },
  { label: 'VCT', value: 'VCT' },
  { label: 'SMR', value: 'SMR' },
  { label: 'STP', value: 'STP' },
  { label: 'SAU', value: 'SAU' },
  { label: 'SEN', value: 'SEN' },
  { label: 'SRB', value: 'SRB' },
  { label: 'SYC', value: 'SYC' },
  { label: 'SLE', value: 'SLE' },
  { label: 'SGP', value: 'SGP' },
  { label: 'SVK', value: 'SVK' },
  { label: 'VNM', value: 'VNM' },
  { label: 'SVN', value: 'SVN' },
  { label: 'SOM', value: 'SOM' },
  { label: 'ZAF', value: 'ZAF' },
  { label: 'ZWE', value: 'ZWE' },
  { label: 'ESP', value: 'ESP' },
  { label: 'ESH', value: 'ESH' },
  { label: 'SDN', value: 'SDN' },
  { label: 'SUR', value: 'SUR' },
  { label: 'SJM', value: 'SJM' },
  { label: 'SWZ', value: 'SWZ' },
  { label: 'SWE', value: 'SWE' },
  { label: 'CHE', value: 'CHE' },
  { label: 'SYR', value: 'SYR' },
  { label: 'TJK', value: 'TJK' },
  { label: 'THA', value: 'THA' },
  { label: 'TGO', value: 'TGO' },
  { label: 'TKL', value: 'TKL' },
  { label: 'TON', value: 'TON' },
  { label: 'TTO', value: 'TTO' },
  { label: 'ARE', value: 'ARE' },
  { label: 'TUN', value: 'TUN' },
  { label: 'TUR', value: 'TUR' },
  { label: 'TKM', value: 'TKM' },
  { label: 'TCA', value: 'TCA' },
  { label: 'TUV', value: 'TUV' },
  { label: 'UGA', value: 'UGA' },
  { label: 'UKR', value: 'UKR' },
  { label: 'MKD', value: 'MKD' },
  { label: 'EGY', value: 'EGY' },
  { label: 'GBR', value: 'GBR' },
  { label: 'GGY', value: 'GGY' },
  { label: 'JEY', value: 'JEY' },
  { label: 'IMN', value: 'IMN' },
  { label: 'TZA', value: 'TZA' },
  { label: 'USA', value: 'USA' },
  { label: 'VIR', value: 'VIR' },
  { label: 'BFA', value: 'BFA' },
  { label: 'URY', value: 'URY' },
  { label: 'UZB', value: 'UZB' },
  { label: 'VEN', value: 'VEN' },
  { label: 'WLF', value: 'WLF' },
  { label: 'WSM', value: 'WSM' },
  { label: 'YEM', value: 'YEM' },
  { label: 'ZMB', value: 'ZMB' },
  { label: 'ABH', value: 'ABH' },
  { label: 'OST', value: 'OST' },
  { label: 'SSD', value: 'SSD' },
  { label: 'DNR', value: 'DNR' },
  { label: 'LNR', value: 'LNR' },
];

export const dictionaries: Dictionary[] = [
  // {
  //   title: 'vehicleCategories',
  //   link: '/dictionary/category',
  //   created: '21.11.2022',
  //   updated: '21.11.2022',
  // },
  {
    title: 'directoryApp',
    link: '/dictionary/app',
  },
  {
    title: 'registration',
    link: '/registration',
  },
];

export const dictionaryCategories: DictionaryCategory[] = [
  {
    id: '1',
    icon: 'bike',
    name: 'bike',
    subcategory: ['A'],
    created: '10.09.2022',
    updated: '22.11.2022',
  },
  {
    id: '2',
    icon: 'legkovie',
    name: 'passenger',
    subcategory: ['B', 'BE'],
    created: '10.09.2022',
    updated: '22.11.2022',
  },
  {
    id: '3',
    icon: 'gruzovie',
    name: 'truck',
    subcategory: ['C', 'C1', 'C1E'],
    created: '10.09.2022',
    updated: '22.11.2022',
  },
  {
    id: '4',
    icon: 'bus',
    name: 'bus',
    subcategory: ['D', 'D1', 'D1E'],
    created: '10.09.2022',
    updated: '22.11.2022',
  },
];
