import client from '../../httpClient/clientV1';

export type User = {
  surname: string;
  notify_email: string;
  email: string;
  name: string;
  patronymic: string;
  password: string;
  mapp_id: string;
  role: string;
  passwordConfirmation: string;
};

export async function createUser(data: Partial<User>) {
  const response = await client.post('/user', data);
  return response.data;
}

export default Object.assign({
  createUser,
});
