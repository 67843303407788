import axios from 'axios';
import { toast } from 'react-toastify';

const clientScanner = axios.create({
  baseURL: process.env.REACT_APP_SCANNER_ENDPOINT,
});

clientScanner.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('seq_kk_token');

  if (token) {
    config.headers.Authorization = 'Bearer ' + token;
  }

  return config;
});

clientScanner.interceptors.response.use(
  (response) => {
    const { method, url } = response.config;
    console.log(`[Axios ${method?.toUpperCase()} ${url}]`, response);
    return response;
  },
  (error) => {
    let message = String(error);
    console.log(error);
    if (axios.isAxiosError(error)) message = error.message;
    if (error.response.status === 413) {
      toast.error(
        'При добавлении файла произошла ошибка. Пожалуйста, проверьте формат и размер файла и повторите попытку'
      );
    } else if (error.response.status === 429) {
      toast.error('Предыдущее сканирование еще не завершилось');
    } else {
      toast.error(message);
    }
  }
);

export default clientScanner;
