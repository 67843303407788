import client from '../../httpClient/clientV1';

const mappsUrl = '/mapps';

async function fetchMapps(searchParams?: string) {
  const params = searchParams === undefined ? '' : `?${searchParams}`;

  const response = await client.get<Mapp[]>(`${mappsUrl}${params}`);
  return response.data;
}

async function fetchMappById(id: string) {
  const response = await client.get<Mapp>(`/mapps/${id}`);
  return response.data;
}

async function createMapp(body: AdminMapp) {
  const response = await client.post('/admin/mapp', body);
  return response.data;
}

async function updateMapp(id: string, body: AdminMapp) {
  const response = await client.put(`/admin/mapp/${id}`, body);
  return response.data;
}

async function deleteMapp(id: string) {
  const response = await client.delete<Mapp>(`/admin/mapp/${id}`);
  return response.data;
}

export async function fetchTimezones() {
  const result = await client.get<TimezoneType[]>('/dictionaries/timezones');
  return result.data;
}

export default Object.assign({
  fetchMapps,
  fetchMappById,
  createMapp,
  updateMapp,
  deleteMapp,
  fetchTimezones,
});
