import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../../components/Spinner';

import store from '../../../../store';
const { profileStore } = store;

function LoginBlock() {
  const { t } = useTranslation(['profile', 'common']);

  return (
    <div>
      <p className="mb-1 text-caption fs-7">{t('login')}</p>
      <div className="d-flex">
        {
          <h5 className="my-auto me-4">
            {profileStore.isLoading && <Spinner size={24} className="m-auto text-primary" />}
            {!profileStore.isLoading && profileStore.profile.email}
          </h5>
        }
      </div>
    </div>
  );
}

export default observer(LoginBlock);
