import { action, makeAutoObservable } from 'mobx';
import { RootStore } from '../../store';
import Api, { FetchNotificationsResponse, Notification } from './api';

export class NotificationStore {
  rootStore: RootStore;

  notifications?: Notification[];
  notification?: Notification;
  unreadNotificationCount = 0;

  isLoading = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setUnreadNotificationCount(count: number) {
    this.unreadNotificationCount = count;
  }

  setNotifications(notifications?: Notification[]) {
    // this.notifications = notifications === undefined ? undefined : notifications.reverse();
    this.notifications = notifications === undefined ? undefined : notifications;
  }

  setNotification(notification?: Notification) {
    this.notification = notification;
  }

  async fetchNotifications({ language, searchParams }: { language: string; searchParams: string }) {
    await Api.fetchNotifications({ language, searchParams }).then(
      action('fetchSuccess', (response: FetchNotificationsResponse) => {
        this.rootStore.filterStore.update('total', String(response.total));
        this.setNotifications(response.notifications);
      }),
      action('fetchError', (error: unknown) => console.error(error))
    );
  }

  async fetchNotificationById(id: string, language: string) {
    await Api.fetchNotificationById(id, language).then(
      action('fetchSuccess', (response: { notification: Notification }) => this.setNotification(response.notification)),
      action('fetchError', (error: unknown) => console.error(error))
    );
  }

  async readNotifications(ids: string[]) {
    return await Api.readNotifications(ids).then(
      action('fetchSuccess', (response: { Status: boolean }) => response.Status),
      action('fetchError', (error: unknown) => {
        console.error(error);
        return false;
      })
    );
  }

  fetchUnreadNotificationCount() {
    Api.fetchUnreadNotificationCount().then(
      action('fetchSuccess', (response: { NewCount: number }) => this.setUnreadNotificationCount(response.NewCount)),
      action('fetchError', (error: unknown) => console.error(error))
    );
  }
}
