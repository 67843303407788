import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function YourNextSteps() {
  const [showRules, setShowRules] = useState(false);
  const { t } = useTranslation(['booking']);
  return (
    <>
      {showRules && (
        <>
          <hr className="m-0" />
          <div className="p-4 d-grid gap-4">
            <h5>{t('whats_next')}</h5>
            <ol className="m-0">
              <li>{t('whats_next_1')}</li>
              <li>{t('whats_next_2')}</li>
            </ol>
          </div>
        </>
      )}
      <hr className="m-0" />
      <div className="p-4">
        <Link to="#" onClick={() => setShowRules((state) => !state)}>
          {showRules ? t('hide_your_next_steps') : t('show_your_next_steps')}
        </Link>
      </div>
    </>
  );
}
export default YourNextSteps;
