import { useState } from 'react';
import { Form, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import EmptyResult from '../../../../components/EmptyResult';
import TableHead from './components/TableHead';
import TableRow from './components/TableRow';

import { dictionaries } from './../../../../static';

import rootStore from '../../../../store';
const { isFSEQ } = rootStore;

function DictionaryList() {
  const { t } = useTranslation(['dictionary', 'common', 'analytic']);
  const isTerminalClient = isFSEQ();

  const [searchQuery, setSearchQuery] = useState('');

  const filteredDictionaries = dictionaries.filter((dictionary: Dictionary) =>
    dictionary.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const hasDictionary = !!filteredDictionaries?.length;

  return (
    <Container
      className={`${!isTerminalClient ? 'flex-grow-1 ' : 'm-auto terminal-min-height transform-y-180px '}pb-3 pb-sm-5`}
    >
      <Row>
        <Col lg={isTerminalClient ? 12 : 9}>
          <h2 className="mt-40px mb-4">{t('administration')}</h2>

          <div className="bg-white rounded shadow-main" style={{ marginBottom: '4rem' }}>
            {/* <div className="d-flex p-4" style={{ borderBottom: '1px solid #E1E1E1' }}>
              <Form.Control
                type="search"
                placeholder={t('search', { ns: 'common' }) || ''}
                className="flex-grow-1"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div> */}

            {hasDictionary && (
              <div className="d-flex flex-column gap-4 p-4">
                <TableHead />
                {filteredDictionaries.map((dictionary, index) => (
                  <div key={index}>
                    <TableRow dictionary={dictionary} />
                  </div>
                ))}
                <div>
                  <Link to={`/dashboard/${process.env.REACT_APP_BUILD_ENV === 'production' ? '18' : '12'}`}>
                    {t('userActivityLog', { ns: 'analytic' })}
                  </Link>
                </div>
                <div>
                  <Link to={`/dashboard/${process.env.REACT_APP_BUILD_ENV === 'production' ? '19' : '15'}`}>
                    {t('viewUsers', { ns: 'analytic' })}
                  </Link>
                </div>
                <div>
                  <a
                    href="https://keycloak.cloud.eq:8443/auth/realms/master/protocol/openid-connect/auth?client_id=security-admin-console&redirect_uri=https%3A%2F%2Fkeycloak.cloud.eq%3A8443%2Fauth%2Fadmin%2Fmaster%2Fconsole%2F&state=3d615fa0-08dc-4090-8897-4dcb61eb6c9a&response_mode=fragment&response_type=code&scope=openid&nonce=c600817f-79e1-45a6-8272-4ea99d292a9c&code_challenge=qcMrXvF-HiTMMSKefdgddANoEQpD7Gws45_AcA06pTk&code_challenge_method=S256"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('userManagement', { ns: 'dictionary' })}
                  </a>
                </div>
              </div>
            )}

            {!hasDictionary && <EmptyResult text={t('noDictionariesByRequest')} />}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default observer(DictionaryList);
