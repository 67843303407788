import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Icon from '../../../../components/Icon';
import Select from '../../../../components/Select';
import Modal from './../../../../components/Modal';
import Button from './../../../../components/Button';
import EmptyResult from '../../../../components/EmptyResult';
import Loader from '../../../../components/Loader';
import PaginationSet from '../../../../components/PaginationSet';

import rootStore from '../../../../store';
import { TransportClassId, classOptions } from '../../../../static';

import InputSearch from '../../../../components/InputSearch';
import TerminalHeader from '../../../../components/TerminalHeader';
const { transportStore, filterStore, isFSEQ } = rootStore;

const fullOptions = [{ label: 'all', value: 'all' }, ...classOptions];

const initialParams: FilterParams = {
  car_type: 'all',
};

function TransportList() {
  const { t } = useTranslation(['transport', 'header', 'common']);
  const navigation = useNavigate();

  const { search } = useLocation();
  const [, setSearchParams] = useSearchParams();

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
  const [selectedTransport, setSelectedTransport] = useState<Transport>();
  const [transportTypes, setTransportTypes] = useState<TransportType[]>([]);

  const isTerminalClient = isFSEQ();

  const fetchTransportTypes = useCallback(async () => {
    const transportTypes = await transportStore.fetchTransportTypes();
    setTransportTypes(transportTypes);
  }, []);

  useEffect(() => {
    fetchTransportTypes();
  }, [fetchTransportTypes]);

  useEffect(() => {
    filterStore.set(initialParams, search);
    transportStore.fetchTransport(filterStore.searchParams);
  }, [search]);

  const getTransportType = (carTypeId: string | number): string => {
    const type: TransportType | undefined = transportTypes.find(
      (transportType: TransportType) => String(transportType.typeId) === String(carTypeId)
    );

    return type?.name || '';
  };

  const updateFilter = (key: string, value: string) => {
    if (key === 'search' || key === 'limit' || key === 'car_type') {
      filterStore.update('page', '1');
    }

    filterStore.update(key, value).then(() => setSearchParams(filterStore.filteredParams));
  };

  const openConfirmModal = (selectedTransport: Transport) => {
    setSelectedTransport(selectedTransport);
    setIsOpenConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setSelectedTransport(undefined);
    setIsOpenConfirmModal(false);
  };

  const deleteVehicleHandler = async () => {
    if (!selectedTransport) return;

    const successDelete = await transportStore.deleteTransportById(selectedTransport?.car_id);

    if (successDelete) {
      transportStore.fetchTransport(filterStore.searchParams);
      setIsOpenConfirmModal(false);
      setIsOpenSuccessModal(true);
    }
  };

  const deleteTransportLabel = () => {
    if (!selectedTransport) return '';

    const { name, model, grnz, car_type_id } = selectedTransport;
    const carOptions: string =
      String(car_type_id) === TransportClassId.TRAILER
        ? `${t('trailer')} ${grnz}`
        : `${name || ''} ${model || ''} ${grnz}`;

    return `${t('delete', { ns: 'common' })} ${carOptions}?`;
  };

  const TableRow = ({ transport }: { transport: Transport }) => {
    const renderActionButtons = () => {
      if (isTerminalClient) {
        return (
          <div className="d-flex gap-3">
            <div className="d-flex align-items-center gap-1">
              <Icon name={transportStore.getTransportValidationStatusCode(transport.validation_status)}></Icon>
              <span>
                {t(transportStore.getTransportValidationStatusCode(transport.validation_status), {
                  ns: 'transport',
                })}
              </span>
            </div>
            {transport?.is_booking_available ? (
              <>
                <Link to={`/transport/${transport?.car_id}`}>
                  <Button
                    text={t('change', { ns: 'common' })}
                    variant="outline"
                    className="terminal-button-sm terminal-button-sm--action terminal-button-sm--action-link"
                  />
                </Link>
                <Button
                  text={t('delete', { ns: 'common' })}
                  variant="outline"
                  className="terminal-button-sm terminal-button-sm--action terminal-button-sm--action-del"
                  onClick={() => openConfirmModal(transport)}
                />
              </>
            ) : (
              <OverlayTrigger
                trigger={['click']}
                overlay={<Tooltip>{t('inactive_transport_editing', { ns: 'transport' })}</Tooltip>}
              >
                <span className="me-4 d-flex flex-column justify-content-center">
                  <Icon name="exclamation-mark" width={48} height={48} className="cursor-pointer transport-icon"></Icon>
                </span>
              </OverlayTrigger>
            )}
          </div>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center gap-1">
            <Icon name={transportStore.getTransportValidationStatusCode(transport.validation_status)}></Icon>
            <span>
              {t(transportStore.getTransportValidationStatusCode(transport.validation_status), {
                ns: 'transport',
              })}
            </span>
          </div>
          <div>
            {transport?.is_booking_available ? (
              <>
                <Link to={`/transport/${transport?.car_id}`}>
                  <Icon name="edit" className="transport-icon" />
                </Link>
                <Icon
                  name="trash"
                  className="transport-icon ms-4 position-relative"
                  style={{ top: 2 }}
                  onClick={() => openConfirmModal(transport)}
                />
              </>
            ) : (
              <OverlayTrigger
                trigger={['hover', 'focus']}
                overlay={<Tooltip>{t('inactive_transport_editing', { ns: 'transport' })}</Tooltip>}
              >
                <span className="me-4">
                  <Icon name="exclamation-mark" width={24} height={24} className="cursor-pointer transport-icon"></Icon>
                </span>
              </OverlayTrigger>
            )}
          </div>
        </>
      );
    };
    return (
      <Row>
        <Col xs={12} md={2}>
          <span className="ms-3">{getTransportType(transport.car_type_id)}</span>
        </Col>
        <Col xs={12} md={3}>
          <span className="ms-3">
            {transport.name} {transport.model}
          </span>
        </Col>
        <Col xs={12} md={isTerminalClient ? 2 : 3}>
          <div className="d-flex align-items-center">
            <span>{transport.grnz}</span>
          </div>
        </Col>
        <Col xs={12} md={isTerminalClient ? 5 : 4}>
          <div
            className={`${
              isTerminalClient ? 'justify-content-end' : 'justify-content-between'
            } d-flex align-items-center`}
          >
            {renderActionButtons()}
          </div>
        </Col>
      </Row>
    );
  };

  const isLoadResult = !transportStore.isLoading && !!transportStore.transportList?.length;
  const isLoadEmptyResult = !transportStore.isLoading && !transportStore.transportList?.length;

  return (
    <>
      <Container
        className={`${
          !isTerminalClient ? 'flex-grow-1 ' : 'm-auto terminal-min-height transform-y-180px '
        }pb-3 pb-sm-5`}
      >
        {isTerminalClient && <TerminalHeader />}
        <Row>
          <Col lg={isTerminalClient ? 12 : 9}>
            {!isTerminalClient && <h2 className="mt-40px mb-4">{t('transport', { ns: 'header' })}</h2>}

            {/* <div
              className="d-none d-sm-flex align-items-center justify-content-between rounded py-28px px-4 mb-4"
              style={{ border: '1px dashed rgba(29, 93, 235, 0.18)' }}
            >
              <span className="text-secondary">{t('add_new_vehicle')}</span>

              <Link to="/transport/add" className={`${isTerminalClient ? 'btn btn-primary' : 'link-primary'} fs-6`}>
                {t('add', { ns: 'common' })}
              </Link>
            </div> */}

            <Button
              text={t('add', { ns: 'common' })}
              className="d-flex justify-content-center d-sm-none mb-3 w-100"
              onClick={() => navigation('/transport/add')}
            />

            <div className="bg-white rounded shadow-main">
              <div className="p-3 p-md-4 border-bottom" style={{ borderColor: '#E1E1E1' }}>
                <Row>
                  <Col xs={{ span: 12, order: 2 }} md={{ span: 4, order: 1 }}>
                    <Select
                      options={fullOptions}
                      formatOptionLabel={({ value, label }) => <>{t(label)}</>}
                      value={fullOptions.find((option) => option.value === filterStore.params.car_type)}
                      onChange={(e) => e?.value && updateFilter('car_type', e.value)}
                    />
                  </Col>
                  <Col xs={{ span: 12, order: 1 }} md={{ span: 8, order: 2 }} className="mb-3 mb-md-0">
                    <InputSearch
                      placeholder={t('search', { ns: 'common' }) || ''}
                      value={filterStore.params?.search || ''}
                      onChange={(newValue: string) => updateFilter('search', newValue)}
                    />
                  </Col>
                </Row>
              </div>

              {transportStore.isLoading && <Loader />}

              {isLoadResult && (
                <>
                  {transportStore.transportList.map((transport: Transport, index: number) => (
                    <div key={index} className="p-3 p-md-4 border-bottom" style={{ borderColor: '#E1E1E1' }}>
                      <TableRow transport={transport} />
                    </div>
                  ))}
                  <PaginationSet
                    items={Number(filterStore.params.total)}
                    page={Number(filterStore.params.page)}
                    pageCount={Number(filterStore.params.limit)}
                    isTerminalClient={isTerminalClient}
                    onPageCountChange={(page) => updateFilter('limit', String(page))}
                    onPageChange={(page) => updateFilter('page', String(page))}
                    className="py-4 px-28px"
                  />
                </>
              )}

              {isLoadEmptyResult && <EmptyResult text={t('vehicles_were_not_found', { ns: 'transport' })} />}
            </div>
          </Col>
        </Row>
      </Container>

      <Modal
        show={isOpenConfirmModal}
        onHide={closeConfirmModal}
        header={t('removing_the_vehicle') || ''}
        body={deleteTransportLabel()}
        footer={
          <div className="d-flex flex-column flex-sm-row gap-4 w-100">
            <Button
              variant="primary"
              onClick={() => deleteVehicleHandler()}
              text={t('delete', { ns: 'common' })}
              // className="me-4"
              isLoading={transportStore.isLoading}
            />
            <Button variant="outline" onClick={closeConfirmModal} text={t('cancellation', { ns: 'common' })} />
          </div>
        }
      />

      <Modal
        show={isOpenSuccessModal}
        onHide={() => setIsOpenSuccessModal(false)}
        body={
          <div className="w-100 d-flex flex-column align-items-center justify-content-center">
            <Icon name="accept" width={80} height={80} style={{ color: '#C9D8FA' }} className="mt-44px mb-4" />
            <h4 className="m-0 text-center">{t('the_vehicle_is_removed')}</h4>
          </div>
        }
        footer={
          <div className="w-100 d-flex justify-content-center">
            <Button
              variant="primary"
              onClick={() => setIsOpenSuccessModal(false)}
              text={t('to_the_list_of_transport')}
            />
          </div>
        }
      />
    </>
  );
}

export default observer(TransportList);
