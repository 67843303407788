import { action, makeAutoObservable } from 'mobx';
import { RootStore } from '../../store';
import Api, { User } from './api';

export class UserRegistrationStore {
  rootStore: RootStore;
  isLoading = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  async createUser(data: Partial<User>) {
    this.setLoading(true);

    return await Api.createUser(data)
      .then(
        action('fetchSuccess', (response: any) => {
          console.log(response);
          return true;
        }),
        action('fetchError', (error: unknown) => {
          console.error(error);
        })
      )
      .finally(() => this.setLoading(false));
  }
}
