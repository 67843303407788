import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  ru: {
    header: {
      title: 'Система резервирования времени подъезда к автомобильному пункту пропуска',
      site: 'СРВ',
      booking: 'Бронирования',
      transport: 'Транспорт',
      reports: 'Отчеты',
      administration: 'Администрирование',
      app: 'Автомобильный пункт пропуска',
      profile: 'Профиль',
      notification: 'Уведомления',
      logout: 'Выйти',
    },
    footer: {
      title: 'Система резервирования времени подъезда к автомобильному пункту пропуска',
      support: 'Техническая поддержка:',
    },
    profile: {
      profile: 'Профиль',
      account: 'Учётная запись',
      data: 'Данные',
      transport: 'Транспорт',
      phone: 'Телефон',
      change: 'Изменить',
      number_change: 'Изменение номера',
      new_phone_number: 'Новый номер телефона',
      enter_phone_number: 'Введите номер телефона',
      email_change: 'Изменение электронной почты',
      new_email: 'Новый адрес электронной почты',
      enter_email: 'Введите адрес электронной почты',
      orgName_change: 'Изменение наименования организации',
      new_orgName: 'Новое наименование организации',
      enter_orgName: 'Введите наименование организации',
      country_change: 'Изменение страны заявителя',
      new_country: 'Новая страна заявителя',
      enter_country: 'Выберите страну заявителя',
      status: 'Статус',
      select_status: 'Выберите статус',
      physical: 'Физическое лицо',
      juridical: 'Юридическое лицо',
      undetermined: 'не установлен',
      editing_data: 'Редактирование данных',
      login: 'Логин',
      orgName: 'Наименование организации',
      applicantCountry: 'Страна заявителя',
      confirm_change_title: 'Подтверждение изменения',
      confirm_change_body: 'Подтвердить изменение адреса',
    },
    booking: {
      filters: 'Фильтры',
      booking: 'Бронирование',
      book: 'Забронировать',
      add_new_booking: 'Добавьте новое бронирование',
      no_reservations_were_found: 'Бронирований не найдено',
      all_statuses: 'Все статусы',
      all_ts_types: 'Все типы ТС',
      all_apps: 'Все автомобильные пункты пропуска',
      with_trailer: 'С прицепом',
      trailerless: 'Без прицепа',
      selecting_a_date_or_period: 'Выбор даты или периода',
      // type_options_all: 'Все',
      // type_options_active: 'Активные',
      // type_options_past: 'Прошедшие',
      // time_options_all: 'За всё время',
      // time_options_week: 'Неделя',
      // time_options_month: 'Месяц',
      // time_options_half_year: 'Полгода',
      // time_options_year: 'Год',
      // status_options_all: 'Все статусы',
      // status_options_on_review: 'На проверке',
      // status_options_register: 'Зарегистрировано',
      // status_options_confirm: 'Заявка не подтверждена',
      // status_options_completed: 'Завершено',
      // status_options_cancel: 'Отменено',
      year: 'года',
      year_short: 'г',
      vehicle_data: 'Данные транспортного средства',
      category: 'Категория',
      plate_number: 'Госномер',
      app_data: 'Данные автомобильного пункта пропуска',
      name: 'Наименование',
      address: 'Адрес',
      contacts: 'Контакты заявителя',
      phone: 'Телефон',
      email: 'Электронная почта',
      directions_app: 'Код для проезда к автомобильному пункту пропуска',
      cancel_code: 'Код отмены',
      cancel_booking: 'Отменить бронирование',
      change_status: 'Изменить статус',
      cancellation_of_booking: 'Отмена бронирования',
      rescheduling_title: 'Перенос бронирования',
      changing_status: 'Изменение статуса',
      cancellation_warning_message:
        'Возможность отмены бронирования заблокирована, так как до времени указанного в заявке осталось менее 3 (трех) часов.',
      rescheduling_warning_message:
        'Возможность переноса заблокирована, так как до времени указанного в заявке осталось менее 3 (трех) часов.',
      confirm_cancellation_booking: 'Подтвердить отмену бронирования',
      confirm_rescheduling: 'Подтвердить перенос бронирования',
      reservations_canceled: 'Бронирование отменено',
      home: 'На главную',
      to_create_ts: 'Создать ТС',
      rescheduling: 'Перенос бронирования',
      rescheduling_hint:
        'Перенос и отмену заявки возможно выполнить только с активными заявками не позднее чем за 3 часа до зарезервированного времени проезда.',
      documents: 'Документы, прилагаемые к заявке',
      sts: 'Свидетельство о регистрации ТС',
      booking_management: 'Управление бронированием',

      choose_a_model_and_make: 'Выберите модель и марку тягача',
      choose_a_trailer: 'Выберите прицеп',
      vehicle: 'Транспортное средство',
      add_a_new_vehicle: 'Добавить новое транспортное средство',
      cargo_availability: 'Наличие груза',
      export: 'Экспорт',
      regular_flight: 'Регулярный рейс',
      irregular_flight: 'Нерегулярный рейс',
      route_number: 'Номер маршрута',
      availability_of_passengers: 'Наличие пассажиров',
      number_of_passengers: 'Количество пассажиров',
      provide_contact_information_for_the_driver: 'Укажите контактные данные водителя',
      enter_phone_number: 'Введите номер телефона',
      enter_email: 'Введите электронную почту',
      matches_the_contact_details_of_the_profile: 'Совпадает с контактными данными профиля',
      select_app: 'Выберите автомобильный пункт пропуска',
      app: 'Автомобильный пункт пропуска',
      date: 'Дата',
      time: 'Время',
      confirmation_time_after_ime_expires: 'Время на подтверждение ограничено, по истечении времени',
      // remaining: 'Осталось',
      captcha_warning: 'Чтобы продолжить, введите указанные ниже символы',
      select_a_date_and_time: 'Выберите дату и время',
      you_book_interval_in_time_zone_located:
        'Вы бронируете интервал в том часовом поясе, где находится автомобильный пункт пропуска',
      you_have_selected: 'Вы выбрали',
      the_application_is_executed: 'Заявка оформлена',
      you_booked_time: 'Вы забронировали время для проезда автомобильного пункта пропуска',
      whats_next: 'Что дальше',
      whats_next_1: 'Уведомление о бронировании будет направлено на указанную электронную почту и в Личный кабинет',
      whats_next_2: `Грузовое транспортное средство должно прибыть к пункту контроля, расположенному на участке автомобильной дороги, не ранее чем за 1 час до наступления зарезервированного времени`,
      booking_cancel: 'Для отмены или переноса бронирования необходимо перейти в',
      booking_cancel_link: 'карточку бронирования',
      booking_cancellation_code: 'Код отмены бронирования',
      booking_cancellation_code_text:
        'Код необходим для отмены бронирования на площадке-накопителе через терминал самообслуживания. Отмена бронирования без кода доступна в карточке бронирования',
      rolse_of_reservation_on_the_spot: 'Отмена бронирования на месте',
      travel_for_the_route_to_the_app: 'Код для проезда к автомобильному пункту пропуска',
      print_or_save_the_QR_code:
        'Распечатайте или сохраните QR-код себе на устройство. Он может потребоваться для проезда и выезда с площадки-накопителя перед пунктом пропуска',
      to_get_to_the_app_you_may_need_to: 'Для проезда к автомобильному пункту пропуска может понадобиться',
      qr_code: 'QR-код',
      it_can_be_found_in: 'его можно найти в',
      booking_card: 'карточке бронирования',
      to_cancel_a_reservation_you_must_enter: 'Для отмены бронирования необходимо ввести',
      code: 'код',
      self_service_terminal_it_can_be_found_at: 'в терминале самообслуживания, его можно найти в',
      print_the_qr_code: 'Распечатать QR-код',
      confirm_application: 'Подтвердите заявку',
      // confirmation_time_limited: 'Время на подтверждение ограничено, по истечении времени',
      // you_return_previous_step: 'вы вернётесь на предыдущий шаг',
      seats_available: 'Доступно мест',
      not_chosen: 'Не выбрано',
      show_history: 'Показать историю',
      hide_history: 'Скрыть историю',
      show_your_next_steps: 'Ваши дальнейшие действия',
      hide_your_next_steps: 'Скрыть',
      scheme: 'Схема автомобильного пункта пропуска',
      view_ts: 'Просмотр документа на ТС',
      booking_attention:
        'Забронировать дату и время пересечения границы возможно на ближайшие 30 дней. Если возможность выбора свободного слота отсутствует, значит все слоты уже заняты, либо Вами была произведена отмена бронирования в течение последних 30 минут. Попробуйте записаться позже',
      not_found: 'Доступные ТС отсутствуют',
      booking_is_not_available: 'Бронирование недоступно',
      booking_is_not_available_body:
        'Вами была произведена отмена бронирования в течение последних 30 минут. Попробуйте записаться позже',
      captcha_failed_title: 'Неверные символы',
      captcha_failed_body: 'Вы ввели неверные символы для подтверждения бронирования',
      bookings_failed_title: 'Отсутствуют свободные места',
      bookings_failed_body: 'Для выбранного интервала отсутствуют свободные места. Попробуйте выбрать другое время',
      handbook_title: 'Ознакомились с инструкцией портального ИДК?',
      handbook_message:
        'На МАПП Забайкальск установлен ИДК портального типа, просьба ознакомиться со следующими правилами:',
      handbook_file_name_ru: 'Памятка на русском языке',
      handbook_file_name_ch: 'Memo (Chinese)',
      informed: 'Ознакомлен',
    },
    transport: {
      add_new_vehicle: 'Добавьте новое транспортное средство',
      vehicles_were_not_found: 'Транспортные средства не найдены',
      adding_a_vehicle: 'Добавление транспортного средства',
      editing_a_vehicle: 'Редактирование транспортного средства',
      removing_the_vehicle: 'Удаление транспортного средства',
      the_vehicle_is_removed: 'Транспортное средство удалено',
      to_the_list_of_transport: 'К списку транспорта',
      category: 'Категория',
      vehicle_type: 'Тип ТС',
      brand: 'Марка',
      model: 'Модель',
      gosnomer: 'Госномер',
      select_the_vehicle: 'Выберите транспортное средство',
      select_category: 'Выберите категорию',
      enter_the_brand: 'Введите марку',
      enter_the_model: 'Введите модель',
      enter_the_license_plate: 'Введите госномер',
      passenger: 'Легковое',
      truck: 'Тягач',
      trailer: 'Прицеп',
      bus: 'Автобус',
      bike: 'Мотоцикл',
      all: 'Все',
      delete_sts_file_title: 'Удаление файла с СТС',
      delete_sts_file_body: 'Удалить добавленный файл СТС?',
      upload_neccessary_docs: 'Загрузить необходимые документы',
      upload_neccessary_docs_hint:
        'Электронный образ (скан-копия) свидетельства о регистрации ТС (тягача или прицепа).',
      file_must_have_only_face_side: 'Файл должен содержать только лицевую сторону СТС',
      info_about_sts: 'Лицевая сторона СТС содержит следующие сведения о ТС',
      grnz: 'Регистрационный знак',
      vin: 'Идентификационный номер (VIN)',
      model_etc: 'Марка, модель и т.д.',
      truck_sts: 'СТС тягача',
      trailer_sts: 'СТС прицепа',
      doc_scanning: 'Выполняется сканирование документа...',
      scan_upload: 'Сканировать',
      scan_result: 'Результат сканирования',
      info_about_number_plate: 'Порядок заполнения госномера',
      license_plate_like_sts: 'Номер заполняется в строгом соответствии с написанием в СТС',
      license_plate_rules: 'Используются заглавные латинские символы и цифры',
      license_plate_without_spaces: 'Номер вводится без пробелов',
      inactive_transport_editing: 'Редактирование и удаление недоступно для ТС с действующим бронированием',
      review: 'На проверке',
      verified: 'Проверено',
      rejected: 'Отклонено',
      portal: 'Портал',
      kiosk: 'Терминал самозаписи',
      where_created: 'Все созданные ТС',
      status_ts_updated: 'Статус ТС обновлен',
    },
    class: {
      all: 'Все',
      passenger: 'Легковое',
      cargo: 'Грузовое',
      bus: 'Автобус',
      motorcycle: 'Мотоцикл',
    },
    login: {
      authorization: 'Авторизация',
      register: 'Зарегистрироваться',
      cant_enter: 'Не удаётся войти?',
      enter_email: 'Введите email',
      enter_password: 'Введите пароль',
      password: 'Пароль',
      login: 'Войти',
    },
    app: {
      title: 'Автомобильный пункт пропуска',
      where_will_you_cross_the_border: 'Где будете пересекать границу?',
      book: 'Забронировать',
      partPPLabel: 'Сопредельный автомобильный пункт пропуска',
      noApp: 'Нет автомобильного пункта пропуска по запросу',
      Mon: 'Понедельник',
      Tue: 'Вторник',
      Wed: 'Среда',
      Thu: 'Четверг',
      Fri: 'Пятница',
      Sat: 'Суббота',
      Sun: 'Воскресенье',
    },
    notification: {
      notifications: 'Уведомления',
      no_notifications: 'Уведомления не найдены',
      no_notifications_by_category: 'Нет уведомлений в данной категории',
      notificationAll: 'Все',
      // notificationNews: 'Новости',
      // notificationBooking: 'Бронирования',
      // notificationProfile: 'Профиль',
      show: 'Показать',
      allRead: 'Все сообщения прочитаны',
      application_creation: 'Создание заявки',
      postponement_of_application: 'Перенос заявки',
      cancellation_of_application: 'Отмена заявки',
      queue_shift: 'Сдвиг очереди',
    },
    // status: {
    //   on_review: 'На проверке',
    //   registered: 'Зарегистрировано',
    //   not_confirm: 'Заявка не подтверждена',
    //   completed: 'Завершено',
    //   canceled: 'Отменено',
    //   annulled: 'Аннулировано',
    //   hidden: 'Скрыто',
    //   early_arrived: 'Раннее прибытие',
    // },
    // status: {
    //   all: 'Все статусы',
    //   created: 'На проверке', // 0
    //   planned: 'Зарегистрировано', // 10
    //   approved: 'Ожидание времени', // 20
    //   not_arrived: 'Аннулировано', // 30
    //   transit_to_app: 'Проезд к автомобильному пункту пропуска', // 35
    //   completed: 'Завершено', // 40
    //   canceled: 'Отменено', // 50
    //   cancelled_by_booker: 'Отменено', // 50
    //   cancelled_by_operator: 'Аннулировано', // 60
    //   // cancelled_by_system: 'Аннулировано системой', // 70
    //   absolutely_invisible: 'Скрыто', // 80
    //   early_arrived: 'Покинуть площадку', // 90
    //   not_approved: 'Не подтверждена', // 100
    // },
    analytic: {
      reports: 'Аналитика',
      bookings: 'Бронирования',
      freightVehicles: 'Грузовые ТС',
      buses: 'Автобусы',
      vehicleReservations: 'Бронирования по ТС',
      userActivityLog: 'Просмотр журнала действий пользователей',
      viewUsers: 'Просмотр пользователей',
      dataFromOperator: 'Данные из АРМ Оператора',
      travelStatistics: 'Статистика проездов',
    },
    dictionary: {
      administration: 'Администрирование',
      vehicleCategories: 'Категории ТС',
      directoryApp: 'Справочник автомобильных пунктов пропуска',
      addApp: 'Добавить',
      name: 'Наименование',
      adjacentCp: 'Сопредельный ПП',
      add: 'Добавлен',
      changed: 'Изменён',
      actions: 'Действия',
      confirmAction: 'Подтвердите действие',
      deleteApp: 'Удалить автомобильный пункт пропуска',
      noDictionariesByRequest: 'Нет справочников по запросу',
      noCategoriesByRequest: 'Нет категорий по запросу',
      icon: 'Иконка',
      subCategories: 'Подкатегории',
      timezone: 'Часовой пояс',
      countryCode: 'Код страны',
      checkpointAddress: 'Адрес пункта пропуска',
      driveSiteAddress: 'Адрес площадки накопителя',
      peakLoads: 'Пиковые нагрузки',
      workingHours: 'Время работы',
      editingApp: 'Редактирование автомобильного пункта пропуска',
      addingApp: 'Добавление автомобильного пункта пропуска',
      editCategory: 'Редактирование категории',
      deletedApp: 'Автомобильный пункт пропуска успешно удалён',
      workHoursHint:
        'Выберите день недели и укажите для него интервал рабочих часов. В случае наличия перерыва укажите два интервала, первый из которых заканчивается на начале перерыва, а второй - начинается с его окончания',
      siteCapacity: 'Вместимость площадки, шт',
      vehicleCapacity: 'Пропускная способность, ТС/сут',
      countLanesApp: 'Количество полос автомобильного пункта пропуска',
      maxOccupancySite: 'Предельная заполняемость площадки, %',
      schemeLabel: 'Схема проезда к пункту пропуска',
      workSchedule: 'График работы',
      Mon: 'Пн',
      Tue: 'Вт',
      Wed: 'Ср',
      Thu: 'Чт',
      Fri: 'Пт',
      Sat: 'Сб',
      Sun: 'Вс',
      addSchedule: 'Добавить график',
      roundTheClock: 'Круглосуточно',
      addWorkTimeApp: 'Добавление времени работы автомобильного пункта пропуска',
      startTime: 'Время начала работы',
      endTime: 'Время окончания работы',
      registration: 'Регистрация пользователей',
      booking_days: 'Доступно дней для бронирования',
      delete_scheme_file_title: 'Удаление файла схемы',
      delete_scheme_file_body: 'Удалить добавленный файл схемы?',
      userManagement: 'Управление пользователями',
    },
    upload: {
      drag_or_upload: 'Перетащите файл или выберите его на устройстве',
      select_file: 'Выбрать файл',
      file_extension: 'Для загрузки выберите файл с расширением',
      file_size: 'Максимально допустимый размер файла',
      allow_file_types_message: 'Разрешена загрузка файлов только с расширениями',
      allow_file_size_message: 'Размер файла должен быть больше 0',
      default_filename: 'Документ',
    },
    common: {
      back: 'Назад',
      loading: 'Загрузка',
      search: 'Поиск',
      add: 'Добавить',
      save: 'Сохранить',
      cancel: 'Отменить',
      cancellation: 'Отмена',
      delete: 'Удалить',
      not_found: 'Не найдено',
      proceed: 'Продолжить',
      close: 'Закрыть',
      to_main: 'На главную',
      no_data: 'Нет данных',
      edit: 'Редактировать',
      confirm: 'Подтвердить',
      data_changed: 'Данные были изменены',
      data_ts_updated: 'Данные ТС номер',
      updated: 'обновлены',
      ts_add_number: 'Добавлено ТС номер',
      total: 'Всего',
      show: 'Показать',
      download: 'Скачать',
      view: 'Просмотр',
      scan_again: 'Сканировать заново',
      change: 'Изменить',
      ok: 'Понятно',
      all: 'Все',
      select: 'Выбрать',
      searching: 'Поиск...',
    },
    registration: {
      title: 'Добавление пользователя',
      name: 'Имя',
      surname: 'Фамилия',
      patronymic: 'Отчество',
      email: 'Email',
      login: 'Логин',
      password: 'Пароль',
      passwordConfirmation: 'Подтверждение пароля',
      mapp_id: 'Автомобильный пункт пропуска оператора',
      role: 'Роль',
      administrator: 'Контролер',
      operator: 'Оператор',
      success_created: 'Пользователь успешно создан',
    },
    attention: {
      title: 'Уважаемые пользователи!',
      text: '<span>С 1 сентября 2024 года резервирование даты и времени прибытия грузового транспортного средства к пунктам контроля Забайкальск, Бугристое и Чернышевское будет осуществояться с использованием <a href="https://public.epd-portal.ru/" target="_blank">ГИС&nbsp;ЭПД</a></span> <span>Более подробная информация о резервировании даты и времени прибытия к пункту контроля с использованием ГИС ЭПД размещена на сайте <a href="https://rosgranstroy.ru/queue" target="_blank">ФГКУ Росгранстрой</a></span>',
    },
  },

  ////////////////////////////////////

  en: {
    header: {
      title: 'The reservation system for crossing the state border',
      site: 'TRS',
      booking: 'Booking',
      transport: 'Transport',
      reports: 'Reports',
      administration: 'Administration',
      app: 'Automobile checkpoint',
      profile: 'Profile',
      notification: 'Notification',
      logout: 'Logout',
    },
    footer: {
      title: 'The system of reservation of time for crossing the state border',
      support: 'Support:',
    },
    profile: {
      profile: 'Profile',
      account: 'Account',
      data: 'Data',
      transport: 'Transport',
      phone: 'Phone',
      change: 'Change',
      number_change: 'Number change',
      new_phone_number: 'New phone number',
      enter_phone_number: 'Enter phone number',
      email_change: 'Email change',
      new_email: 'New email',
      enter_email: 'Enter email',
      status: 'Status',
      select_status: 'Select status',
      physical: 'Individual',
      juridical: 'Legal person',
      undetermined: 'Undetermined',
      editing_data: 'Editing data',
      login: 'Login',
      orgName: 'Name of organization',
      applicantCountry: 'Applicant country',
      confirm_change_title: 'Confirmation of changes',
      confirm_change_body: 'Confirm address change',
    },
    booking: {
      filters: 'Filters',
      booking: 'Booking',
      book: 'Book',
      add_new_booking: 'Add new booking',
      no_reservations_were_found: 'No reservations were found',
      all_statuses: 'All statuses',
      all_ts_types: 'All vehicle types',
      all_apps: 'All APP',
      with_trailer: 'With trailer',
      trailerless: 'Trailerless',
      selecting_a_date_or_period: 'Selecting a date or period',
      // type_options_all: 'All',
      // type_options_active: 'Active',
      // type_options_past: 'Past',
      // time_options_all: 'In all the time',
      // time_options_week: 'Week',
      // time_options_month: 'Month',
      // time_options_half_year: 'Six months',
      // time_options_year: 'Year',
      // status_options_all: 'All statuses',
      // status_options_on_review: 'On review',
      // status_options_register: 'Registered',
      // status_options_confirm: 'The application is not confirmed',
      // status_options_completed: 'Completed',
      // status_options_cancel: 'Canceled',
      year: 'year',
      year_short: 'y',
      vehicle_data: 'Vehicle data',
      category: 'Category',
      plate_number: 'Plate number',
      app_data: 'Automobile checkpoint data',
      name: 'Name',
      address: 'Address',
      contacts: 'Client contacts',
      phone: 'Phone',
      email: 'Email',
      directions_app: 'Directions code for the APP',
      cancel_code: 'Cancel code',
      cancel_booking: 'Cancel booking',
      change_status: 'Change status',
      cancellation_of_booking: 'Cancellation of booking',
      rescheduling_title: 'Rescheduling a booking',
      changing_status: 'Change of status',
      cancellation_warning_message:
        'The possibility of canceling the reservation is blocked, since there are less than 3 (three) hours left until the time specified in the application.',
      rescheduling_warning_message:
        'The possibility of transfer is blocked, since less than 3 (three) hours are left before the time specified in the application.',
      confirm_cancellation_booking: 'Confirm cancellation of booking',
      confirm_rescheduling: 'Confirm rescheduled a booking',
      reservations_canceled: 'Reservations canceled',
      home: 'Home',
      to_create_ts: 'Create vehicle',
      rescheduling: 'Rescheduling a booking',
      rescheduling_hint:
        'Rescheduling and canceling an application for a possible blocking only with active applications no later than 3 hours before booking a travel time.',
      documents: 'Documents attached to the application',
      sts: 'Vehicle registration certificate',
      booking_management: 'Booking Management',

      choose_a_model_and_make: 'Choose a model and make of a truck',
      choose_a_trailer: 'Choose a trailer',
      vehicle: 'Vehicle',
      add_a_new_vehicle: 'Add a new vehicle',
      cargo_availability: 'Cargo availability',
      export: 'Export',
      regular_flight: 'Regular flight',
      irregular_flight: 'Irregular flight',
      route_number: 'Route number',
      availability_of_passengers: 'Availability of passengers',
      number_of_passengers: 'Number of passengers',
      provide_contact_information_for_the_driver: 'Provide contact information for the driver',
      enter_phone_number: 'Enter phone number',
      enter_email: 'Enter email',
      matches_the_contact_details_of_the_profile: 'Matches the contact details of the profile',
      select_app: 'Select APP',
      app: 'APP',
      date: 'Date',
      time: 'Time',
      confirmation_time_after_ime_expires: 'Confirmation time is limited, after the time expires',
      // remaining: 'Remaining',
      captcha_warning: 'To continue, please enter the characters below',
      select_a_date_and_time: 'Select a date and time',
      you_book_interval_in_time_zone_located: 'You book an interval in the time zone where the APT is located',
      you_have_selected: 'You have selected',
      the_application_is_executed: 'The application has been made out',
      you_booked_time: 'You have reserved time to pass the road border crossing',
      whats_next: 'Whats next',
      whats_next_1: 'Booking notification will be sent to the specified e-mail and to the Personal Account',
      whats_next_2: `The cargo vehicle must arrive at the control point located on the road section not earlier than 1 hour before the reserved time.`,
      booking_cancel: 'To cancel or reschedule a booking, go to',
      booking_cancel_link: 'booking card',
      booking_cancellation_code: 'Cancellation code',
      booking_cancellation_code_text:
        'The code is required to cancel a reservation at the storage site via a self-service terminal. Cancellation without code is available in the booking card',
      rolse_of_reservation_on_the_spot: 'Cancellation of a reservation on site',
      travel_for_the_route_to_the_app: 'Directions to the APP',
      print_or_save_the_QR_code:
        'Print or save the QR code on your device. You may need it to travel to and from the storage site in front of the checkpoint',
      to_get_to_the_app_you_may_need_to: 'To get to the APP you may need to',
      qr_code: 'QR-code',
      it_can_be_found_in: 'it can be found in',
      booking_card: 'booking card',
      to_cancel_a_reservation_you_must_enter: 'To cancel a reservation you must enter',
      code: 'code',
      self_service_terminal_it_can_be_found_at: 'at a self-service terminal, it can be found at',
      print_the_qr_code: 'Print the QR code',
      confirm_application: 'Confirm application',
      // confirmation_time_limited: 'Confirmation time is limited, after the time expires',
      // you_return_previous_step: 'you will return to the previous step',
      seats_available: 'Seats available',
      not_chosen: 'Not chosen',
      show_history: 'Show history',
      hide_history: 'Hide history',
      show_your_next_steps: 'Your next steps',
      hide_your_next_steps: 'Hide',
      scheme: 'Scheme of a car checkpoint',
      view_ts: 'Viewing a vehicle document',
      booking_attention:
        'You can book a date and time of border crossing for the next 30 days. If there is no free slot available, it means that all slots are already occupied or you have canceled your reservation within the last 30 minutes. Try to book later',
      not_found: 'No cars available',
      booking_is_not_available: 'Booking is not available',
      booking_is_not_available_body: 'You have canceled your booking within the last 30 minutes. Try again later',
      captcha_failed_title: 'Invalid characters',
      captcha_failed_body: 'You entered incorrect characters to confirm your booking',
      bookings_failed_title: 'There are no available slots',
      bookings_failed_body: 'There are no available slots for the selected time slot. Try choosing another time',
      handbook_title:
        'Have you familiarized yourself with the instructions of the portal inspection and inspection system?',
      handbook_message:
        'At the Zabaikalsk APP a portal-type inspection system is installed, please familiarise yourself with the following rules',
      handbook_file_name_ru: 'Memo (Russian)',
      handbook_file_name_ch: 'Memo (Chinese)',
      informed: 'Informed',
    },
    transport: {
      add_new_vehicle: 'Add a new vehicle',
      vehicles_were_not_found: 'Vehicles were not found',
      adding_a_vehicle: 'Adding a vehicle',
      editing_a_vehicle: 'Editing a vehicle',
      removing_the_vehicle: 'Removing the vehicle',
      the_vehicle_is_removed: 'The vehicle is removed',
      to_the_list_of_transport: 'To the list of transport',
      category: 'Category',
      vehicle_type: 'Vehicle Type',
      brand: 'Brand',
      model: 'Model',
      gosnomer: 'Plate number',
      select_the_vehicle: 'Select the vehicle',
      select_category: 'Select category',
      enter_the_brand: 'Enter the brand',
      enter_the_model: 'Enter the model',
      enter_the_license_plate: 'Enter the license plate',
      passenger: 'Passenger',
      truck: 'Truck',
      trailer: 'Trailer',
      bus: 'Bus',
      bike: 'Bike',
      all: 'All',
      delete_sts_file_title: 'Deleting the VRC file',
      delete_sts_file_body: 'Delete added VRC file?',
      upload_neccessary_docs: 'Upload the necessary documents',
      upload_neccessary_docs_hint:
        'Electronic image (scan copy) of the vehicle (truck or trailer) registration certificate.',
      file_must_have_only_face_side: 'The file shall contain only the front side of the VRC',
      info_about_sts: 'The front side of the VRC contains the following information about the vehicle',
      grnz: 'Registration plate',
      vin: 'Vehicle Identification Number (VIN)',
      model_etc: 'Brand, model, etc.',
      truck_sts: 'Truck VRC',
      trailer_sts: 'Trailer VRC',
      doc_scanning: 'Document scanning is performed...',
      scan_upload: 'Scan',
      scan_result: 'Scan result',
      info_about_number_plate: 'How to fill in the license plate number',
      license_plate_like_sts: 'The number shall be filled in strictly in accordance with the spelling in the VRC',
      license_plate_rules: 'Capitalized Latin characters and numbers are used',
      license_plate_without_spaces: 'The number is entered without spaces',
      inactive_transport_editing: 'Editing and deleting is not available for vehicles with active booking',
      review: 'Under review',
      verified: 'Verified',
      rejected: 'Rejected',
      portal: 'Portal',
      kiosk: 'Kiosk',
      where_created: 'All created vehicles',
      status_ts_updated: 'Vehicle status was updated',
    },
    class: {
      all: 'All',
      passenger: 'Passenger',
      cargo: 'Cargo',
      bus: 'Bus',
      motorcycle: 'Motorcycle',
    },
    login: {
      authorization: 'Authorization',
      register: 'Register',
      cant_enter: 'Can`t enter?',
      enter_email: 'Enter email',
      enter_password: 'enter password',
      password: 'Password',
      login: 'Login',
    },
    app: {
      title: 'APP',
      where_will_you_cross_the_border: 'Where will you cross the border?',
      book: 'Book',
      partPPLabel: 'Contiguous APP',
      noApp: 'No APP on request',
      Mon: 'Monday',
      Tue: 'Tuesday',
      Wed: 'Wednesday',
      Thu: 'Thursday',
      Fri: 'Friday',
      Sat: 'Saturday',
      Sun: 'Sunday',
    },
    notification: {
      notifications: 'Notifications',
      no_notifications: 'Notifications not found',
      no_notifications_by_category: 'No notifications in this category',
      notificationAll: 'All',
      // notificationNews: 'News',
      // notificationBooking: 'Booking',
      // notificationProfile: 'Profile',
      show: 'Show',
      allRead: 'All messages read',
      application_creation: 'Application creation',
      postponement_of_application: 'Postponement of application',
      cancellation_of_application: 'Cancellation of application',
      queue_shift: 'Queue shift',
    },
    // status: {
    //   on_review: 'On review',
    //   registered: 'Registered',
    //   not_confirm: 'The application is not confirmed',
    //   completed: 'Completed',
    //   canceled: 'Canceled',
    //   annulled: 'Annulled',
    //   hidden: 'Hidden',
    //   early_arrived: 'Early arrived',
    // },
    // status: {
    //   all: 'All statuses',
    //   created: 'On review',
    //   planned: 'Registered',
    //   approved: 'Waiting time',
    //   not_arrived: 'Annulled',
    //   transit_to_app: 'Travel to APP',
    //   completed: 'Completed',
    //   canceled: 'Canceled',
    //   cancelled_by_booker: 'Canceled',
    //   cancelled_by_operator: 'Сanceled',
    //   // cancelled_by_system: 'Cancelled by system',
    //   absolutely_invisible: 'Hidden',
    //   early_arrived: 'Leave the site',
    //   not_approved: 'Not confirmed', // 100
    // },
    analytic: {
      reports: 'Reports',
      bookings: 'Bookings',
      freightVehicles: 'Freight vehicles',
      buses: 'Buses',
      vehicleReservations: 'Vehicle reservations',
      userActivityLog: 'Viewing the user activity log',
      viewUsers: 'View Users',
      dataFromOperator: "Data from Operator's workstation",
      travelStatistics: 'Travel statistics',
    },
    dictionary: {
      administration: 'Administration',
      vehicleCategories: 'Vehicle categories',
      directoryApp: 'Directory APP',
      addApp: 'Add',
      name: 'Name',
      adjacentCp: 'Adjacent CP',
      add: 'Add',
      changed: 'Changed',
      actions: 'Actions',
      confirmAction: 'Confirm action',
      deleteApp: 'Delete APP',
      noDictionariesByRequest: 'No dictionaries by request',
      noCategoriesByRequest: 'N o categories by request',
      icon: 'Icon',
      subCategories: 'Subcategories',
      timezone: 'Timezone',
      countryCode: 'Country code',
      checkpointAddress: 'Checkpoint address',
      driveSiteAddress: 'Drive site address',
      peakLoads: 'Peak loads',
      workingHours: 'Working hours',
      editingApp: 'Editing APP',
      addingApp: 'Adding APP',
      editCategory: 'Edit category',
      deletedApp: 'APP successfully deleted',
      workHoursHint:
        'Select the day of the week and specify the working hours interval for it. If there is a break, specify two intervals, the first ending at the start of the break and the second beginning at the end of the break',
      siteCapacity: 'Site capacity, pcs',
      vehicleCapacity: 'Throughput capacity, Veh/day',
      countLanesApp: 'Number of APP lanes',
      maxOccupancySite: 'Maximum occupancy of the site, %',
      schemeLabel: 'Driving directions to the checkpoint',
      workSchedule: 'Work schedule',
      Mon: 'Mon',
      Tue: 'Tue',
      Wed: 'Wed',
      Thu: 'Thu',
      Fri: 'Fri',
      Sat: 'Sat',
      Sun: 'Sun',
      addSchedule: 'Add schedule',
      roundTheClock: 'Round the clock',
      addWorkTimeApp: 'Adding APP operating time',
      startTime: 'Start time',
      endTime: 'End time',

      registration: 'User registration',
      booking_days: 'Available days for booking',
      delete_scheme_file_title: 'Deleting a schema file',
      delete_scheme_file_body: 'Delete added schema file?',
      userManagement: 'User management',
    },
    upload: {
      drag_or_upload: 'Drag and drop a file or select it on your device',
      select_file: 'Choose a file',
      file_extension: 'To upload, select a file with an extension',
      file_size: 'Maximum allowed file size',
      allow_file_types_message: 'Only files with extensions are allowed to be uploaded',
      allow_file_size_message: 'The file size must be greater than 0',
      default_filename: 'Document',
    },
    common: {
      back: 'Back',
      loading: 'Loading',
      search: 'Search',
      add: 'Add',
      save: 'Save',
      cancel: 'Cancel',
      cancellation: 'Cancel',
      delete: 'Delete',
      edit: 'Edit',
      confirm: 'Confirm',
      proceed: 'Proceed',
      to_main: 'Home',
      data_changed: 'The data has been changed',
      data_ts_updated: 'Vehicle data number',
      updated: 'updated',
      ts_add_number: 'Added by TC number',
      total: 'Total',
      show: 'Show',
      download: 'Download',
      view: 'View',
      scan_again: 'Scan again',
      change: 'Change',
      ok: `It's clear`,
      all: 'All',
      select: 'Select',
      searching: 'Searching...',
    },
    registration: {
      title: 'Adding a user',
      name: 'Name',
      surname: 'Surname',
      patronymic: 'Patronymic',
      email: 'Email',
      login: 'Login',
      password: 'Password',
      passwordConfirmation: 'Password confirmation',
      mapp_id: 'Operator APP',
      role: '',
      administrator: 'Administrator',
      operator: 'Operator',
      success_created: 'User successfully created',
    },
    attention: {
      title: 'Dear users!',
      text: '<span>From September 1, 2024, the reservation of the date and time of arrival of a cargo vehicle to the Zabaikalsk, Bugristoye and&nbsp;Chernyshevskoye checkpoints will be carried out using <a href="https://public.epd-portal.ru/" target="_blank">GIS&nbsp;EDI</a></span> <span>More detailed information on reserving the date and time of arrival at the control point using GIS&nbsp;EDS is available on the website of&nbsp;<a href="https://rosgranstroy.ru/queue" target="_blank">FGCU Rosgranstroi</a></span>',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('lang') || 'ru',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
