import { useTranslation } from 'react-i18next';

function TableHead() {
  const { t } = useTranslation('dictionary');

  return (
    <div className="d-flex">
      <div style={{ width: '10%' }} className="flex-shrink-0 text-secondary fs-7">
        {t('icon')}
      </div>
      <div style={{ width: '25%' }} className="flex-shrink-0 text-secondary fs-7">
        {t('name')}
      </div>
      <div style={{ width: '25%' }} className="flex-shrink-0 text-secondary fs-7">
        {t('subCategories')}
      </div>
      <div style={{ width: '15%' }} className="flex-shrink-0 text-secondary fs-7">
        {t('add')}
      </div>
      <div style={{ width: '15%' }} className="flex-shrink-0 text-secondary fs-7">
        {t('changed')}
      </div>
      <div style={{ width: '10%' }} className="flex-shrink-0 text-center text-secondary fs-7">
        {t('actions')}
      </div>
    </div>
  );
}

export default TableHead;
