import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import store from '../../../../store';

import Loader from '../../../../components/Loader';

type RouterParams = {
  id: string;
};

const { notificationStore } = store;

function TransportSingle() {
  const { id } = useParams<RouterParams>();

  const { t, i18n } = useTranslation(['transport', 'common']);
  const isTerminalClient = store.isFSEQ();

  useEffect(() => {
    if (!id) return;

    notificationStore.setIsLoading(true);

    notificationStore
      .fetchNotificationById(id, i18n.language)
      .then(() => {
        if (notificationStore.notification?.isRead) return;

        notificationStore
          .readNotifications([id])
          .then(() => {
            notificationStore.fetchUnreadNotificationCount();
          })
          .finally(() => notificationStore.setIsLoading(false));
      })
      .finally(() => notificationStore.setIsLoading(false));

    return () => {
      notificationStore.setNotification();
    };
  }, [id, i18n.language]);

  return (
    <>
      <Container
        className={`${
          !isTerminalClient ? 'flex-grow-1 ' : 'm-auto terminal-min-height transform-y-180px '
        }pb-3 pb-sm-5`}
      >
        <Row>
          <Col md={9}>
            <Link to="/notification" className="link-back my-4">
              {t('back', { ns: 'common' })}
            </Link>
            <div className="bg-white p-4 shadow-main rounded d-flex flex-column" style={{ marginBottom: '4rem' }}>
              {notificationStore.isLoading && <Loader />}

              {!notificationStore.isLoading && (
                <>
                  <span className="fs-5 fw-bold text-black mb-4">{notificationStore.notification?.header}</span>
                  {/* <span className="fs-6 text-secondary mb-4">
                    Отправитель: {notificationStore.notification?.header}
                  </span> */}
                  <span className="fs-6">{notificationStore.notification?.body}</span>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default observer(TransportSingle);
