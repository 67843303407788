import { useEffect } from 'react';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Loader from '../../../../../components/Loader';
import Icon from '../../../../../components/Icon';
import EmptyResult from '../../../../../components/EmptyResult';
import InputSearch from '../../../../../components/InputSearch';
import PaginationSet from '../../../../../components/PaginationSet';
import DeleteModal from './components/DeleteModal';

import store from './store';

import rootStore from '../../../../../store';
import { toast } from 'react-toastify';
const { mappStore, filterStore, isFSEQ } = rootStore;

function DictionaryAppList() {
  const { t } = useTranslation(['transport', 'dictionary', 'common']);

  const { search } = useLocation();
  const [, setSearchParams] = useSearchParams();
  const isTerminalClient = isFSEQ();

  useEffect(() => {
    filterStore.set({}, search);
    mappStore.fetchMapps(filterStore.searchParams, true);
  }, [search]);

  const updateFilter = (key: string, value: string) => {
    if (key === 'search' || key === 'limit' || key === 'carClassId') {
      filterStore.update('page', '1');
    }

    filterStore.update(key, value).then(() => setSearchParams(filterStore.filteredParams));
  };

  const onDeleteHandler = async (id: string) => {
    store.setIsOpenModal(false);

    const isSuccessDeletedMapp = await mappStore.deleteMapp(id);

    if (isSuccessDeletedMapp) {
      toast.info(t('deletedApp', { ns: 'dictionary' }));
    }

    if (search) {
      setSearchParams('');
    } else {
      mappStore.fetchMapps('', true);
    }
  };

  const onCloseHandler = () => {
    store.setIsOpenModal(false);
    store.setSelectedMapp();
  };

  const openModal = (mapp: Mapp) => {
    store.setIsOpenModal(true);
    store.setSelectedMapp(mapp);
  };

  const Thead = () => (
    <div className="d-flex">
      <div style={{ width: '45%' }} className="flex-shrink-0 text-secondary fs-7">
        {t('name', { ns: 'dictionary' })}
      </div>
      <div style={{ width: '45%' }} className="flex-shrink-0 text-secondary fs-7">
        {t('adjacentCp', { ns: 'dictionary' })}
      </div>
      {/* <div style={{ width: '15%' }} className="flex-shrink-0 text-secondary fs-7">
        {t('add', { ns: 'dictionary' })}
      </div>
      <div style={{ width: '15%' }} className="flex-shrink-0 text-secondary fs-7">
        {t('changed', { ns: 'dictionary' })}
      </div> */}
      <div style={{ width: '10%' }} className="flex-shrink-0 text-center text-secondary fs-7">
        {t('actions', { ns: 'dictionary' })}
      </div>
    </div>
  );

  const TRow = ({ mapp }: { mapp: Mapp }) => (
    <div className="d-flex">
      <div style={{ width: '45%' }} className="flex-shrink-0 text-primary">
        {mapp.name}
      </div>
      <div style={{ width: '45%' }} className="flex-shrink-0">
        {mapp.part_pp}
      </div>
      {/* <div style={{ width: '15%' }} className="flex-shrink-0">
        -
      </div>
      <div style={{ width: '15%' }} className="flex-shrink-0">
        -
      </div> */}
      <div style={{ width: '10%' }} className="flex-shrink-0 d-flex justify-content-between align-items-center px-12px">
        <Link to={`/dictionary/app/${mapp.id}`} style={{ color: '#0B1F33' }}>
          <Icon name="edit" className="cursor-pointer" />
        </Link>
        <Icon
          name="trash"
          onClick={() => openModal(mapp)}
          className="cursor-pointer"
          style={{ position: 'relative', top: 2, left: 6, opacity: 1 }}
        />
      </div>
    </div>
  );

  const { mapps, isLoading } = mappStore;

  const hasMapps = !!mapps?.length;

  return (
    <>
      <Container
        className={`${
          !isTerminalClient ? 'flex-grow-1 ' : 'm-auto terminal-min-height transform-y-180px '
        }pb-3 pb-sm-5`}
      >
        <Row>
          <Col lg={isTerminalClient ? 12 : 9}>
            <Link to="/dictionary" className="link-back my-4">
              {t('back', { ns: 'common' })}
            </Link>
            <h2 className="mt-40px mb-4">{t('directoryApp', { ns: 'dictionary' })}</h2>

            <div className="bg-white rounded shadow-main" style={{ marginBottom: '4rem' }}>
              <div className="d-flex p-4" style={{ borderBottom: '1px solid #E1E1E1' }}>
                <InputSearch
                  placeholder={t('search', { ns: 'common' }) || ''}
                  value={filterStore.params?.search || ''}
                  onChange={(newValue: string) => updateFilter('search', newValue)}
                  className="w-100"
                />

                <Link
                  to={'/dictionary/app/add'}
                  style={{ whiteSpace: 'nowrap' }}
                  className="d-flex align-items-center px-40px"
                >
                  {t('addApp', { ns: 'dictionary' })}
                </Link>
              </div>

              {isLoading && <Loader />}

              {!isLoading && hasMapps && (
                <div className="d-flex flex-column gap-4 p-4">
                  <Thead />
                  {mapps?.map((mapp: Mapp, index) => (
                    <div key={index}>
                      <TRow mapp={mapp} />
                    </div>
                  ))}
                </div>
              )}

              {!isLoading && !!hasMapps && (
                <PaginationSet
                  items={Number(filterStore.params.total)}
                  page={Number(filterStore.params.page)}
                  pageCount={Number(filterStore.params.limit)}
                  onPageCountChange={(page) => updateFilter('limit', String(page))}
                  onPageChange={(page) => updateFilter('page', String(page))}
                  className="py-4 px-28px"
                />
              )}

              {!isLoading && !hasMapps && <EmptyResult text="Нет автомобильных пунктов пропуска по запросу" />}
            </div>
          </Col>
        </Row>
      </Container>

      <DeleteModal
        mapp={store.mapp}
        isOpenModal={store.isOpenModal}
        onCloseHandler={onCloseHandler}
        onDeleteHandler={onDeleteHandler}
      />
    </>
  );
}

export default observer(DictionaryAppList);
