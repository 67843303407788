import { useState, useEffect, useRef } from 'react';
import Modal from '../Modal';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';

function InactivityWarning() {
  const [showWarning, setShowWarning] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const { t } = useTranslation('common');
  const { keycloak } = useKeycloak();

  const inactivityTimerRef = useRef<NodeJS.Timer | null>(null);
  const countdownIntervalRef = useRef<NodeJS.Timer | null>(null);

  const startInactivityTimer = () => {
    inactivityTimerRef.current = setTimeout(showWarningMessage, 60000);
  };

  const clearInactivityTimer = () => {
    clearTimeout(inactivityTimerRef.current as NodeJS.Timer);
  };

  const handleActivity = () => {
    clearInactivityTimer();
    startInactivityTimer();
    hideWarningMessage();
  };

  const logOut = () => {
    localStorage.removeItem('seq_kk_token');
    window.dispatchEvent(new Event('storage'));
    keycloak.logout();
  };

  const showWarningMessage = () => {
    setShowWarning(true);
    window.removeEventListener('mousemove', handleActivity);
    window.removeEventListener('keydown', handleActivity);

    let countdownValue = 10;
    setCountdown(countdownValue);
    countdownIntervalRef.current = setInterval(() => {
      countdownValue--;
      setCountdown(countdownValue);
      if (countdownValue === 0) {
        logOut();
      }
    }, 1000);
  };

  const hideWarningMessage = () => {
    setShowWarning(false);
    setCountdown(10);
    clearInactivityTimer();
    clearInterval(countdownIntervalRef.current as NodeJS.Timer);
    startInactivityTimer();
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
  };

  useEffect(() => {
    startInactivityTimer();
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    return () => {
      clearInactivityTimer();
      clearInterval(countdownIntervalRef.current as NodeJS.Timer);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, []);

  return (
    <div>
      <Modal
        show={showWarning}
        header="Продолжить работу?"
        body={
          <div className="w-100 d-flex flex-column align-items-center justify-content-center">
            <p>В случае отсутствия каких-либо действий, автоматический выход произойдёт через</p>
            <h1 className="text-center">{countdown}</h1>
          </div>
        }
        footer={
          <div className="w-100 d-flex justify-content-center">
            <Button variant="primary" onClick={hideWarningMessage} text={t('proceed')} />
          </div>
        }
      />
    </div>
  );
}

export default InactivityWarning;
