import { observer } from 'mobx-react-lite';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import LanguageSwitcher from '../LanguageSwitcher';
import Button from '../Button';
import { keycloak } from '../../keycloak';

function TerminalHeader() {
  const { t } = useTranslation('header');
  const logOut = () => {
    localStorage.removeItem('seq_kk_token');
    window.dispatchEvent(new Event('storage'));
    keycloak.logout({ redirectUri: `${process.env.REACT_APP_BASE_URL}/booking-process` });
  };

  return (
    <div className="mb-5">
      <div className="d-flex align-items-center justify-content-end mb-5 gap-3">
        <div>
          <LanguageSwitcher isTerminalClient={true} />
        </div>
        <div>
          <Button className="terminal-button-sm" text={t('logout')} variant="outline" onClick={logOut} />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <Nav className="gap-5">
          <Nav.Item>
            <NavLink to="/booking" className="terminal-nav-item">
              {t('booking')}
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to="/transport" className="terminal-nav-item">
              {t('transport')}
            </NavLink>
          </Nav.Item>
        </Nav>
      </div>
    </div>
  );
}

export default observer(TerminalHeader);
