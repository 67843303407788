import { format as fnsFormat } from 'date-fns';
import { ru, enUS } from 'date-fns/locale';
import { classOptions, ICON_BY_TRANSPORT_CLASS, PERIODS, STATUSES } from './static';

const phoneFormat = (phone: string) => {
  if (phone[0] === '+') {
    return phone.replace(/[A-Za-zА-Яа-я]/g, '');
  } else {
    return '+' + phone.replace(/[A-Za-zА-Яа-я]/g, '');
  }
};

const pluralize = ({ count, one, few, many }: { count: number; one: string; few: string; many: string }) => {
  const n = Math.abs(count) % 100;

  if (n % 100 >= 11 && n % 100 <= 14) return many;

  switch (n % 10) {
    case 1:
      return one;
    case 2:
    case 3:
    case 4:
      return few;
    default:
      return many;
  }
};

const transportClassToOption = (transportClass: string): SelectOption => {
  const index = Number(transportClass) - 1;
  return classOptions[index];
};

const getTransportIconByClass = (transportClass: string) => {
  return ICON_BY_TRANSPORT_CLASS[transportClass];
};

const getStatus = (statusCode: string) => {
  return STATUSES[statusCode];
};

const getDate = (timestamp: string | null | undefined, format: string | any, byUtc = false) => {
  if (!timestamp) return '';

  const lang = localStorage.getItem('lang') === 'ru' ? ru : enUS;
  const date = byUtc ? convertUtcDateInLocaleTimezone(new Date(timestamp)) : new Date(timestamp);

  return fnsFormat(date, format, { locale: lang });
};

const convertUtcDateInLocaleTimezone = (date: Date): Date => {
  const millisecondsInMinute = 60000;

  return new Date(date.getTime() + date.getTimezoneOffset() * millisecondsInMinute);
};

const getSlot = (timestamp: string, byUTC = false) => {
  const index = byUTC ? new Date(timestamp).getUTCHours() : new Date(timestamp).getHours();
  return PERIODS[index];
};

const getNotificationIconById = (notificationId: string) => {
  switch (notificationId) {
    case 'News':
      return 'mail';
    case 'Booking':
      return 'blank';
    case 'Profile':
      return 'user_full';
  }
};

const getNotificationLabelById = (notificationId: string) => {
  switch (notificationId) {
    case 'News':
      return 'notificationNews';
    case 'Booking':
      return 'notificationBooking';
    case 'Profile':
      return 'notificationProfile';
  }
};

const scrollTop = () => {
  window.scrollTo({ top: 0 });
};

const formatBytes = (bytes: number) => {
  if (bytes === 0) {
    return '0 B';
  }

  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = parseFloat((bytes / Math.pow(k, i)).toFixed(2));

  return `${size} ${sizes[i]}`;
};

const downloadFile = (response: Blob | File, filename = 'file') => {
  const newBlob = response instanceof File ? response : new File([response], filename, { type: response.type });

  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.download = (newBlob as File).name;
  link.click();
  window.URL.revokeObjectURL(data);
};

export default Object.assign({
  phoneFormat,
  pluralize,
  transportClassToOption,
  getTransportIconByClass,
  getStatus,
  getDate,
  getSlot,
  getNotificationIconById,
  getNotificationLabelById,
  scrollTop,
  formatBytes,
  downloadFile,
});
