import { useRef } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { uid } from 'uid';
import Icon from './../Icon';

function InputText({ label = null, errorMsg, infoMsg, clear, className, ...props }: InputExtendedProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const isCrossVisible = props.value && !props.disabled;

  const { current: id } = useRef(uid());

  const onClearHandler = () => {
    clear && clear();
    inputRef.current && inputRef.current.focus();
  };

  const crossColor = () => {
    return props.isInvalid ? '#EE3F58' : '#9DACCE';
  };

  const Label = () => <Form.Label htmlFor={id}>{label}</Form.Label>;

  const ErrorMsg = () => <div className="text-danger fs-7 mb-1">{errorMsg}</div>;

  const InfoMsg = () => <div className="mt-2">{infoMsg}</div>;

  return (
    <FormGroup className={className}>
      {label && <Label />}

      {errorMsg && <ErrorMsg />}

      <div className="form-control-wrapper position-relative">
        <Form.Control
          id={id}
          ref={inputRef}
          autoComplete="one-time-code"
          spellCheck={false}
          isInvalid={!!errorMsg}
          {...props}
        />

        {!!isCrossVisible && (
          <Icon
            name="inputCross"
            className="position-absolute invisible"
            width={22}
            height={22}
            style={{ top: '1rem', right: '1.25rem', cursor: 'pointer', color: crossColor() }}
            onClick={onClearHandler}
          />
        )}
      </div>

      {!!infoMsg && <InfoMsg />}
    </FormGroup>
  );
}

export default InputText;
