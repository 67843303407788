import { action, makeAutoObservable } from 'mobx';
import Api, { MetaBaseConfig } from './api';

export class MetaBaseStore {
  config?: MetaBaseConfig;
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  async fetchMetaBaseDashboard(id: string) {
    this.setIsLoading(true);

    await Api.fetchMetaBaseDashboard(id)
      .then(
        action('fetchSuccess', (response: MetaBaseConfig) => {
          this.setConfig(response);
        }),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  setConfig(config?: MetaBaseConfig) {
    this.config = config;
  }

  get dashboardUrl() {
    return (
      process.env.REACT_APP_BASE_METABASE_URL + '/embed/dashboard/' + this.config?.token + '#bordered=true&titled=true'
    );
  }
}

const metaBaseStore = new MetaBaseStore();
export default metaBaseStore;
