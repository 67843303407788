import { useState } from 'react';
import { SingleValue } from 'react-select';

import Select from '../components/Select';

export interface ISelectOption {
  value: string;
  label: string;
}

export const options: ISelectOption[] = [
  { value: 'Легковое', label: 'Легковое' },
  { value: 'Грузовое', label: 'Грузовое' },
  { value: 'Автобус', label: 'Автобус' },
  { value: 'Мотоцикл', label: 'Мотоцикл' },
];

function Selects() {
  const [selectedValue, setSelectedValue] = useState<SingleValue<ISelectOption>>(null);

  const onChangeHandler = (value: SingleValue<ISelectOption>) => {
    setSelectedValue(value);
  };

  return (
    <section>
      <h4 className="my-3">Обычный селект</h4>
      <Select
        label="Транспортное средство"
        placeholder="Выберите транспортное средство"
        options={options}
        value={selectedValue}
        onChange={onChangeHandler}
      />
      <h4 className="my-3">Деактивированный селект</h4>
      <Select
        label="Транспортное средство"
        placeholder="Выберите транспортное средство"
        options={options}
        value={selectedValue}
        onChange={onChangeHandler}
        isDisabled
      />
      <div className="mt-3 p-2 border">
        <h4 className="mt-0 mb-3">Раздвигающийся селект</h4>
        <Select
          isCollapse
          label="Транспортное средство"
          placeholder="Выберите транспортное средство"
          options={options}
          value={selectedValue}
          onChange={onChangeHandler}
        />
      </div>
    </section>
  );
}

export default Selects;
