import { AuthStore } from './authStore';
import { FilterStore } from './filterStore';
import { ProfileStore } from '../entity/Profile/store';
import { BookingStore } from '../entity/Booking/store';
import { BookingProcessStore } from '../entity/BookingProcess/store';
import { TransportStore } from '../entity/Transport/store';
import { MappStore } from '../entity/Mapp/store';
import { NotificationStore } from '../entity/Notification/store';
import { UserRegistrationStore } from '../entity/UserRegistration/store';
import { autorun } from 'mobx';
import Cookies from 'js-cookie';

export class RootStore {
  authStore = new AuthStore(this);
  profileStore = new ProfileStore(this);
  bookingStore = new BookingStore(this);
  bookingProcessStore = new BookingProcessStore(this);
  transportStore = new TransportStore(this);
  mappStore = new MappStore(this);
  notificationStore = new NotificationStore(this);
  userRegistrationStore = new UserRegistrationStore(this);
  filterStore = new FilterStore(this);

  isFSEQ(): boolean {
    return !!Cookies.get('fseq_kiosk');
  }

  getMappIdFromFSEQ(): string {
    return Cookies.get('fseq_kiosk') || '';
  }
}

const store = new RootStore();

// configure({
//   enforceActions: 'never',
// });

autorun(() => {
  if (!store.authStore.account) return;

  store.notificationStore.fetchUnreadNotificationCount();
  setInterval(() => {
    store.notificationStore.fetchUnreadNotificationCount();
  }, 5 * 60 * 1000);
});

export default store;
