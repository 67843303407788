import client from '../../httpClient/clientV1';

export type FetchBookingsResponse = Pagination & {
  data: Booking[];
};

type RequestCancelBooking = {
  id: string;
  cancelCode: string;
};

export type BookingStatus = {
  status_code: string;
  name_ru: string;
  name_en: string;
};

export type BookingStatusResponse = {
  statuses: BookingStatus[];
};

export type BookingsStatusChangeParam = {
  id: string;
  status: string;
};

type BookingHistoryResponse = {
  data: BookingHistory[];
};

export type BookingHistory = {
  created_at: string;
  status: string;
};

async function fetchBookings(searchParams?: string) {
  const params = searchParams === undefined ? '' : `?${searchParams}`;

  const response = await client.get<FetchBookingsResponse>(`/bookings${params}`);

  return response.data;
}

async function fetchBookingById(id: string) {
  const response = await client.get<{ data: Booking }>(`/bookings/${id}`);
  return response.data;
}

async function cancelBooking(data: RequestCancelBooking) {
  const response = await client.post<{ status: boolean }>('/bookings_cancel', data);
  return response.data;
}

async function fetchBookingStatuses() {
  const result = await client.get<BookingStatusResponse>('/booking_statuses');
  return result.data;
}

async function fetchChangeStatus(data: BookingsStatusChangeParam) {
  const result = await client.post<any>('/bookings_status_change', data);
  return result.data;
}

async function fetchHistory(id: string) {
  const result = await client.get<BookingHistoryResponse>(`/booking_history/${id}`);
  return result.data;
}

export default Object.assign({
  fetchBookings,
  fetchBookingById,
  cancelBooking,
  fetchBookingStatuses,
  fetchChangeStatus,
  fetchHistory,
});
