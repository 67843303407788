import { ReactNode } from 'react';
import { Modal as BSModal, ModalProps as BSModalProps } from 'react-bootstrap';
import classNames from 'classnames';
import Icon from './../../components/Icon';

type ModalProps = BSModalProps & {
  header?: string | ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  hideCloseButton?: boolean;
};

function Modal({ header, body, footer, hideCloseButton, contentClassName, ...props }: ModalProps) {
  if (props.centered === undefined) props.centered = true;
  if (props.scrollable === undefined) props.scrollable = true;

  return (
    <BSModal {...props}>
      {!hideCloseButton && (
        <Icon name="close" width={22} height={22} className="btn-cross position-absolute" onClick={props.onHide} />
      )}
      {header && (
        <BSModal.Header>
          <BSModal.Title>{header}</BSModal.Title>
        </BSModal.Header>
      )}
      {body && <BSModal.Body className={classNames({ 'pb-4': footer })}>{body}</BSModal.Body>}
      {footer && <BSModal.Footer>{footer}</BSModal.Footer>}
    </BSModal>
  );
}

export default Object.assign(Modal, {
  Body: BSModal.Body,
  Header: BSModal.Header,
  Title: BSModal.Title,
  Footer: BSModal.Footer,
  Dialog: BSModal.Dialog,
  TRANSITION_DURATION: BSModal.TRANSITION_DURATION,
  BACKDROP_TRANSITION_DURATION: BSModal.BACKDROP_TRANSITION_DURATION,
});
