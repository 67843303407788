import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../components/Button';
import Modal from '../../../../../../components/Modal';

type Props = {
  mapp?: Mapp;
  isOpenModal: boolean;
  onCloseHandler: () => void;
  onDeleteHandler: (id: string) => void;
};

function DeleteModal({ mapp, isOpenModal, onCloseHandler, onDeleteHandler }: Props) {
  const { t } = useTranslation(['dictionary', 'common']);

  const mappName = `${t('deleteApp')} ${mapp?.name || ''}`;

  return (
    <Modal
      show={isOpenModal}
      onHide={onCloseHandler}
      header={t('confirmAction') || ''}
      body={mappName}
      footer={
        <div className="d-flex flex-column flex-sm-row gap-4 w-100">
          <Button
            variant="primary"
            onClick={() => onDeleteHandler(mapp?.id || '')}
            text={t('delete', { ns: 'common' })}
          />
          <Button variant="outline" onClick={onCloseHandler} text={t('cancellation', { ns: 'common' })} />
        </div>
      }
    />
  );
}

export default observer(DeleteModal);
