import { Link } from 'react-router-dom';

function Typography() {
  return (
    <>
      <section>
        <h4 className="mb-3">Заголовки</h4>
        <h1>Заголовок - h1</h1>
        <h2>Заголовок - h2</h2>
        <h3>Заголовок - h3</h3>
        <h4>Заголовок - h4</h4>
        <h5>Заголовок - h5</h5>
        <h6>Заголовок - h6</h6>
      </section>
      <hr className="my-3" />
      <section>
        <h4 className="mb-3">Цвет текста</h4>
        <p className="text-primary">.text-primary</p>
        <p className="text-secondary">.text-secondary</p>
        {/* <p className="text-success">.text-success</p> */}
        <p className="text-danger">.text-danger</p>
        {/* <p className="text-warning bg-dark">.text-warning</p>
        <p className="text-info bg-dark">.text-info</p>
        <p className="text-light bg-dark">.text-light</p>
        <p className="text-dark">.text-dark</p>
        <p className="text-body">.text-body</p>
        <p className="text-muted">.text-muted</p>
        <p className="text-white bg-dark">.text-white</p>
        <p className="text-black-50">.text-black-50</p>
        <p className="text-white-50 bg-dark">.text-white-50</p> */}
      </section>
      <section>
        <h4 className="mb-3">Ссылка</h4>
        <Link to="#" className="link-primary fs-7 me-5">
          Пример ссылки 14px
        </Link>
        <Link to="#" className="link-primary fs-6">
          Пример ссылки 16px
        </Link>
        <Link to="#" className="link-back mt-2" onClick={() => alert('go back!')}>
          Назад
        </Link>
      </section>
      <section>
        <h4 className="my-3">Список</h4>
        <ul>
          <li>Проверьте корректность вводимых данных – без тире и пробела, язык ввода, клавишу «Caps Lock»</li>
          <li>
            Воспользуйтесь функцией
            <Link to="#" className="link-primary fs-8 ms-1">
              восстановления пароля
            </Link>
          </li>
        </ul>
        <ol className="mt-3">
          <li>Для проезда к автомобильному пункту пропуска может понадобиться QR-код</li>
          <li>Для отмены бронирования необходимо ввести код в терминале самообслуживания</li>
        </ol>
      </section>
    </>
  );
}

export default Typography;
