// import clientKeycloak from '../../httpClient/clientKeycloak';
import client from '../../httpClient/clientV1';
// import { UserStatus } from '../../static';

export type Profile = {
  id: string;
  login: string;
  phone: string;
  email: string;
  type: string;
  name: string;
  surname?: string;
  patronymic?: string;
  client_name?: string;
  country?: string;
  notify_email: string;
};

export type ProfileKeys = keyof Profile;

export type ProfileShort = Pick<Profile, 'email' | 'phone'>;

export type ProfileShortKeys = keyof ProfileShort;

export type UpdateProfileRequest = {
  [key: string]: string;
};

// async function fetchProfile() {
//   const response = await clientKeycloak.get(
//     `${process.env.REACT_APP_KEYCLOAK_API_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/userinfo`
//   );

//   return response.data;
// }

export async function fetchProfile() {
  const result = await client.get<{ data: Profile }>('/user');
  return result.data;
}

async function updateProfile(user: UpdateProfileRequest) {
  const response = await client.put('/user', user);
  return response.data;
}

export async function fetchCountries() {
  const result = await client.get<CountryType[]>('/dictionaries/countries');
  return result.data;
}

export default Object.assign({
  fetchProfile,
  updateProfile,
  fetchCountries,
});
