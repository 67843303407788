import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dictionary as LDictionary } from 'lodash';
// import { Booking } from '../../../../api';
import Utils from './../../../../../../utils';
import { TransportClassId } from '../../../../../../static';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '../../../../../../components/Icon';

function BookingCard({
  booking,
  bookingStatusesDict,
}: {
  booking: Booking;
  bookingStatusesDict: LDictionary<SelectOption>;
}) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(['booking']);

  const bookingDate = Utils.getDate(booking.time_slot, 'd MMMM yyyy', true) + ' ' + t('year');
  const slot = Utils.getSlot(booking.time_slot, true);
  const statusName = bookingStatusesDict[booking.status]?.label || '';

  return (
    <div
      className="d-flex flex-column flex-md-row p-4 rounded custom-pointer bg-white shadow-main mb-3 mb-md-4 cursor-pointer"
      onClick={() => navigate(`/booking/${booking.id}`)}
    >
      <div className="col d-flex align-items-center gap-3">
        <OverlayTrigger overlay={<Tooltip>{statusName}</Tooltip>}>
          <span>
            <Icon name={booking.status}></Icon>
          </span>
        </OverlayTrigger>
        <div className="d-grid mb-3 mb-md-0">
          <span>{bookingDate}</span>
          <span>{slot}</span>
        </div>
      </div>
      <div className="col">
        <span className="ms-0 ms-md-3">{booking.mapp[i18n.language === 'en' ? 'name_eng' : 'name']}</span>
      </div>
      <div className="col">
        {booking.cars?.[TransportClassId.TRUCK] && (
          <div className="d-flex align-items-start gap-3 mb-3 mb-md-0">
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {booking.cars?.[TransportClassId.TRUCK]?.[i18n.language === 'en' ? 'type_name_eng' : 'type_name']}
                </Tooltip>
              }
            >
              <span style={{ color: '#66727F' }}>{Utils.getTransportIconByClass('1')}</span>
            </OverlayTrigger>
            <div className="d-grid">
              <span>
                {booking.cars?.[TransportClassId.TRUCK]?.name} {booking.cars?.[TransportClassId.TRUCK]?.model}
              </span>
              <span>{booking.cars?.[TransportClassId.TRUCK]?.grnz}</span>
            </div>
          </div>
        )}
      </div>
      <div className="col">
        {booking.cars?.[TransportClassId.TRAILER] && (
          <div className="d-flex align-items-start gap-3 mb-3 mb-md-0">
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {booking.cars?.[TransportClassId.TRAILER]?.[i18n.language === 'en' ? 'type_name_eng' : 'type_name']}
                </Tooltip>
              }
            >
              <span style={{ color: '#66727F' }}>{Utils.getTransportIconByClass('2')}</span>
            </OverlayTrigger>
            <div className="d-grid">
              <span>{booking.cars?.[TransportClassId.TRAILER].grnz}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BookingCard;
