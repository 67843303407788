import { makeAutoObservable } from 'mobx';

export class Store {
  mapp?: Mapp;
  isOpenModal = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsOpenModal(value: boolean) {
    this.isOpenModal = value;
  }

  setSelectedMapp(mapp?: Mapp) {
    this.mapp = mapp;
  }
}

const store = new Store();
export default store;
