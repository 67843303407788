import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Icon from '../../../../../../components/Icon';

function TableRow({ category }: { category: DictionaryCategory }) {
  const { t } = useTranslation('transport');

  return (
    <div className="d-flex">
      <div style={{ width: '10%' }} className="flex-shrink-0">
        <Icon name={category.icon} />
      </div>
      <div style={{ width: '25%' }} className="flex-shrink-0">
        {t(category.name)}
      </div>
      <div style={{ width: '25%' }} className="flex-shrink-0">
        {category.subcategory.join(', ')}
      </div>
      <div style={{ width: '15%' }} className="flex-shrink-0">
        {/* {category.created} */} -
      </div>
      <div style={{ width: '15%' }} className="flex-shrink-0">
        {/* {category.updated} */} -
      </div>
      <div style={{ width: '10%' }} className="flex-shrink-0 d-flex justify-content-between align-items-center px-12px">
        <Link to={`/dictionary/category/${category.id}`} style={{ color: '#0B1F33' }}>
          <Icon name="edit" className="cursor-pointer" />
        </Link>
        <Icon name="trash" style={{ position: 'relative', top: 2, left: 6, opacity: 0.3 }} />
      </div>
    </div>
  );
}

export default observer(TableRow);
