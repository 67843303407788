type Props = {
  title: string;
  text: string;
};

function Attention({ title, text }: Props) {
  return (
    <div className="p-4 rounded shadow-main bg-white">
      <h5 className="mb-4">{title}</h5>
      <div className="d-grid gap-2" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
}

export default Attention;
