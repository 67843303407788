import { useRef, useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { uid } from 'uid';
import Icon from './../Icon';

function InputPassword({ label, errorMsg, infoMsg, className, ...props }: InputBaseProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const { current: id } = useRef(uid());

  const crossColor = () => {
    return props.isInvalid ? '#EE3F58' : '#9DACCE';
  };

  const Label = () => <Form.Label htmlFor={id}>{label}</Form.Label>;

  const ErrorMsg = () => <div className="text-danger fs-7 mb-1">{errorMsg}</div>;

  const InfoMsg = () => <div className="mt-2">{infoMsg}</div>;

  const inputType = isOpen ? 'text' : 'password';
  const iconName = isOpen ? 'eyeOpen' : 'eyeClose';

  return (
    <FormGroup className={className}>
      {label && <Label />}

      {errorMsg && <ErrorMsg />}

      <div className="form-control-wrapper position-relative">
        <Form.Control type={inputType} id={id} ref={inputRef} spellCheck={false} isInvalid={!!errorMsg} {...props} />
        <Icon
          name={iconName}
          className="position-absolute"
          width={22}
          height={22}
          style={{ top: '1rem', right: '1.25rem', cursor: 'pointer', color: crossColor() }}
          onClick={() => setIsOpen((prev) => !prev)}
        />
      </div>

      {infoMsg && <InfoMsg />}
    </FormGroup>
  );
}

export default InputPassword;
