function Shadows() {
  return (
    <div className="row">
      <div className="col m-4 p-3 shadow-main">shadow-main</div>
      <div className="col m-4 p-3 shadow-head">shadow-head</div>
      <div className="col m-4 p-3 shadow-pop-up">shadow-pop-up</div>
    </div>
  );
}

export default Shadows;
