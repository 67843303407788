import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';

type Props = {
  title: string;
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
  booking: Booking | null;
};

function ReschedulingConfirmModal({ title, show, onClose, onSuccess, booking }: Props) {
  const { t } = useTranslation(['booking', 'common']);

  return (
    <Modal
      show={show}
      onHide={() => onClose()}
      header={t('rescheduling_title') || ''}
      body={<>{title}</>}
      footer={
        <div className="d-flex flex-column flex-sm-row gap-3 gap-lg-4 w-100">
          <Button text={t('confirm', { ns: 'common' })} onClick={() => onSuccess()} />
          <Button variant="outline" onClick={() => onClose()} text={t('cancel', { ns: 'common' })} />
        </div>
      }
    />
  );
}

export default ReschedulingConfirmModal;
