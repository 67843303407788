import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function TableRow({ dictionary }: { dictionary: Dictionary }) {
  const { t } = useTranslation('dictionary');

  return (
    <div className="d-flex">
      <div className="flex-grow-1  flex-shrink-0 text-primary">
        <Link to={dictionary.link}>{t(dictionary.title)}</Link>
      </div>
      {/* <div style={{ width: '10%' }} className="flex-shrink-0">
        {dictionary.created} -
      </div>
      <div style={{ width: '10%' }} className="flex-shrink-0  ms-4">
        {dictionary.updated} -
      </div> */}
    </div>
  );
}

export default observer(TableRow);
