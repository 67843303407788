import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import EmptyResult from '../../../../components/EmptyResult';
import InputSearch from '../../../../components/InputSearch';
import PaginationSet from '../../../../components/PaginationSet';

import rootStore from '../../../../store';
const { mappStore, filterStore, isFSEQ } = rootStore;

function MappPage() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(['app', 'common', 'dictionary']);

  const { search } = useLocation();
  const [, setSearchParams] = useSearchParams();
  const isTerminalClient = isFSEQ();

  useEffect(() => {
    filterStore.set({}, search);
    mappStore.fetchMapps(filterStore.searchParams, true);
  }, [search]);

  const updateFilter = (key: string, value: string) => {
    if (key === 'search' || key === 'limit' || key === 'carClassId') {
      filterStore.update('page', '1');
    }

    filterStore.update(key, value).then(() => setSearchParams(filterStore.filteredParams));
  };

  const AppRow = ({ app }: { app: Mapp }) => {
    const { name, name_eng, address, address_eng, part_pp, part_pp_eng, schedule } = app;

    const week = [t('Mon'), t('Tue'), t('Wed'), t('Thu'), t('Fri'), t('Sat'), t('Sun')];

    return (
      <div
        className="p-4 border-top"
        style={{ borderColor: '#E1E1E1' }}
        // onClick={() => navigate(`/app-info/${app.id}`)}
      >
        <Row className="mb-0 mb-lg-3">
          {/* <Col xs={12} lg={4} className="mb-4 mb-lg-0 text-end order-lg-3">
            <Button
              text={t('book')}
              disabled={!app.id}
              onClick={() => navigate('/booking-process', { state: { mappId: app.id } })}
            />
          </Col> */}
          <Col xs={12} lg={8}>
            <div className="d-grid gap-3 mapps">
              <h6 className="order-0">{i18n.language === 'en' ? name_eng : name}</h6>
              <span className="order-1 order-lg-2">{i18n.language === 'en' ? address_eng : address}</span>
              <h6 className="order-2 order-lg-1">{t('partPPLabel')}:</h6>
              <span className="order-3">{i18n.language === 'en' ? part_pp_eng : part_pp}</span>
            </div>
          </Col>
        </Row>
        <h6 className="my-4">{t('workSchedule', { ns: 'dictionary' })}:</h6>
        <div className="schedule-date d-flex align-items-start flex-wrap py-4">
          {week.map((day, index) => (
            <div key={index} className="mx-2 my-2" style={{ minWidth: '114px' }}>
              <h6>{day}</h6>
              <div>
                {schedule.schedule_items?.[index + 1]?.weekday_schedule_items?.map((item, i) => (
                  <div key={i}>
                    {item.start} - {item.end}
                  </div>
                )) || <span>Выходной</span>}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Container
      className={`${!isTerminalClient ? 'flex-grow-1 ' : 'm-auto terminal-min-height transform-y-180px '}pb-3 pb-sm-5`}
    >
      <Row>
        <Col lg={isTerminalClient ? 12 : 9}>
          <h2 className="mt-40px mb-4">{t('title')}</h2>

          <div className="bg-white rounded shadow-main">
            <div
              className="d-flex p-3 p-lg-4"
              style={{ borderBottom: `${mappStore.mapps?.length ? 0 : '1px'} solid #E1E1E1` }}
            >
              <InputSearch
                placeholder={t('search', { ns: 'common' }) || ''}
                value={filterStore.params?.search || ''}
                onChange={(newValue: string) => updateFilter('search', newValue)}
                className="w-100"
              />
            </div>

            {mappStore.isLoading && <Loader />}

            {!mappStore.isLoading && (
              <>
                {mappStore.mapps.map((app: Mapp, index: number) => (
                  <AppRow key={index} app={app} />
                ))}
              </>
            )}

            {!mappStore.isLoading && !!mappStore.mapps.length && (
              <PaginationSet
                items={Number(filterStore.params.total)}
                page={Number(filterStore.params.page)}
                pageCount={Number(filterStore.params.limit)}
                onPageCountChange={(page) => updateFilter('limit', String(page))}
                onPageChange={(page) => updateFilter('page', String(page))}
                className="py-4 px-28px"
              />
            )}

            {!mappStore.isLoading && !mappStore.mapps.length && (
              <EmptyResult text="Автомобильных пунктов пропуска не найдено" />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default observer(MappPage);
