import { Form } from 'react-bootstrap';

function Checkbox() {
  return (
    <Form>
      <div key={`default-checkbox`} className="mb-3">
        <Form.Check type={'checkbox'} id={`default-checkbox`} label={`default checkbox`} />

        <Form.Check disabled type={'checkbox'} label={`disabled checkbox`} id={`disabled-default-checkbox`} />
      </div>
      <div key={`default-radio`} className="mb-3">
        <Form.Check type={'radio'} id={`default-radio`} label={`default radio`} />

        <Form.Check disabled type={'radio'} label={`disabled radio`} id={`disabled-default-radio`} />
      </div>
    </Form>
  );
}

export default Checkbox;
