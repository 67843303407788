import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from '../../../../../components/Icon';
import { ADMIN_ROLE, TransportClassId } from '../../../../../static';
import Button from '../../../../../components/Button';
import store from '../../../../../store';
const { authStore, isFSEQ } = store;

type Props = {
  isActive: boolean;
  cars: Booking['cars'] | undefined;
  onCancelBooking: () => void;
  onRescheduling: () => void;
  onDownload: (fileUuid: string) => void;
  onChangeStatus: () => void;
  status?: string;
};

function LeftPanel({ isActive, cars, status, onCancelBooking, onRescheduling, onDownload, onChangeStatus }: Props) {
  const { t } = useTranslation(['booking', 'upload', 'transport', 'common']);
  const isTerminalClient = isFSEQ();
  const isAdmin = authStore.role === ADMIN_ROLE;

  return (
    <div>
      <div className="d-grid shadow-main rounded-3 bg-white mt-3 mt-lg-0 mb-4">
        <div className="p-4">
          <div className="text-caption mb-3">{t('documents', { ns: 'booking' })}</div>
          <div className="text-caption mb-3">{t('sts', { ns: 'booking' })}:</div>
          {cars && (
            <div>
              {cars[TransportClassId.TRUCK] && <h6>{t('truck', { ns: 'transport' })}</h6>}
              {cars[TransportClassId.TRAILER] && <h6>{t('trailer', { ns: 'transport' })}</h6>}
              <div className="seq-upload-area rounded p-2">
                {[cars[TransportClassId.TRUCK], cars[TransportClassId.TRAILER]]
                  .filter((v) => !!v)
                  .map((v, index) => (
                    <div className="seq-upload-area__uploaded" key={index}>
                      <div className="d-flex align-items-center">
                        {/* <Icon name="badge" width={48} height={24} className={`d-sm-block me-2 extension-${fileType}`} /> */}
                        <Icon name="file" width={48} height={24} />
                        {v.file_name || t('default_filename', { ns: 'upload' })}
                      </div>
                      <div className="d-flex gap-4">
                        <button type="button" className="btn btn-link" onClick={() => onDownload(v.file_uuid)}>
                          {t('view', { ns: 'common' })}
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
        {isActive && !isAdmin && isTerminalClient && (
          <div>
            <hr className="m-0" />
            <div className="p-4 d-flex flex-column align-items-start">
              <h6 className="mb-3">{t('booking_management')}</h6>
              {/* <Button
                className="mb-3"
                variant="outline"
                onClick={() => onRescheduling()}
                text={t('rescheduling')}
              ></Button> */}

              <Button
                className="mb-3"
                variant="outline"
                onClick={() => onCancelBooking()}
                text={t('cancel_booking')}
              ></Button>
            </div>
          </div>
        )}
        {isActive && !isAdmin && !isTerminalClient && (
          <div>
            <hr className="m-0" />
            <div className="p-4 d-flex flex-column align-items-start">
              <h6 className="mb-3">{t('booking_management')}</h6>
              {/* <Link to="#" className="mb-3" onClick={() => onRescheduling()}>
                {t('rescheduling')}
              </Link> */}

              <Link to="#" onClick={() => onCancelBooking()}>
                {t('cancel_booking')}
              </Link>
            </div>
          </div>
        )}
        {isAdmin && (
          <div>
            <hr className="m-0" />
            <div className="p-4 d-flex flex-column align-items-start">
              <h6 className="mb-3">{t('booking_management')}</h6>
              <Link to="#" className="mt-3" onClick={() => onChangeStatus()}>
                {t('change_status')}
              </Link>
            </div>
          </div>
        )}
      </div>
      {!isAdmin && (
        <div
          className="rounded py-28px px-4 mb-4 text-caption"
          style={{ border: '1px dashed rgba(29, 93, 235, 0.18)' }}
        >
          {t('rescheduling_hint', { ns: 'booking' })}
        </div>
      )}
    </div>
  );
}

export default LeftPanel;
