import { useCallback, useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Form, Col, Container, Row, OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';

import InputText from '../../../../../components/InputText';
import Button from '../../../../../components/Button';
import Select from '../../../../../components/Select';
import Modal from '../../../../../components/Modal';
import Icon from '../../../../../components/Icon';
import ScheduleDate from './components/ScheduleDate';
import UploadFile from '../../../../../components/UploadFile';

import { downloadFile, uploadFile } from '../../../../../api';
import Utils from './../../../../../utils';
import store from './store';
import rootStore from '../../../../../store';
const { mappStore, isFSEQ } = rootStore;

type RouterParams = {
  id: string;
};

function DictionaryCategorySingle() {
  const { id } = useParams<RouterParams>();
  const { t } = useTranslation(['dictionary', 'common']);
  const navigate = useNavigate();
  const [timezoneOptions, setTimezoneOptions] = useState<SelectOption[]>([]);
  const [timezoneOption, setTimezoneOption] = useState<SelectOption | null>();
  const [schemeDocument, setSchemeDocument] = useState<File | null>(null);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [onDeleteSuccess, setOnDeleteSuccess] = useState<(() => void) | null>(null);
  const isTerminalClient = isFSEQ();

  const fetchTimezones = useCallback(async () => {
    const timezones = await mappStore.fetchTimezones();
    const timezoneOptions: SelectOption[] =
      timezones?.map((timezone: TimezoneType) => ({
        value: timezone.id,
        label: timezone.name,
      })) || [];
    setTimezoneOptions(timezoneOptions);
  }, []);

  useEffect(() => {
    fetchTimezones();
  }, [fetchTimezones]);

  useEffect(() => {
    if (!id) return;
    const initForm = async () => {
      await mappStore.fetchMappById(id);
      mappStore.mapp && store.initFields(mappStore.mapp);
      if (store.fields.scheme_uuid && store.fields.scheme_uuid !== '00000000-0000-0000-0000-000000000000') {
        const document = await downloadFile(store.fields.scheme_uuid);
        setSchemeDocument(document);
      }
    };
    initForm();
    return () => store.clearFields();
  }, [id]);

  // useEffect(() => {
  //   if (!timezoneOptions.length) return;
  //   const timezoneOption = timezoneOptions.find((option) => option.value === store.fields.time_zone);
  //   if (timezoneOption) {
  //     handleChangeTimezone(timezoneOption);
  //   }
  // }, [timezoneOptions]);

  const onSaveAppHandler = async () => {
    const { id, ...fields } = store.fields;
    let response;

    if (id) {
      response = await mappStore.updateMapp(id, fields);
    } else {
      response = await mappStore.createMapp(fields);
    }

    if (response) {
      navigate('/dictionary/app');
    }
  };

  const handleChangeTimezone = (option: SingleValue<SelectOption>) => {
    if (option) {
      setTimezoneOption(option);
      store.changeField('time_zone', Number(option.value));
    }
  };

  const handleChangedFile = async (file: File, onSuccess: () => void, OnFailure: (error: any) => void) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const result = await uploadFile(formData);
      store.changeField('scheme_uuid', result.file_uuid);
      onSuccess();
    } catch (error) {
      OnFailure(error);
    }
  };

  const handleConfirmDelete = (onSuccess: () => void) => {
    setIsConfirmModalVisible(true);
    setOnDeleteSuccess(() => onSuccess);
  };

  const handleDeleteSuccess = () => {
    if (onDeleteSuccess) {
      onDeleteSuccess();
      setOnDeleteSuccess(null);
      store.changeField('scheme_uuid', '');
    }
  };

  const handleDownloadFile = async () => {
    const result = await downloadFile(store.fields.scheme_uuid);
    Utils.downloadFile(result);
  };

  const isValid = (): boolean => {
    const { id, ...fields } = store.fields;
    const validSchedule = Object.entries(fields.schedule.schedule_items).some(
      ([_, value]) => value && value.weekday_schedule_items.length > 0
    );
    return Object.entries(fields).every(([_, value]) => value !== '') && validSchedule;
  };

  const workHoursHint = (
    <Popover className="seq-popover">
      <Popover.Body>
        <p className="pt-2 mb-0">{t('workHoursHint')}</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <Container
      className={`${!isTerminalClient ? 'flex-grow-1 ' : 'm-auto terminal-min-height transform-y-180px '}pb-3 pb-sm-5`}
    >
      <Row>
        <Col lg={12}>
          <Link to="/dictionary/app" className="link-back my-4">
            {t('back', { ns: 'common' })}
          </Link>
          <h2 className="mt-40px mb-4">{t(id ? 'editingApp' : 'addingApp')}</h2>

          <div className="p-4 shadow-main rounded">
            <Row>
              <Col md={7}>
                <Row>
                  <Col md={12} className="pb-4">
                    <InputText
                      label={`${t('name')} (RU)`}
                      placeholder={`${t('name')}`}
                      value={store.fields.name}
                      onChange={(e: { target: { value: string } }) => store.changeField('name', e.target.value)}
                      clear={() => store.changeField('name', '')}
                      disabled={mappStore.isLoading}
                    />
                  </Col>
                  <Col md={12} className="pb-4">
                    <InputText
                      label={`${t('name')} (EN)`}
                      placeholder={`${t('name')}`}
                      value={store.fields.name_eng}
                      onChange={(e: { target: { value: string } }) => store.changeField('name_eng', e.target.value)}
                      clear={() => store.changeField('name_eng', '')}
                      disabled={mappStore.isLoading}
                    />
                  </Col>
                  <Col md={12} className="pb-4">
                    <InputText
                      label={`${t('adjacentCp')} (RU)`}
                      placeholder={`${t('adjacentCp')}`}
                      value={store.fields.part_pp}
                      onChange={(e: { target: { value: string } }) => store.changeField('part_pp', e.target.value)}
                      clear={() => store.changeField('part_pp', '')}
                      disabled={mappStore.isLoading}
                    />
                  </Col>
                  <Col md={12} className="pb-4">
                    <InputText
                      label={`${t('adjacentCp')} (EN)`}
                      placeholder={`${t('adjacentCp')}`}
                      value={store.fields.part_pp_eng}
                      onChange={(e: { target: { value: string } }) => store.changeField('part_pp_eng', e.target.value)}
                      clear={() => store.changeField('part_pp_eng', '')}
                      disabled={mappStore.isLoading}
                    />
                  </Col>

                  <Col md={12} className="pb-4">
                    <Form.Label htmlFor={id}>{`${t('checkpointAddress')} (RU)`}</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder={`${t('checkpointAddress')}`}
                      rows={6}
                      value={store.fields.address}
                      onChange={(e) => store.changeField('address', e.target.value)}
                      disabled={mappStore.isLoading}
                    />
                  </Col>

                  <Col md={12} className="pb-4">
                    <Form.Label htmlFor={id}>{`${t('checkpointAddress')} (EN)`}</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder={`${t('checkpointAddress')}`}
                      rows={6}
                      value={store.fields.address_eng}
                      onChange={(e) => store.changeField('address_eng', e.target.value)}
                      disabled={mappStore.isLoading}
                    />
                  </Col>

                  <Col md={12} className="pb-4">
                    <Form.Label htmlFor={id}>{`${t('driveSiteAddress')} (RU)`}</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder={`${t('driveSiteAddress')}`}
                      rows={10}
                      value={store.fields.platform_address}
                      onChange={(e) => store.changeField('platform_address', e.target.value)}
                      disabled={mappStore.isLoading}
                    />
                  </Col>

                  <Col md={12} className="pb-4">
                    <Form.Label htmlFor={id}>{`${t('driveSiteAddress')} (EN)`}</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder={`${t('driveSiteAddress')}`}
                      rows={10}
                      value={store.fields.platform_address_eng}
                      onChange={(e) => store.changeField('platform_address_eng', e.target.value)}
                      disabled={mappStore.isLoading}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={5}>
                <Row>
                  <Col md={12} className="pb-4">
                    <Select
                      label={`${t('timezone')}`}
                      placeholder={`${t('timezone')}`}
                      options={timezoneOptions}
                      value={
                        timezoneOption ||
                        timezoneOptions.find((option) => Number(option.value) === store.fields.time_zone)
                      }
                      onChange={(option) => handleChangeTimezone(option)}
                      isDisabled={mappStore.isLoading}
                    />
                  </Col>
                  <Col md={12} className="pb-4">
                    <InputText
                      label={t('siteCapacity')}
                      value={store.fields.parking_capacity}
                      onChange={(e: { target: { value: string } }) =>
                        store.changeField('parking_capacity', Number(e.target.value))
                      }
                      clear={() => store.changeField('parking_capacity', '')}
                      disabled={mappStore.isLoading}
                    />
                  </Col>
                  <Col md={12} className="pb-4">
                    <InputText
                      label={t('vehicleCapacity')}
                      value={store.fields.mapp_capacity}
                      onChange={(e: { target: { value: string } }) =>
                        store.changeField('mapp_capacity', Number(e.target.value))
                      }
                      clear={() => store.changeField('mapp_capacity', '')}
                      disabled={mappStore.isLoading}
                    />
                  </Col>
                  <Col md={12} className="pb-4">
                    <InputText
                      label={t('countLanesApp')}
                      value={store.fields.lanes}
                      onChange={(e: { target: { value: string } }) =>
                        store.changeField('lanes', Number(e.target.value))
                      }
                      clear={() => store.changeField('lanes', '')}
                      disabled={mappStore.isLoading}
                    />
                  </Col>
                  <Col md={12} className="pb-4">
                    <InputText
                      label={t('maxOccupancySite')}
                      value={store.fields.max_occupancy}
                      onChange={(e: { target: { value: string } }) =>
                        store.changeField('max_occupancy', Number(e.target.value))
                      }
                      clear={() => store.changeField('max_occupancy', '')}
                      disabled={mappStore.isLoading}
                    />
                  </Col>
                  <Col md={12} className="pb-4">
                    <InputText
                      label={t('booking_days')}
                      value={store.fields.booking_days}
                      onChange={(e: { target: { value: string } }) =>
                        store.changeField('booking_days', Number(e.target.value))
                      }
                      clear={() => store.changeField('booking_days', '')}
                      disabled={mappStore.isLoading}
                    />
                  </Col>
                  <Col md={12} className="pb-4">
                    <Form.Label>{t('schemeLabel')}</Form.Label>
                    <UploadFile
                      allowedFileTypes={['pdf', 'jpg', 'png', 'jpeg']}
                      filename="Документ"
                      value={schemeDocument}
                      documentButtonName={t('download', { ns: 'common' })}
                      onChange={handleChangedFile}
                      onDelete={handleConfirmDelete}
                      onDownload={handleDownloadFile}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="pb-4">
              <Col md={12}>
                <div className="my-4 pb-2 fw-bold d-flex align-items-center gap-1">
                  {t('workSchedule')}
                  <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={workHoursHint}>
                    <div className="d-inline-block">
                      <Icon name="question-mark" width={24} height={24} className="cursor-pointer" />
                    </div>
                  </OverlayTrigger>
                </div>
                <ScheduleDate schedule={store.fields.schedule} />
              </Col>
            </Row>
            <div>
              <Button
                type="submit"
                variant="primary"
                text={t('save', { ns: 'common' }) || ''}
                className="me-4"
                disabled={!isValid()}
                isLoading={mappStore.isLoading}
                onClick={onSaveAppHandler}
              />
              <Button
                variant="outline"
                text={t('cancel', { ns: 'common' }) || ''}
                className="me-4"
                onClick={() => navigate('/dictionary/app')}
                disabled={mappStore.isLoading}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Modal
        show={isConfirmModalVisible}
        onHide={() => setIsConfirmModalVisible(false)}
        header={t('delete_scheme_file_title', { ns: 'dictionary' }) || ''}
        body={<>{t('delete_scheme_file_body', { ns: 'dictionary' }) || ''}</>}
        footer={
          <div className="d-flex flex-column flex-sm-row gap-3 gap-lg-4 w-100">
            <Button type="button" text={t('confirm', { ns: 'common' })} onClick={handleDeleteSuccess} />
            <Button
              type="button"
              variant="outline"
              onClick={() => setIsConfirmModalVisible(false)}
              text={t('cancel', { ns: 'common' })}
            />
          </div>
        }
      />
    </Container>
  );
}

export default observer(DictionaryCategorySingle);
