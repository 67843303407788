import { Row, Col } from 'react-bootstrap';
import { BookingHistory } from '../../../api';
import Utils from './../../../../../utils';
import Icon from '../../../../../components/Icon';

import store from '../../../../../store';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
const { bookingStore } = store;

function History({ history }: { history: BookingHistory[] | undefined }) {
  const [showHistory, setShowHistory] = useState(false);
  const { t, i18n } = useTranslation(['booking']);
  const bookingStatuses = bookingStore.getBookingStatuses(i18n.language);

  return (
    <>
      {showHistory && (
        <>
          <hr className="m-0" />
          {history?.map((item, index) => (
            <div key={index}>
              {index !== 0 && <hr className="m-0" />}
              <Row className="p-4">
                <Col xs={6}>
                  <div className="d-flex gap-2">
                    <Icon name={item?.status || ''}></Icon>
                    <span>{bookingStatuses?.find((v) => v.value === item.status)?.label}</span>
                  </div>
                </Col>
                <Col xs={6} className="text-end text-caption">
                  {Utils.getDate(item.created_at, 'dd.MM.yyyy')} в {Utils.getDate(item.created_at, 'HH:mm')}
                </Col>
              </Row>
            </div>
          ))}
        </>
      )}
      <hr className="m-0" />
      <div className="p-4">
        <Link to="#" onClick={() => setShowHistory((state) => !state)}>
          {showHistory ? t('hide_history') : t('show_history')}
        </Link>
      </div>
    </>
  );
}

export default History;
