import { useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Form, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import EmptyResult from '../../../../../components/EmptyResult';
import TableHead from './components/TableHead';
import TableRow from './components/TableRow';
import { dictionaryCategories } from '../../../../../static';
import rootStore from '../../../../../store';
const { isFSEQ } = rootStore;

function DictionaryCategoryList() {
  const { t } = useTranslation(['dictionary', 'common']);
  const isTerminalClient = isFSEQ();

  const [searchQuery, setSearchQuery] = useState('');

  const filteredCategories = dictionaryCategories.filter((category: DictionaryCategory) =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const hasCategory = !!filteredCategories?.length;

  return (
    <Container
      className={`${!isTerminalClient ? 'flex-grow-1 ' : 'm-auto terminal-min-height transform-y-180px '}pb-3 pb-sm-5`}
    >
      <Row>
        <Col lg={isTerminalClient ? 12 : 9}>
          <Link to="/dictionary" className="link-back my-4">
            {t('back', { ns: 'common' })}
          </Link>
          <h2 className="mt-40px mb-4">{t('vehicleCategories')}</h2>

          <div className="bg-white rounded shadow-main" style={{ marginBottom: '4rem' }}>
            <div className="d-flex p-4" style={{ borderBottom: '1px solid #E1E1E1' }}>
              <Form.Control
                type="search"
                placeholder={t('search', { ns: 'common' }) || ''}
                className="flex-grow-1"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            {hasCategory && (
              <div className="d-flex flex-column gap-4 p-4">
                <TableHead />
                {filteredCategories.map((category, index) => (
                  <div key={index}>
                    <TableRow category={category} />
                  </div>
                ))}
              </div>
            )}

            {!hasCategory && <EmptyResult text={t('noCategoriesByRequest')} />}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default observer(DictionaryCategoryList);
