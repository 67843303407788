import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '../../../../components/Button';
import InputText from '../../../../components/InputText';
import Spinner from '../../../../components/Spinner';

import store from '../../../../store';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const { profileStore } = store;

function OrgName() {
  const { t } = useTranslation(['profile', 'common']);
  const [newOrgName, setNewOrgName] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const isFSEQ = store.isFSEQ();

  useEffect(() => {
    if (isEdit) setNewOrgName('');
  }, [isEdit]);

  const onUpdateHandler = async () => {
    const isSuccessResponse = await profileStore.updateProfile({ clientName: newOrgName });

    if (isSuccessResponse) {
      profileStore.fetchProfile();
      toast.info(t('data_changed', { ns: 'common' }));
      setNewOrgName('');
      setIsEdit(false);
    }
  };

  const Toggle = observer(() => (
    <div>
      <p className="mb-1 text-caption fs-7">{t('orgName')}</p>
      <div className="d-flex">
        {
          <h5 className="my-auto me-4">
            {profileStore.isLoading && <Spinner size={24} className="m-auto text-primary" />}
            {!profileStore.isLoading && profileStore.profile.client_name}
          </h5>
        }
        <Link to="#" className={`${isFSEQ ? 'btn btn-primary' : 'link-primary'} fs-6`} onClick={() => setIsEdit(true)}>
          {t('change')}
        </Link>
      </div>
    </div>
  ));

  if (!isEdit) return <Toggle />;

  return (
    <div className="py-32px px-4 rounded-1 d-grid gap-32px" style={{ border: '1px dashed rgba(29, 93, 235, 0.18)' }}>
      <h5 className="m-0">
        {t('orgName_change')} {profileStore.profile.client_name}
      </h5>

      <div className="d-flex align-items-center">
        <InputText
          label={t('new_orgName')}
          placeholder={t('enter_orgName') || ''}
          className="w-50 me-32px"
          value={newOrgName}
          onChange={(e: any) => setNewOrgName(e.target.value)}
          disabled={profileStore.isLoading}
        />
      </div>
      <div>
        <Button
          variant="primary"
          text={t('save', { ns: 'common' })}
          className="me-4"
          onClick={onUpdateHandler}
          isLoading={profileStore.isLoading}
          disabled={!newOrgName}
        />
        <Button variant="outline" text={t('cancel', { ns: 'common' })} onClick={() => setIsEdit(false)} />
      </div>
    </div>
  );
}

export default observer(OrgName);
