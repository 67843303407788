import client from '../../httpClient/clientV1';
import clientScanner from '../../httpClient/clientScanner';

export type FetchTransportResponse = Pagination & {
  data: Transport[];
};

export type TransportItemResponse = {
  id: string;
  car_type_id: number;
  document_uuid: string;
  grnz: string;
  grnzNormalized: string;
  model: string;
  name: string;
};

async function fetchTransport(searchParams?: string) {
  const params = searchParams === undefined ? '' : `?${searchParams}`;

  const response = await client.get<FetchTransportResponse>(`/cars${params}`);

  return response.data;
}

export async function fetchTransportById(id: string) {
  const result = await client.get<TransportItemResponse>(`/cars/${id}`);
  return result.data;
}

async function deleteTransport(id: string) {
  const response = await client.delete(`/cars/${id}`);
  return response.data;
}

export async function createTransport(params: any) {
  const result = await client.post<any>('/cars', params);
  return result.data;
}

export async function updateTransport(id: any, params: any) {
  const result = await client.put<any>(`/cars/${id}`, params);
  return result.data;
}
export async function changeStatus(id: string, status_id: number) {
  const result = await client.patch<any>('/cars/status', { id, status_id });
  return result;
}

export async function fetchTransportClasses() {
  const result = await client.get('/car_classes');
  return result.data;
}

export async function fetchTransportTypes() {
  const result = await client.get<TransportType[]>('/car_types');
  return result.data;
}

export async function scanDocument() {
  const result = await clientScanner.get('', {
    responseType: 'blob',
  });
  return result.data;
}

export default Object.assign({
  fetchTransport,
  fetchTransportById,
  deleteTransport,
  createTransport,
  updateTransport,
  changeStatus,
  fetchTransportClasses,
  fetchTransportTypes,
  scanDocument,
});
