import client from '../httpClient/clientV1';

export async function uploadFile(formData: FormData) {
  const result = await client.post<{ file_uuid: string }>('/docstore', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return result.data;
}

export async function downloadFile(documentUuid: string) {
  const result = await client.get(`/docstore/${documentUuid}`, { responseType: 'blob' });
  return result.data;
}

export default Object.assign({
  uploadFile,
});
