import client from '../../httpClient/clientV1';
// import { Booking } from '../Booking/api';

export type BookingSlotsRequest = {
  id?: string;
  grnz?: string;
  mappId: string;
  passengerCar?: Record<string, never>;
  cargo?: { laden: boolean };
  bus?: {
    flightType: 'FlightType_REGULAR' | 'FlightType_IRREGULAR';
    countPass: number;
  };
  bike?: Record<string, never>;
};

export type BookingPostRequestBody = {
  driver: {
    email: string;
    phone: string;
  };
  car: BookingSlotsRequest;
  time_slot: string;
  mapp_id: string;
  lang: string;
  is_kiosk: boolean;
};

export type BookingResponse = {
  id: string;
  qrCode: string;
  number: string;
};

export type BookingSlotsResponse = {
  data: SlotType[];
};

export type BookingMove = {
  bookingId: string;
  time: string | undefined;
  bph: number | undefined;
  captcha_id: string;
  captcha_input: string;
};

export type Captcha = {
  captcha: string;
  captcha_id: string;
};

async function fetchSlots(body: BookingSlotsRequest) {
  const response = await client.post<BookingSlotsResponse[]>('/booking_slots', body);
  return response.data;
}

async function getCaptcha() {
  const response = await client.get<Captcha>('/captcha');
  return response.data;
}

async function createBooking(body: BookingPostRequestBody) {
  const response = await client.post<Booking>('/bookings', body);
  return response?.data;
}

async function moveBooking(body: BookingMove) {
  const response = await client.post<Booking>('/bookings_move', body);
  return response?.data;
}

export default Object.assign({
  fetchSlots,
  createBooking,
  moveBooking,
  getCaptcha,
});
