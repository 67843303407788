import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';

import { LANGUAGES } from '../../static';
import Button from '../Button';

type Props = {
  isTerminalClient?: boolean;
};

function LanguageSwitcher({ isTerminalClient }: Props) {
  const { i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('lang', language);
  };

  const LanguageRender = () => {
    if (isTerminalClient) {
      return (
        <>
          {LANGUAGES.filter((language) => localStorage.getItem('lang') !== language.value).map((language, index) => (
            <Button
              key={index}
              className="terminal-button-sm"
              text={language.label}
              variant="outline"
              onClick={() => changeLanguage(language.value)}
            />
          ))}
        </>
      );
    }

    return (
      <Dropdown align="end">
        <Dropdown.Toggle className="bg-transparent text-primary border-0 d-flex p-0">
          <Icon name="language" />
          <Icon name="small_down" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {LANGUAGES.map((language, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => changeLanguage(language.value)}
              // active={localStorage.getItem('lang') === language.value}
            >
              {language.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return <LanguageRender />;
}

export default LanguageSwitcher;
