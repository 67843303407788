import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';

type Props = {
  header: string;
  body: string;
  show: boolean;
  onClose: () => void;
};

function WarningModal({ header, body, show, onClose }: Props) {
  const { t } = useTranslation(['common']);

  return (
    <Modal
      show={show}
      onHide={() => onClose()}
      header={header || ''}
      body={<>{body}</>}
      footer={
        <div className="d-flex flex-column flex-sm-row gap-3 gap-lg-4 w-100">
          <Button variant="outline" onClick={() => onClose()} text={t('ok', { ns: 'common' })} />
        </div>
      }
    />
  );
}

export default WarningModal;
