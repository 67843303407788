import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';

import store from '../../../../../store';
const { bookingStore } = store;

type Props = {
  title: string;
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
  booking: Booking | null;
};

function CancellationConfirmModal({ title, show, onClose, onSuccess, booking }: Props) {
  const { t } = useTranslation(['booking', 'common']);

  if (booking === null) return null;

  const cancelBookingHandler = async (booking: Booking) => {
    const isSuccessDelete = await bookingStore.cancelBooking(booking);

    if (isSuccessDelete) {
      onClose();
      onSuccess();
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => onClose()}
      header={t('cancellation_of_booking') || ''}
      body={<>{title}</>}
      footer={
        <div className="d-flex flex-column flex-sm-row gap-3 gap-lg-4 w-100">
          <Button text={t('confirm', { ns: 'common' })} onClick={() => cancelBookingHandler(booking)} />
          <Button variant="outline" onClick={() => onClose()} text={t('cancel', { ns: 'common' })} />
        </div>
      }
    />
  );
}

export default CancellationConfirmModal;
