import { makeAutoObservable, action } from 'mobx';
import { RootStore } from '../../store';
// import Api, { Transport } from './api';
import Api, { FetchTransportResponse } from './api';
import { TransportClassId } from '../../static';
import { toast } from 'react-toastify';

export enum TransportValidationStatus {
  'review' = 1,
  'verified',
  'rejected',
}

export class TransportStore {
  rootStore: RootStore;
  transportList: Transport[] = [];
  transportTotalByCarType: { [carType: string]: number } = {};
  isLoading = true;
  scanLoading = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setScanLoading(value: boolean) {
    this.scanLoading = value;
  }

  async fetchTransport(searchParams?: string, carType?: string) {
    this.setLoading(true);

    await Api.fetchTransport(searchParams)
      .then(
        action('fetchSuccess', (response: FetchTransportResponse) => {
          this.setTransport(response.data || []);
          if (carType) {
            this.setTotal(carType, response.total);
          }
          this.rootStore.filterStore.update('total', String(response.total));
        }),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setLoading(false));
  }

  setTransport(transportList: Transport[]) {
    this.transportList = transportList;
  }

  setTotal(carType: string, total: number) {
    this.transportTotalByCarType[carType] = total;
  }

  async deleteTransportById(transportId: string) {
    this.setLoading(true);

    return await Api.deleteTransport(transportId)
      .then(
        action('fetchSuccess', (response: { data: Transport }) => response.data),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setLoading(false));
  }

  async fetchTransportById(id: string) {
    this.setLoading(true);

    return await Api.fetchTransportById(id)
      .then(
        action('fetchSuccess', (response: any) => response.data),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setLoading(false));
  }

  async createTransport(transport: any) {
    this.setLoading(true);

    return await Api.createTransport(transport)
      .then(
        action('fetchSuccess', (response: { data: Transport[] }) => response.data),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setLoading(false));
  }

  async updateTransport(id: string, transport: any) {
    this.setLoading(true);

    return await Api.updateTransport(id, transport)
      .then(
        action('fetchSuccess', (response: { data: Transport[] }) => response.data),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setLoading(false));
  }

  async changeStatus(id: string, status_id: number) {
    this.setLoading(true);

    return await Api.changeStatus(id, status_id)
      .then(
        action('fetchSuccess', (response: any) => response),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setLoading(false));
  }

  async fetchTransportTypes() {
    this.setLoading(true);

    return await Api.fetchTransportTypes()
      .then(
        action('fetchSuccess', (response: any) => response.data),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setLoading(false));
  }

  async scanDocument() {
    this.setScanLoading(true);

    return await Api.scanDocument()
      .then(
        action('fetchSuccess', (response: any) => response),
        action('fetchError', (error: unknown) => toast.error(error))
      )
      .finally(() => this.setScanLoading(false));
  }

  getTransportValidationStatusCode(status?: string | number): string {
    return status ? TransportValidationStatus[+status] : '';
  }

  get transportOptions(): SelectOptionExtended[] {
    return this.rootStore.transportStore.transportList.map((transport) => ({
      label:
        String(transport.car_type_id) === TransportClassId.TRUCK
          ? `${transport.name || ''} ${transport.model || ''} ${transport.grnz || ''}`
          : `${transport.grnz || ''}`,
      value: transport.grnz,
      ...transport,
    }));
  }
}
