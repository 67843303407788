import { SetStateAction, useState } from 'react';
import InputText from '../components/InputText';
import InputPassword from '../components/InputPassword';

function Inputs() {
  const [value, setValue] = useState('');

  return (
    <>
      <section>
        <h4 className="my-3">Текстовые поля</h4>
        <form>
          <InputText
            placeholder="Введите текст"
            value={value}
            onChange={(e: { target: { value: SetStateAction<string> } }) => setValue(e.target.value)}
            clear={() => setValue('')}
            className="mb-3"
          />
          <br />
          <InputText
            label="Заголовок поля"
            placeholder="Введите текст"
            value={value}
            onChange={(e: { target: { value: SetStateAction<string> } }) => setValue(e.target.value)}
            clear={() => setValue('')}
            className="mb-3"
          />
          <br />
          <InputText
            label="Заголовок поля"
            placeholder="Введите текст"
            value={value}
            onChange={(e: { target: { value: SetStateAction<string> } }) => setValue(e.target.value)}
            clear={() => setValue('')}
            className="mb-3"
            infoMsg={<div className="text-secondary fs-7">Инфо-поле</div>}
          />
          <br />
          <InputText
            isInvalid
            label="Заголовок поля"
            placeholder="Введите текст"
            value={value}
            onChange={(e: { target: { value: SetStateAction<string> } }) => setValue(e.target.value)}
            clear={() => setValue('')}
            className="mb-3"
            errorMsg="Ошибка"
            infoMsg={<div className="text-secondary fs-7">Инфо-поле</div>}
          />
          <InputText
            disabled
            label="Заголовок поля"
            placeholder="Введите текст"
            value={value}
            onChange={(e: { target: { value: SetStateAction<string> } }) => setValue(e.target.value)}
            clear={() => setValue('')}
            className="mb-3"
          />
        </form>
      </section>

      <hr className="my-3" />
      <section>
        <h4 className="mb-3">Поле с паролем</h4>
        <form>
          <InputPassword
            placeholder="Введите пароль"
            value={value}
            onChange={(e: { target: { value: SetStateAction<string> } }) => setValue(e.target.value)}
            className="mb-3"
          />
          <InputPassword
            placeholder="Введите пароль"
            label="Заголовок поля"
            value={value}
            onChange={(e: { target: { value: SetStateAction<string> } }) => setValue(e.target.value)}
            className="mb-3"
            infoMsg={<div className="text-secondary fs-7">Инфо-поле</div>}
          />
        </form>
      </section>
    </>
  );
}

export default Inputs;
