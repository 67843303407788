import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import EmptyResult from '../../../../components/EmptyResult';
import DropdownList from './../../../../components/DropdownList';
import Loader from '../../../../components/Loader';
import NotificationCount from '../../../../components/NotificationCount';

import { Notification } from './../../api';

import Utils from './../../../../utils';
import store from './../../../../store';
import { ADMIN_ROLE, NOTIFICATION_CATEGORIES } from '../../../../static';
import PaginationSet from '../../../../components/PaginationSet';
import InputSearch from '../../../../components/InputSearch';
const { notificationStore, isFSEQ, authStore, filterStore } = store;

const initialParams: FilterParams = {
  typeId: 'all',
};

function NotificationList() {
  const { t, i18n } = useTranslation('notification');
  const { search } = useLocation();
  const [, setSearchParams] = useSearchParams();

  const isTerminalClient = isFSEQ();
  const isAdmin = authStore.role === ADMIN_ROLE;

  const options = NOTIFICATION_CATEGORIES;

  useEffect(() => {
    filterStore.set(initialParams, search);
    fetchNotifications(i18n.language, filterStore.searchParams);

    return () => {
      notificationStore.setNotifications();
    };
  }, [i18n, i18n.language, search]);

  const fetchNotifications = (language: string, searchParams: string) => {
    notificationStore.setIsLoading(true);

    notificationStore
      .fetchNotifications({ language, searchParams })
      .finally(() => notificationStore.setIsLoading(false));

    notificationStore.fetchUnreadNotificationCount();
  };

  const onReadAllHandler = async () => {
    if (!notificationStore.unreadNotificationCount) return;
    if (notificationStore.notifications === undefined) return;

    notificationStore.setIsLoading(true);
    const ids = notificationStore.notifications.map((notification) => notification.id);
    const isSuccessRead = await notificationStore.readNotifications(ids);

    if (isSuccessRead) {
      fetchNotifications(i18n.language, filterStore.searchParams);
      notificationStore.fetchUnreadNotificationCount(); // ???
      toast.info(t('allRead'));
    } else {
      notificationStore.setIsLoading(false);
    }
  };

  const updateFilter = (key: string, value: string | (Date | null)[]) => {
    if (key === 'clientName' || key === 'limit' || Object.keys(initialParams).includes(key)) {
      filterStore.update('page', '1');
    }
    if (!Array.isArray(value)) {
      filterStore.update(key, value).then(() => setSearchParams(filterStore.filteredParams));
    }
  };

  const onChangeOption = (option: SelectOption) => {
    updateFilter('typeId', String(option.value));
  };

  const TableRow = observer(({ notification }: { notification: Notification }) => (
    <Link to={`/notification/${notification.id}`}>
      <Row className="align-items-center">
        {/* <Col xs={1} className="d-flex align-items-center">
          <span className="text-primary">
            <Icon name={Utils.getNotificationIconById(notification.section)} />
          </span>
        </Col> */}
        <Col xs={5} lg={2}>
          <div className="d-flex flex-column me-5 gap-1">
            <span style={{ color: '#0B1F33' }}>{t(Utils.getNotificationLabelById(notification.typeId))}</span>
            <span className="text-secondary text-nowrap">
              {Utils.getDate(notification.createdAt, 'dd.MM.yy', true)} в{' '}
              {Utils.getDate(notification.createdAt, 'HH:mm', true)}
            </span>
          </div>
        </Col>
        {isAdmin && (
          <Col xs={5} lg={2}>
            <div className="d-flex flex-column gap-1">
              <OverlayTrigger overlay={<Tooltip>{notification.clientName}</Tooltip>}>
                <span className="text-secondary text-nowrap text-truncate">{notification.clientName}</span>
              </OverlayTrigger>
            </div>
          </Col>
        )}
        <Col xs={12} lg={isTerminalClient ? 12 : isAdmin ? 8 : 10} className="me-0 flex-grow-1">
          <div className="d-flex flex-column gap-1 overflow-hidden mt-3 mt-lg-0">
            <span
              className={classNames('text-black', {
                'fw-bold': !notification.isRead,
              })}
            >
              {notification.header}
            </span>
            <div className="text-secondary overflow-hidden" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {notification.body}
            </div>
          </div>
        </Col>
      </Row>
    </Link>
  ));

  return (
    <Container
      className={`${!isTerminalClient ? 'flex-grow-1 ' : 'm-auto terminal-min-height transform-y-180px '}pb-3 pb-sm-5`}
    >
      <Row>
        <Col xs={12} xl={isTerminalClient ? 12 : 9} className="pb-5">
          <h2 className="d-flex align-items-center mt-40px mb-4">
            {t('notifications')}

            {!!notificationStore.unreadNotificationCount && (
              <NotificationCount count={notificationStore.unreadNotificationCount} className="ms-2" />
            )}
          </h2>

          <div className="bg-white rounded shadow-main">
            <div
              className="p-4"
              style={{ borderBottom: `${notificationStore.notifications?.length ? 0 : '1px'} solid #E1E1E1` }}
            >
              <Row className="align-items-center">
                <Col xs={12} lg={4}>
                  <DropdownList
                    list={options.map((option) => ({ ...option, label: t(option.label) }))}
                    value={
                      options.find((option) => String(option.value) === String(filterStore.params.typeId)) || options[0]
                    }
                    onSelectItem={(option) => onChangeOption(option)}
                    style={{ zIndex: 1 }}
                  />
                </Col>
                <Col xs={12} lg={8} className={`d-flex align-items-center ${isAdmin ? '' : 'justify-content-end'}`}>
                  {isAdmin && (
                    <InputSearch
                      className="flex-grow-1"
                      placeholder={t('search', { ns: 'common' }) || ''}
                      value={filterStore.params?.clientName || ''}
                      onChange={(newValue: string) => updateFilter('clientName', newValue)}
                    />
                  )}
                  {!!notificationStore.unreadNotificationCount && (
                    <span className="text-primary cursor-pointer text-nowrap ms-3" onClick={onReadAllHandler}>
                      Прочитать все
                    </span>
                  )}
                </Col>
              </Row>
            </div>

            {notificationStore.isLoading && <Loader />}

            {!notificationStore.isLoading &&
              notificationStore.notifications?.map((notification: Notification) => (
                <div key={notification.id} className="p-4 border-top" style={{ borderColor: '#E1E1E1' }}>
                  <TableRow notification={notification} />
                </div>
              ))}

            {!notificationStore.isLoading && notificationStore.notifications?.length === 0 && (
              <EmptyResult text={t('no_notifications')} />
            )}
            <PaginationSet
              className="p-4"
              items={Number(filterStore.params.total)}
              page={Number(filterStore.params.page)}
              pageCount={Number(filterStore.params.limit)}
              isTerminalClient={isTerminalClient}
              onPageCountChange={(page) => updateFilter('limit', String(page))}
              onPageChange={(page) => updateFilter('page', String(page))}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default observer(NotificationList);
