import { action, makeAutoObservable } from 'mobx';
import { RootStore } from '../../store';
import Api, {
  BookingHistory,
  BookingStatus,
  BookingStatusResponse,
  BookingsStatusChangeParam,
  FetchBookingsResponse,
} from './api';
import { downloadFile } from '../../api';

export class BookingStore {
  rootStore: RootStore;

  bookings: Booking[] = [];
  booking: Booking | null = null;
  bookingStatusesList: BookingStatus[] = [];
  history: BookingHistory[] = [];
  schemeFile: string | null = null;
  isLoading = true;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  async fetchBookingStatuses() {
    await Api.fetchBookingStatuses().then(
      action('fetchSuccess', (response: BookingStatusResponse) => {
        this.setBookingStatuses(response);
      }),
      action('fetchError', (error: unknown) => console.error(error))
    );
  }

  setBookingStatuses(bookingStatuses: BookingStatusResponse) {
    this.bookingStatusesList = bookingStatuses?.statuses;
  }

  async fetchBookings(searchParams?: string) {
    this.setIsLoading(true);

    await Api.fetchBookings(searchParams)
      .then(
        action('fetchSuccess', (response: FetchBookingsResponse) => {
          this.setBookings(response.data);
          this.rootStore.filterStore.update('total', String(response.total));
        }),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  setBookings(bookings: Booking[]) {
    this.bookings = bookings;
  }

  async fetchBookingById(id: string) {
    this.setIsLoading(true);

    await Api.fetchBookingById(id)
      .then(
        action('fetchSuccess', async (response: { data: Booking }) => {
          this.setBooking(response.data);
          const document = await downloadFile(response.data?.mapp?.scheme_uuid);
          this.setSchemeFile(document);
        }),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  setBooking(booking: Booking | null) {
    this.booking = booking;
  }

  setSchemeFile(file: Blob) {
    this.schemeFile = file ? URL.createObjectURL(file as Blob) : null;
  }

  async fetchHistory(id: string) {
    await Api.fetchHistory(id).then(
      action('fetchSuccess', (response: { data: BookingHistory[] }) => this.setHistory(response.data)),
      action('fetchError', (error: unknown) => console.error(error))
    );
  }

  setHistory(history: BookingHistory[]) {
    this.history = history;
  }

  cancelBooking(booking: Booking) {
    const requestParams = {
      id: booking.id,
      cancelCode: booking.number,
    };

    return Api.cancelBooking(requestParams)
      .then(
        action('fetchSuccess', () => true),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  changeStatus(data: BookingsStatusChangeParam) {
    return Api.fetchChangeStatus(data)
      .then(
        action('fetchSuccess', () => true),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  getBookingStatuses(lang: string | null): SelectOption[] {
    const key = lang === 'en' ? 'name_en' : 'name_ru';
    return this.bookingStatusesList?.map((v) => ({
      value: v.status_code,
      label: v[key],
    }));
  }
}
