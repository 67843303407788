import { makeAutoObservable } from 'mobx';
import { RootStore } from '.';

const initialParams: FilterParams = {
  search: '',
  limit: '10',
  page: '1',
  total: '1',
};

const excludedValues = ['all', 'ALL_ACTIVITY', 'ALL_STATUSES', 'ALL_TIME'];

export class FilterStore {
  rootStore: RootStore;
  params: FilterParams = {};
  isTerminalClient = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.isTerminalClient = this.rootStore.isFSEQ();
  }

  set(pageParams: FilterParams, newParams: string) {
    const searchParams = new URLSearchParams(newParams);
    const newFilteredParams = Object.fromEntries(searchParams);
    const modifiedInitialParams = { ...initialParams };

    if (this.isTerminalClient) {
      modifiedInitialParams.limit = '5';
    }

    this.params = { ...modifiedInitialParams, ...pageParams, ...newFilteredParams };
  }

  async update(key: string, value: string) {
    this.params[key] = value;
  }

  get filteredParams() {
    const params: Record<string, string | string[]> = {};

    const { search, limit, page, total, ...additionalParams } = this.params;

    if (search !== '') {
      params.search = search;
    }
    if (limit !== (this.isTerminalClient ? '5' : '10')) {
      params.limit = limit;
    }
    if (page !== '1') {
      params.page = page;
    }

    Object.entries(additionalParams).forEach(([key, value]) => {
      if (!excludedValues.includes(value)) {
        params[key] = value;
      }
    });

    return params;
  }

  get searchParams() {
    const params: Record<string, string> = {};

    const { search, limit, page, total, ...additionalParams } = this.params;

    if (search !== '') {
      params.search = search;
    }

    params.limit = limit;
    params.page = page;

    Object.entries(additionalParams).forEach(([key, value]) => {
      if (!excludedValues.includes(value)) {
        params[key] = value;
      }
    });

    return new URLSearchParams(params).toString();
  }
}
