import jwt_decode from 'jwt-decode';
import { makeAutoObservable } from 'mobx';
import { AuthClientInitOptions } from '@react-keycloak/core/lib';
import { RootStore } from '.';
import { CLIENT_ROLE } from '../static';
// import { ADMIN_ROLE, ANALYTIC_ROLE } from '../static';

export class AuthStore {
  rootStore: RootStore;
  account?: AuthClientInitOptions;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setAccount(token: string) {
    const account: AuthClientInitOptions = jwt_decode(token);

    // for test
    // account.role = ADMIN_ROLE;
    // account.role = ANALYTIC_ROLE;

    this.account = account;
  }

  get role() {
    if (!this.account) return false;

    if (this.account?.role === undefined) return CLIENT_ROLE;

    return this.account.role;
  }
}
