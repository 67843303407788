import Spinner from '../Spinner';

type LoaderProps = React.HTMLAttributes<HTMLElement> & {
  size?: number;
  height?: number;
};

const Loader = ({ size = 48, height = 146, ...props }: LoaderProps) => (
  <div className="d-flex px-3" style={{ height }} {...props}>
    <Spinner size={size} className="m-auto text-primary" />
  </div>
);

export default Loader;
