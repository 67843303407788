import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { differenceInHours } from 'date-fns';
import { Container, Row, Col } from 'react-bootstrap';

import Loader from '../../../../components/Loader';
import TerminalHeader from '../../../../components/TerminalHeader';
import Icon from '../../../../components/Icon';

import CancellationConfirmModal from './components/CancellationConfirmModal';
import ReschedulingConfirmModal from './components/ReschedulingConfirmModal';
import ChangeStatusModal from './components/ChangeStatusModal';
import SuccessModal from './components/SuccessModal';
import History from './components/History';
import LeftPanel from './components/LeftPanel';
import WarningModal from './components/WarningModal';

import { ADMIN_ROLE, TransportClassId } from '../../../../static';
import { downloadFile } from '../../../../api';
import Utils from './../../../../utils';
import store from '../../../../store';
import YourNextSteps from './components/YourNextSteps';
import Modal from '../../../../components/Modal';

const { bookingStore, isFSEQ, authStore } = store;

function BookingSingle() {
  const { id } = useParams();
  const { t, i18n } = useTranslation(['booking', 'status', 'transport']);
  const isTerminalClient = isFSEQ();

  const isAdmin = authStore.role === ADMIN_ROLE;

  const navigate = useNavigate();

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isReschedulingConfirmModalVisible, setIsReschedulingConfirmModalVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [isCancellationWarningModalVisible, setIsCancellationWarningModalVisible] = useState(false);
  const [isReschedulingWarningModalVisible, setIsReschedulingWarningModalVisible] = useState(false);
  const [isChangeStatusModalVisible, setIsChangeStatusModalVisible] = useState(false);

  const [tsDocument, setTsDocument] = useState<Blob | null>(null);
  const [isTSDocumentModalVisible, setIsTSDocumentModalVisible] = useState(false);
  const [isSchemeModalVisible, setIsSchemeModalVisible] = useState(false);

  useEffect(() => {
    if (!id) return;

    bookingStore.fetchBookingById(id);
    if (isAdmin) {
      bookingStore.fetchHistory(id);
    }
  }, [id, isAdmin]);

  useEffect(() => {
    bookingStore.fetchBookingStatuses();
  }, []);

  const date = Utils.getDate(bookingStore.booking?.time_slot, 'd MMMM yyyy', true);
  const slot = Utils.getSlot(bookingStore.booking?.time_slot, true);

  const pageTitle = `${t('booking')} ${date} ${t('year_short')}., ${slot}`;
  const confirmModalTitle = `${t('confirm_cancellation_booking')} ${date} ${t('year_short')}., ${slot}`;
  const reschedulingConfirmModalTitle = `${t('confirm_rescheduling')} ${date} ${t('year_short')}., ${slot}`;
  const bookingStatuses = bookingStore.getBookingStatuses(i18n.language);
  const statusName = bookingStatuses?.find((v) => v.value === bookingStore.booking?.status)?.label;

  const isLoadBooking = !bookingStore.isLoading && !!bookingStore.booking;

  const isActive = () => {
    if (bookingStore.booking === null) return false;

    const { status } = bookingStore.booking;
    return !['executed', 'unexecuted', 'canceled'].includes(status);
  };

  const handleDownloadFile = async (fileUuid: string) => {
    const result = await downloadFile(fileUuid);
    const newBlob = new File([result], 'file', { type: result.type });
    setTsDocument(newBlob);
    setIsTSDocumentModalVisible(true);
  };

  const handleCancelBooking = () => {
    if (bookingStore.booking === null) return;
    const { time_slot } = bookingStore.booking;
    const diff = differenceInHours(new Date(time_slot), new Date());
    if (diff < 3) {
      setIsCancellationWarningModalVisible(true);
    } else {
      setIsConfirmModalVisible(true);
    }
  };

  const handleRescheduling = () => {
    if (bookingStore.booking === null) return;
    const { time_slot } = bookingStore.booking;
    const diff = differenceInHours(new Date(time_slot), new Date());
    if (diff < 3) {
      setIsReschedulingWarningModalVisible(true);
    } else {
      setIsReschedulingConfirmModalVisible(true);
    }
  };

  const handleChangeStatus = () => {
    setIsChangeStatusModalVisible(true);
  };

  return (
    <div
      className={`${!isTerminalClient ? 'flex-grow-1 ' : 'm-auto terminal-min-height transform-y-180px '}pb-3 pb-sm-5`}
    >
      {isTerminalClient && <TerminalHeader />}
      <Container>
        <Row>
          <Col>
            <Link to="/booking" className="link-back my-4">
              {t('back', { ns: 'common' })}
            </Link>
          </Col>
        </Row>
        <Row>
          {bookingStore.isLoading && (
            <Col>
              <div className="bg-white rounded shadow-main p-4">
                <Loader />
              </div>
            </Col>
          )}

          {isLoadBooking && (
            <>
              <Col xs={12} lg={isTerminalClient ? 12 : 9} className="mb-3">
                <div className="d-grid shadow-main rounded-3 bg-white">
                  <div className="p-4">
                    <h4 className="m-0 text-center">{pageTitle}</h4>
                  </div>
                  <hr className="m-0" />

                  <div className="p-4 d-grid gap-4">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex gap-2">
                        <Icon name={bookingStore.booking?.status || ''}></Icon>
                        <span>{statusName}</span>
                      </div>
                    </div>
                    <div>
                      <h5>{t('vehicle_data')}</h5>
                      {bookingStore.booking?.cars?.[TransportClassId.TRUCK] && (
                        <div className="row mt-3">
                          <div className="col-md-3">
                            <div className="text-caption">{t('category')} </div>
                            <div>
                              {
                                bookingStore.booking?.cars?.[TransportClassId.TRUCK]?.[
                                  i18n.language === 'en' ? 'type_name_eng' : 'type_name'
                                ]
                              }
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="text-caption">{t('gosnomer', { ns: 'transport' })} </div>
                            <div>{bookingStore.booking?.cars?.[TransportClassId.TRUCK]?.grnz}</div>
                          </div>
                          <div className="col-md-3">
                            <div className="text-caption">{t('brand', { ns: 'transport' })} </div>
                            <div>{bookingStore.booking?.cars?.[TransportClassId.TRUCK]?.name}</div>
                          </div>
                          <div className="col-md-3">
                            <div className="text-caption">{t('model', { ns: 'transport' })} </div>
                            <div>{bookingStore.booking?.cars?.[TransportClassId.TRUCK]?.model}</div>
                          </div>
                        </div>
                      )}
                      {bookingStore.booking?.cars?.[TransportClassId.TRAILER] && (
                        <div className="row mt-3">
                          <div className="col-md-3">
                            <div className="text-caption">{t('category')} </div>
                            <div>
                              {
                                bookingStore.booking?.cars?.[TransportClassId.TRAILER]?.[
                                  i18n.language === 'en' ? 'type_name_eng' : 'type_name'
                                ]
                              }
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="text-caption">{t('gosnomer', { ns: 'transport' })} </div>
                            <div>{bookingStore.booking?.cars?.[TransportClassId.TRAILER]?.grnz}</div>
                          </div>
                        </div>
                      )}
                    </div>

                    <hr className="m-0" />

                    <div>
                      <h5>{t('app_data')}</h5>
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="d-grid gap-4">
                            <div className="d-grid">
                              <span className="text-caption">{t('name')}</span>
                              {bookingStore.booking?.mapp?.[i18n.language === 'en' ? 'name_eng' : 'name']}
                            </div>
                            <div className="d-grid">
                              <span className="text-caption">{t('address')}</span>
                              {bookingStore.booking?.mapp?.[i18n.language === 'en' ? 'address_eng' : 'address']}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-grid">
                            {bookingStore.schemeFile && (
                              <img
                                onClick={() => setIsSchemeModalVisible(true)}
                                className="w-100 cursor-pointer"
                                src={bookingStore.schemeFile || ''}
                                alt=""
                              ></img>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <>
                      <hr className="m-0" />

                      <div>
                        <h5>{t('contacts')}</h5>
                        <div className="d-flex gap-4 mt-3">
                          <div className="d-grid">
                            <span className="text-caption">{t('phone')} </span>
                            {bookingStore.booking?.driver.phone || t('no_data', { ns: 'common' })}
                          </div>
                          <div className="d-grid">
                            <span className="text-caption">{t('email')} </span>
                            {bookingStore.booking?.driver.email || t('no_data', { ns: 'common' })}
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                  {isAdmin && <History history={bookingStore.history}></History>}
                  {!isAdmin && <YourNextSteps></YourNextSteps>}
                </div>
              </Col>
              <Col xs={12} lg={isTerminalClient ? 12 : 3}>
                <LeftPanel
                  isActive={isActive()}
                  status={bookingStore.booking?.status}
                  cars={bookingStore.booking?.cars}
                  onCancelBooking={handleCancelBooking}
                  onRescheduling={handleRescheduling}
                  onDownload={handleDownloadFile}
                  onChangeStatus={handleChangeStatus}
                ></LeftPanel>
              </Col>
            </>
          )}
        </Row>
      </Container>

      <CancellationConfirmModal
        title={confirmModalTitle}
        show={isConfirmModalVisible}
        onClose={() => setIsConfirmModalVisible(false)}
        onSuccess={() => {
          setIsSuccessModalVisible(true);
          id && bookingStore.fetchBookingById(id);
        }}
        booking={bookingStore.booking}
      />
      <ReschedulingConfirmModal
        title={reschedulingConfirmModalTitle}
        show={isReschedulingConfirmModalVisible}
        onClose={() => setIsReschedulingConfirmModalVisible(false)}
        onSuccess={() => {
          navigate('/booking-process', { state: { bookingId: bookingStore.booking?.id } });
        }}
        booking={bookingStore.booking}
      />
      <WarningModal
        show={isCancellationWarningModalVisible}
        onClose={() => setIsCancellationWarningModalVisible(false)}
        header={t('cancellation_of_booking', { ns: 'booking' })}
        body={t('cancellation_warning_message', { ns: 'booking' })}
      />
      <WarningModal
        show={isReschedulingWarningModalVisible}
        onClose={() => setIsReschedulingWarningModalVisible(false)}
        header={t('rescheduling_title', { ns: 'booking' })}
        body={t('rescheduling_warning_message', { ns: 'booking' })}
      />
      <SuccessModal show={isSuccessModalVisible} onClose={() => setIsSuccessModalVisible(false)} />
      <Modal
        show={isTSDocumentModalVisible}
        hideCloseButton={false}
        onHide={() => setIsTSDocumentModalVisible(false)}
        header={
          <div className="d-flex justify-content-between align-items-center">
            <div>{t('view_ts', { ns: 'booking' }) || ''}</div>
            <Icon
              name="download"
              className="ms-2 cursor-pointer"
              width={22}
              height={22}
              onClick={() => Utils.downloadFile(tsDocument)}
            />
          </div>
        }
        body={<img className="w-100" src={(tsDocument && URL.createObjectURL(tsDocument as Blob)) || ''} alt="" />}
      />
      <ChangeStatusModal
        show={isChangeStatusModalVisible}
        onClose={() => setIsChangeStatusModalVisible(false)}
        onSuccess={() => {
          id && bookingStore.fetchBookingById(id);
        }}
        booking={bookingStore.booking}
        bookingStatuses={bookingStatuses}
      />
      <Modal
        show={isSchemeModalVisible}
        hideCloseButton={false}
        header={t('scheme', { ns: 'booking' }) || ''}
        onHide={() => setIsSchemeModalVisible(false)}
        body={<img className="w-100" src={bookingStore.schemeFile || ''} alt="" />}
      />
    </div>
  );
}

export default observer(BookingSingle);
