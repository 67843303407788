import QuizCard from '../components/QuizCard';

function QuizCards() {
  return (
    <section>
      <h4 className="mb-4">QuizCard</h4>
      <QuizCard text="Легковое" className="mb-4" />
      <QuizCard text="Легковое" disabled />
    </section>
  );
}

export default QuizCards;
