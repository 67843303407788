import Button from '../components/Button';

function Buttons() {
  return (
    <section>
      <h4>Primary</h4>
      <p>* если меньше 16 символов отсутпы слева/справа 40px</p>
      <div className="d-flex my-3">
        <Button variant="primary" text="Продолжить" className="me-4" />
        <Button variant="primary" text="Продолжить" className="me-4" isLoading={true} />
        <Button variant="primary" text="Продолжить" disabled />
      </div>

      <p>* если больше или равно 16 символов отсутпы слева/справа 24px</p>
      <div className="d-flex my-3">
        <Button variant="primary" text="Нет постоянной регистрации" className="me-4" />
        <Button variant="primary" text="Нет постоянной регистрации" className="me-4" isLoading={true} />
        <Button variant="primary" text="Нет постоянной регистрации" disabled />
      </div>

      <hr className="my-3" />

      <h4>Outline</h4>
      <div className="d-flex mt-3">
        <Button variant="outline" text="Продолжить" className="me-4" />
        <Button variant="outline" text="Продолжить" className="me-4" isLoading={true} />
        <Button variant="outline" text="Продолжить" disabled />
      </div>
    </section>
  );
}

export default Buttons;
