import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { observer } from 'mobx-react-lite';
import Select from '../../../../../components/Select';
import store from '../../../../../store';
const { filterStore } = store;

type Props = {
  onChange: (key: string, value: (string | string[]) | (Date | null)[]) => void;
  ts_date_start: string;
  ts_date_end: string;
};

function AdminTransportListFilter({ onChange, ts_date_start, ts_date_end }: Props) {
  const { t } = useTranslation(['common']);
  const [dateRange, setDateRange] = useState([null, null] as (Date | null)[]);
  const [startDate, endDate] = dateRange;
  const lang = localStorage.getItem('lang');

  const statusOptions: SelectOption[] = [
    {
      label: t('all_statuses', { ns: 'booking' }),
      value: 'all',
    },
    {
      label: t('review', { ns: 'transport' }),
      value: '1',
    },
    {
      label: t('verified', { ns: 'transport' }),
      value: '2',
    },
    {
      label: t('rejected', { ns: 'transport' }),
      value: '3',
    },
  ];

  const kioskOptions: SelectOption[] = [
    {
      label: t('where_created', { ns: 'transport' }),
      value: 'all',
    },
    { value: 'false', label: t('portal', { ns: 'transport' }) },
    { value: 'true', label: t('kiosk', { ns: 'transport' }) },
  ];

  useEffect(() => {
    const init = [
      ts_date_start && ts_date_start !== 'ALL_TIME' ? new Date(ts_date_start) : null,
      ts_date_end && ts_date_end !== 'ALL_TIME' ? new Date(ts_date_end) : null,
    ];
    setDateRange(init);
  }, [ts_date_start, ts_date_end]);

  return (
    <>
      <div className="bg-white rounded shadow-main p-3 mb-3 p-md-4 mb-md-4 ps-2">
        <div className="mb-2">{t('selecting_a_date_or_period', { ns: 'booking' })}</div>
        <DatePicker
          selectsRange
          locale={lang || 'ru'}
          startDate={startDate}
          endDate={endDate}
          className="w-100 form-control"
          dateFormat="dd.MM.yyyy"
          onChange={(update) => {
            setDateRange(update);

            if (!!update[0] === !!update[1]) {
              onChange('date', update);
            }
          }}
          isClearable={true}
        />
      </div>
      <div className="bg-white rounded shadow-main p-3 p-md-4 mb-md-4 ps-2">
        <Select
          className="mb-3"
          isCollapse
          options={statusOptions}
          value={statusOptions.find((option) => option.value === filterStore.params.validation_statuses)}
          onChange={(e) => e?.value && onChange('validation_statuses', [e.value])}
        />
        <Select
          className="mb-3"
          isCollapse
          options={kioskOptions}
          value={kioskOptions.find((option) => option.value === filterStore.params.is_kiosk)}
          onChange={(e) => e?.value && onChange('is_kiosk', e.value)}
        />
      </div>
    </>
  );
}

export default observer(AdminTransportListFilter);
