/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement } from 'react';
import { makeAutoObservable, action } from 'mobx';
import groupBy from 'lodash/groupBy';
import { format } from 'date-fns';

import ApiBookingProcess, { BookingMove, BookingSlotsRequest, BookingSlotsResponse, Captcha } from './api';
import { ProfileShort, ProfileShortKeys } from '../Profile/api';
import { RootStore } from '../../store';

import Utils from '../../utils';
import { PERIODS, TransportClassId } from '../../static';
import { AxiosError } from 'axios';

const initialTransportParams: Record<string, string | boolean> = {
  load_type: 'empty',
  transportation_type: 'transit',
};

const initialProfile: ProfileShort = {
  phone: '',
  email: '',
};

export class BookingProcessStore {
  rootStore: RootStore;

  steps: ReactElement[] | null = null;
  currentStep = 0;
  lastStep = 0;

  transportOption: { [carType: string]: SelectOptionExtended | null } = {};
  transportParams = initialTransportParams;

  profile = initialProfile;

  mappOption: SelectOptionExtended | null = null;

  briefed = false;

  slots: { [date: string]: SlotType[] } = {};
  selectedDay: Date | null = null;
  selectedPeriod: Period | null = null;

  bookingResponse: Booking | null = null;

  bookingId = '';
  isTransfer = false;

  isLoading = false;

  createBookingError = false;
  captchaBookingError = false;
  slotsBookingError = false;
  captcha: Captcha | null = null;
  captchaInput = '';

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  async initBookingProcess(steps: ReactElement[], option: BookingProcessInitOption) {
    this.setIsLoading(true);

    if (option?.bookingId) {
      this.rootStore.bookingStore
        .fetchBookingById(option?.bookingId)
        .then(() => {
          this.setIsTransfer(true);
          this.setBookingId(option.bookingId || '');

          if (this.rootStore.bookingStore.booking === null) return;

          const { cars, mapp } = this.rootStore.bookingStore.booking;

          if (cars) {
            Object.keys(cars).forEach((carType) =>
              this.setTransportOption(carType, {
                ...cars[carType],
                label: cars[carType]?.name,
                value: cars[carType]?.id,
              })
            );
          }

          this.setMappOption({
            label: mapp.name || '',
            value: mapp.id || '',
            ...mapp,
          });

          steps.splice(0, 3);

          this.setLastStep(steps.length);
          this.initSteps(steps);
        })
        .catch((error) => console.error(error))
        .finally(() => this.setIsLoading(false));

      return;
    }

    Promise.all([this.rootStore.profileStore.fetchProfile(), this.rootStore.mappStore.fetchMapps()])
      .then(async () => {
        if (option?.mappId || this.rootStore.isFSEQ()) {
          await this.rootStore.mappStore
            .fetchMappById(option?.mappId || this.rootStore.getMappIdFromFSEQ())
            .then(() => this.setMappOption(this.rootStore.mappStore.mappOption));

          steps.splice(2, 1);
        }

        this.setLastStep(steps.length);
        this.initSteps(steps);
      })
      .catch((error) => console.error(error))
      .finally(() => this.setIsLoading(false));
  }

  initSteps(steps: ReactElement[] | null) {
    this.steps = steps;
  }

  nextStep() {
    if (this.currentStep === this.lastStep) return;
    this.currentStep = this.currentStep + 1;
    Utils.scrollTop();
  }

  prevStep() {
    if (this.currentStep === 0) return;
    this.currentStep = this.currentStep - 1;
    Utils.scrollTop();
  }

  setCreateBookingError(value: boolean) {
    this.createBookingError = value;
  }

  setCaptchaBookingError(value: boolean) {
    this.captchaBookingError = value;
  }

  setSlotsBookingError(value: boolean) {
    this.slotsBookingError = value;
  }

  setCurrentStep(step: number) {
    this.currentStep = step;
  }

  setLastStep(step: number) {
    this.lastStep = step;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsTransfer(value: boolean) {
    this.isTransfer = value;
  }

  setBookingId(id: string) {
    this.bookingId = id;
  }

  setTransportOption(carType: string, transportOption: SelectOptionExtended | null) {
    if (carType) {
      this.transportOption[carType] = transportOption;
    }
  }

  setTransportParams(params: Record<string, string | boolean>) {
    this.transportParams = params;
  }

  onChangeTransportParams(key: string, value: string | boolean) {
    this.transportParams[key] = value;
  }

  clearTransportOption() {
    this.transportOption = {};
  }

  clearTransportParams() {
    this.transportParams = initialTransportParams;
  }

  clearProfile() {
    this.profile = initialProfile;
  }

  onChangeBriefed(briefed: boolean) {
    this.briefed = briefed;
  }

  setCaptchaInput(value: string) {
    this.captchaInput = value;
  }

  onChangeProfile(key: ProfileShortKeys, value: string) {
    if (key === 'phone') {
      const number = value.replace('+', '').replace(/\D/, '');
      this.profile[key] = `+${number}`;
    } else {
      this.profile[key] = value;
    }
  }

  onToggleContactsData(isChecked: boolean) {
    if (!isChecked) return;

    this.profile.phone = this.rootStore.profileStore.profile.phone || '';
    this.profile.email = this.rootStore.profileStore.profile.notify_email || '';
  }

  setMappOption(mappOption: SelectOptionExtended | null) {
    this.mappOption = mappOption;
  }

  getSlots() {
    this.setIsLoading(true);

    ApiBookingProcess.fetchSlots(this.bookingSlotsParams)
      .then(
        action('fetchSuccess', (response: BookingSlotsResponse) => {
          this.setSlots(response.data);
        }),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  setSlots(slots: SlotType[]) {
    this.slots = groupBy(
      slots.filter((v) => v.count !== 0),
      (v) => v.time.split('T')?.[0]
    );
  }

  getCaptcha() {
    this.setIsLoading(true);

    ApiBookingProcess.getCaptcha()
      .then(
        action('fetchSuccess', (response: Captcha) => {
          this.setCaptcha(response);
        }),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  setCaptcha(data: Captcha) {
    this.captcha = data;
  }

  setSelectedDay(day: Date | null) {
    if (day === null) {
      this.selectedDay = null;
      return;
    }

    this.selectedDay = day;
  }

  setSelectedPeriod(period: Period | null) {
    this.selectedPeriod = period;
  }

  createBooking() {
    if (this.selectedDay === null) return;
    if (this.selectedPeriod === null) return;
    if (this.mappOption === null) return;

    this.setIsLoading(true);

    const car: { [carType: string]: { [key: string]: string | boolean | unknown } } = {};

    if (this.transportOption[TransportClassId.TRUCK]) {
      const truck = this.transportOption[TransportClassId.TRUCK];
      car[TransportClassId.TRUCK] = {
        id: truck?.car_id,
        type_name: 'Truck',
        name: truck?.name,
        model: truck?.model,
        grnz: truck?.grnz_normalized,
        file_uuid: truck?.document_uuid,
        // file_name: truck?.file_name,
      };
    }

    if (this.transportOption[TransportClassId.TRAILER]) {
      const trailer = this.transportOption[TransportClassId.TRAILER];
      car[TransportClassId.TRAILER] = {
        id: trailer?.car_id,
        type_name: 'Trailer',
        name: trailer?.name,
        model: trailer?.model,
        grnz: trailer?.grnz_normalized,
        file_uuid: trailer?.document_uuid,
        // file_name: truck?.file_name,
      };
    }

    const lang = (localStorage.getItem('lang') || 'ru').toUpperCase();

    const params = {
      load_type: this.transportParams?.load_type,
      transportation_type: this.transportParams?.transportation_type,
      driver: {
        email: this.profile.email,
        phone: this.profile.phone,
      },
      car,
      time_slot: this.selectedPeriod?.timestamp,
      bph: this.selectedPeriod.bph,
      mapp_id: this.mappOption.id,
      lang,
      is_kiosk: !!this.rootStore.isFSEQ(),
      captcha_id: this.captcha?.captcha_id,
      captcha_input: this.captchaInput,
    };

    ApiBookingProcess.createBooking(params)
      .then(
        action('fetchSuccess', (response: { data: Booking }) => {
          this.setBookingResponse(response.data);
          this.nextStep();
        }),
        action('fetchError', (error: AxiosError<any>) => {
          if (error?.response?.data?.error?.message.includes('err_cooldown_not_expired')) {
            this.setCreateBookingError(true);
          }
          if (error?.response?.data?.error?.message.includes('no_more_slots')) {
            this.setSlotsBookingError(true);
          }
          if (error?.response?.data === 'captcha failed') {
            this.setCaptchaBookingError(true);
          }
        })
      )
      .finally(() => this.setIsLoading(false));
  }

  moveBooking() {
    const params: BookingMove = {
      bookingId: this.bookingId,
      time: this.selectedPeriod?.timestamp,
      bph: this.selectedPeriod?.bph,
      captcha_id: this.captcha?.captcha_id || '',
      captcha_input: this.captchaInput,
    };

    ApiBookingProcess.moveBooking(params)
      .then(
        action('fetchSuccess', (response: { booking: Booking }) => {
          this.setBookingResponse(response.booking);
          this.nextStep();
        }),
        action('fetchError', (error: AxiosError<any>) => {
          if (error?.response?.data?.error?.message.includes('no_more_slots')) {
            this.setSlotsBookingError(true);
          }
          if (error?.response?.data === 'captcha failed') {
            this.setCaptchaBookingError(true);
          }
        })
      )
      .finally(() => this.setIsLoading(false));
  }

  setBookingResponse(bookingResponse: Booking | null) {
    this.bookingResponse = bookingResponse;
  }

  get availableDates(): Date[] {
    return Object.keys(this.slots).map((v) => new Date(v));
  }

  get periods(): Period[] | void {
    if (this.slots === null) return;
    if (this.selectedDay === null) return;

    const params =
      this.slots[format(this.selectedDay, 'yyyy-MM-dd')]?.reduce((sum, cur) => {
        sum[new Date(cur.time).getUTCHours()] = cur;

        return sum;
      }, {} as Record<number, SlotType>) || {};

    return PERIODS.map((period, index) => {
      return {
        label: period,
        timestamp: params[index]?.time,
        count: params[index]?.count || 0,
        bph: params[index]?.bph || 0,
        isAvailable: index in params,
        isActive: this.selectedPeriod?.label === period,
      };
    });
  }

  get bookingSlotsParams(): BookingSlotsRequest | void {
    if (this.transportOption === null) return;
    if (this.mappOption === null) return;

    const params: BookingSlotsRequest = {
      mappId: this.mappOption.id as string,
    };

    // const { carClassId } = this.transportOption;
    // const { routeType, passengersNumber } = this.transportParams;

    // if (carClassId === TransportClassId.PASSENGER) {
    //   params.passengerCar = {};
    // }

    // if (carClassId === TransportClassId.TRUCK) {
    //   params.cargo = {
    //     laden: !!this.transportParams.weight,
    //   };
    // }

    // if (carClassId === TransportClassId.BUS) {
    //   params.bus = {
    //     flightType: routeType === 'regular' ? 'FlightType_REGULAR' : 'FlightType_IRREGULAR',
    //     countPass: passengersNumber ? Number(passengersNumber) : 0,
    //   };
    // }

    // if (carClassId === TransportClassId.BIKE) {
    //   params.bike = {};
    // }

    return params;
  }

  get selectedTruckLabel() {
    if (this.transportOption === null) return;
    const truck = this.transportOption[TransportClassId.TRUCK];

    return `${truck?.label || ''}`;
  }

  get selectedTrailerLabel() {
    if (this.transportOption === null) return;
    const truck = this.transportOption[TransportClassId.TRAILER];

    return `${truck?.label || ''}`;
  }

  resetData() {
    this.initSteps(null);
    this.setCurrentStep(0);
    this.setLastStep(0);
    this.clearTransportOption();
    this.clearTransportParams();
    this.clearProfile();
    this.setMappOption(null);
    this.setSlots([]);
    this.setSelectedDay(null);
    this.setSelectedPeriod(null);
    this.setBookingResponse(null);
    this.setIsLoading(false);
    this.setIsTransfer(false);
    this.setBookingId('');
    this.setCaptchaInput('');
  }
}
