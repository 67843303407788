import PhoneBlock from './components/PhoneBlock';
import EmailBlock from './components/EmailBlock';
import LoginBlock from './components/LoginBlock';
import { CLIENT_ROLE } from '../../../static';
// import PasswordBlock from './components/PasswordBlock';
import rootStore from '../../../store';
const { authStore } = rootStore;

function Account() {
  const isClient = authStore.role === CLIENT_ROLE;
  return (
    <div className="p-4 shadow-main rounded-4 custom-pointer bg-white d-grid gap-4 mt-2 mt-lg-0">
      {isClient && <PhoneBlock />}
      <LoginBlock />
      <EmailBlock />
      {/* <PasswordBlock /> */}
    </div>
  );
}

export default Account;
