import { useEffect } from 'react';
import { Link, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import keyBy from 'lodash/keyBy';

import BookingCard from './components/BookingCard';
import BookingListFilter from './components/BookingListFilter';
import i18next from '../../../../i18n';
import Loader from '../../../../components/Loader';
import InputSearch from './../../../../components/InputSearch';
import PaginationSet from './../../../../components/PaginationSet';
import Button from '../../../../components/Button';
import EmptyResult from '../../../../components/EmptyResult';
import TerminalHeader from '../../../../components/TerminalHeader';

// import { statusOptions, timeOptions, classOptions, typeOptions } from '../../../../static';

import store from '../../../../store';
import { ADMIN_ROLE } from '../../../../static';
import Attention from '../../../../components/Attention';
const { bookingStore, filterStore, authStore, mappStore, isFSEQ } = store;

// const transportClassOptions = [{ label: 'all', value: 'all' }, ...classOptions];

const initialParams: FilterParams = {
  status: 'all',
  ts_date_start: 'ALL_TIME',
  ts_date_end: 'ALL_TIME',
  with_trailer: 'all',
  mapp_ids: 'all',
};

function BookingList() {
  const { t, i18n } = useTranslation(['header', 'booking', 'common', 'transport']);
  const navigation = useNavigate();

  const { search } = useLocation();
  const [, setSearchParams] = useSearchParams();

  const isTerminalClient = isFSEQ();

  const isAdmin = authStore.role === ADMIN_ROLE;

  useEffect(() => {
    bookingStore.fetchBookingStatuses();
    mappStore.fetchMapps();
  }, []);

  useEffect(() => {
    filterStore.set(initialParams, search);
    bookingStore.fetchBookings(filterStore.searchParams);
  }, [search]);

  const updateFilter = (key: string, value: string | (Date | null)[]) => {
    if (key === 'search' || key === 'limit' || Object.keys(initialParams).includes(key)) {
      filterStore.update('page', '1');
    }
    if (Array.isArray(value)) {
      const [ts_date_start, ts_date_end] = value;
      Promise.all([
        filterStore.update('ts_date_start', ts_date_start ? format(ts_date_start, 'yyyy-MM-dd') : 'ALL_TIME'),
        filterStore.update('ts_date_end', ts_date_end ? format(ts_date_end, 'yyyy-MM-dd') : 'ALL_TIME'),
      ]).then(() => setSearchParams(filterStore.filteredParams));
    } else {
      filterStore.update(key, value).then(() => setSearchParams(filterStore.filteredParams));
    }
  };

  const isHaveBookings = !bookingStore.isLoading && !!bookingStore.bookings?.length;
  const isNotHaveBookings = !bookingStore.isLoading && !bookingStore.bookings?.length;
  const bookingStatusesDict = keyBy(bookingStore.getBookingStatuses(i18n.language), 'value');

  return (
    <Container
      className={`${!isTerminalClient ? 'flex-grow-1 ' : 'm-auto terminal-min-height transform-y-180px '}pb-3 pb-sm-5`}
    >
      {isTerminalClient && <TerminalHeader />}
      <Row>
        <Col xs={12} lg={9} className="mt-40px">
          <Attention title={t('title', { ns: 'attention' })} text={t('text', { ns: 'attention' })} />
        </Col>
      </Row>
      {!isAdmin && (
        <Row>
          <Col xs={12} lg={isTerminalClient ? 12 : 9}>
            {!isTerminalClient && <h2 className="mt-40px mb-4">{t('booking')}</h2>}

            {/* <div
              className="d-none align-items-center d-md-flex justify-content-between rounded py-28px px-4 mb-4"
              style={{ border: '1px dashed rgba(29, 93, 235, 0.18)' }}
            >
              <span className="text-secondary">{t('add_new_booking', { ns: 'booking' })}</span>

              <Link to="/booking-process" className={`${isTerminalClient ? 'btn btn-primary' : 'link-primary'} fs-6`}>
                {t('add', { ns: 'common' })}
              </Link>
            </div> */}

            <Button
              text={t('add', { ns: 'common' })}
              className="d-flex d-md-none justify-content-center mb-3 w-100"
              onClick={() => navigation('/booking-process')}
            />
          </Col>
        </Row>
      )}
      {isAdmin && !isTerminalClient && (
        <Row>
          <Col xs={12} lg={9}>
            {<h2 className="mt-40px mb-4">{t('booking')}</h2>}
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={12} lg={isTerminalClient ? 12 : 9}>
          <div className="bg-white rounded shadow-main p-4 mb-4 d-none d-lg-block">
            <InputSearch
              placeholder={t('search', { ns: 'common' }) || ''}
              value={filterStore.params?.search || ''}
              onChange={(newValue: string) => updateFilter('search', newValue)}
            />
          </div>

          <div className={isTerminalClient ? 'd-block mb-3' : 'd-block d-lg-none mb-3'}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t('filters', { ns: 'booking' })}</Accordion.Header>
                <Accordion.Body>
                  <BookingListFilter
                    onChange={updateFilter}
                    ts_date_start={filterStore.params.ts_date_start}
                    ts_date_end={filterStore.params.ts_date_end}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <div className="mb-4">
            {bookingStore.isLoading && (
              <div className="bg-white rounded shadow-main">
                <Loader />
              </div>
            )}

            {isHaveBookings && (
              <>
                {bookingStore.bookings?.map((booking: Booking, index: number) => (
                  <div key={index}>
                    <BookingCard booking={booking} bookingStatusesDict={bookingStatusesDict} />
                  </div>
                ))}
                <PaginationSet
                  items={Number(filterStore.params.total)}
                  page={Number(filterStore.params.page)}
                  pageCount={Number(filterStore.params.limit)}
                  isTerminalClient={isTerminalClient}
                  onPageCountChange={(page) => updateFilter('limit', String(page))}
                  onPageChange={(page) => updateFilter('page', String(page))}
                />
              </>
            )}

            {isNotHaveBookings && (
              <div className="bg-white rounded shadow-main">
                <EmptyResult text={i18next.t('no_reservations_were_found', { ns: 'booking' })} />
              </div>
            )}
          </div>
        </Col>
        <Col lg={3} className={isTerminalClient ? 'd-none' : 'd-none d-lg-block'}>
          <BookingListFilter
            onChange={updateFilter}
            ts_date_start={filterStore.params.ts_date_start}
            ts_date_end={filterStore.params.ts_date_end}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default observer(BookingList);
