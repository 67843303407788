import { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import Spinner from '../../../../components/Spinner';

import store from '../../../../store';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
const { profileStore } = store;

function Country() {
  const { t } = useTranslation(['profile', 'common']);
  const [newCountry, setNewCountry] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [countryOptions, setCountryOptions] = useState<SelectOption[]>([]);
  const [countryOption, setCountryOption] = useState<SelectOption | null>(null);
  const isFSEQ = store.isFSEQ();

  const fetchCountries = useCallback(async () => {
    const countries = await profileStore.fetchCountries();
    const countryOptions: SelectOption[] =
      countries?.map((country: CountryType) => ({
        value: country.code,
        label: country.name,
      })) || [];
    setCountryOptions(countryOptions);
  }, []);

  useEffect(() => {
    if (isEdit) setNewCountry('');
  }, [isEdit]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const onUpdateHandler = async () => {
    const isSuccessResponse = await profileStore.updateProfile({ country: newCountry });

    if (isSuccessResponse) {
      profileStore.fetchProfile();
      toast.info(t('data_changed', { ns: 'common' }));
      setNewCountry('');
      setIsEdit(false);
    }
  };

  const handleChangeSelect = (option: SingleValue<SelectOption>) => {
    if (option) {
      setCountryOption(option);
      setNewCountry(`${option.value}:${option.label}`);
    }
  };

  const Toggle = observer(() => (
    <div>
      <p className="mb-1 text-caption fs-7">{t('applicantCountry')}</p>
      <div className="d-flex">
        {
          <h5 className="my-auto me-4">
            {profileStore.isLoading && <Spinner size={24} className="m-auto text-primary" />}
            {(!profileStore.isLoading && profileStore.profile.country?.split(':')[1]) || ''}
          </h5>
        }
        <Link to="#" className={`${isFSEQ ? 'btn btn-primary' : 'link-primary'} fs-6`} onClick={() => setIsEdit(true)}>
          {t('change')}
        </Link>
      </div>
    </div>
  ));

  if (!isEdit) return <Toggle />;

  return (
    <div className="py-32px px-4 rounded-1 d-grid gap-32px" style={{ border: '1px dashed rgba(29, 93, 235, 0.18)' }}>
      <h5 className="m-0">
        {t('country_change')} {profileStore.profile.country?.split(':')[1] || ''}
      </h5>

      <div>
        <Select
          label={t('new_country')}
          placeholder={t('enter_country') || ''}
          options={countryOptions}
          value={countryOption}
          isSearchable={true}
          onChange={(option) => handleChangeSelect(option)}
          isDisabled={profileStore.isLoading}
        />
      </div>
      <div>
        <Button
          variant="primary"
          text={t('save', { ns: 'common' })}
          className="me-4"
          onClick={onUpdateHandler}
          isLoading={profileStore.isLoading}
          disabled={!newCountry}
        />
        <Button variant="outline" text={t('cancel', { ns: 'common' })} onClick={() => setIsEdit(false)} />
      </div>
    </div>
  );
}

export default observer(Country);
