import { useTranslation } from 'react-i18next';
import { MouseEvent as ReactMouseEvent, forwardRef, useEffect, useState } from 'react';
import Button from '../../../../../../components/Button';
import store from '../store';
import Modal from '../../../../../../components/Modal';
import { Col, Form, Row } from 'react-bootstrap';
import InputText from '../../../../../../components/InputText';
import InputMask from 'react-input-mask';

type Props = {
  schedule: { schedule_items: MappSchedule };
};

function ScheduleDate({ schedule }: Props) {
  const { t } = useTranslation(['dictionary', 'common']);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [currentDay, setCurrentDay] = useState<number | null>(null);
  const [isFullTime, setIsFullTime] = useState<{ [day: number]: boolean }>({});
  const [result, setResult] = useState<{ schedule_items: MappSchedule }>({ schedule_items: {} });
  const [timeStart, setTimeStart] = useState('');
  const [timeEnd, setTimeEnd] = useState('');
  const days: string[] = [t('Mon'), t('Tue'), t('Wed'), t('Thu'), t('Fri'), t('Sat'), t('Sun')];
  const timeStartMask = [/[0-2]/, timeStart[0] === '2' ? /[0-3]/ : /[0-9]/, ':', /[0-5]/, /[0-9]/];
  const timeEndMask = [/[0-2]/, timeEnd[0] === '2' ? /[0-3]/ : /[0-9]/, ':', /[0-5]/, /[0-9]/];

  useEffect(() => {
    setResult(schedule);
  }, [schedule]);

  const updateResult = (day: number, method: 'create' | 'add' | 'delete', value?: { start: string; end: string }) => {
    const updatedResult = { ...result };
    switch (method) {
      case 'create': {
        if (value) {
          updatedResult.schedule_items[day] = { weekday_schedule_items: [value] };
        } else {
          updatedResult.schedule_items[day] = { weekday_schedule_items: [] };
        }
        break;
      }
      case 'delete': {
        delete updatedResult.schedule_items[day];
        break;
      }
      case 'add': {
        if (value) {
          updatedResult.schedule_items[day].weekday_schedule_items.push(value);
        }
        break;
      }
    }

    setResult(updatedResult);
  };

  const resetHours = () => {
    setTimeStart('');
    setTimeEnd('');
  };

  const isValid = (): boolean => {
    const startDate = new Date(`01.01.2001 ${timeStart}`);
    const endDate = new Date(`01.01.2001 ${timeEnd}`);

    return startDate.toString() !== 'Invalid Date' && endDate.toString() !== 'Invalid Date' && startDate < endDate;
  };

  const handleTimeStart = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setTimeStart(value);
  const handleTimeEnd = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setTimeEnd(value);

  const toggleDays = (day: number, checked: boolean) => {
    if (checked) {
      updateResult(day, 'create');
    } else {
      updateResult(day, 'delete');
    }
  };

  const openAddHoursModal = (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>, day: number) => {
    event.preventDefault();
    setCurrentDay(day);
    setIsOpenModal(true);
  };

  const setHours = () => {
    setIsOpenModal(false);
    updateResult(currentDay as number, 'add', {
      start: timeStart,
      end: timeEnd,
    });
    setCurrentDay(null);
    resetHours();

    store.changeField('schedule', result);
  };

  const setFullHours = (day: number, checked: boolean) => {
    updateResult(day, 'create');

    const updatedIsFullTime = { ...isFullTime };
    updatedIsFullTime[day] = checked;
    setIsFullTime(updatedIsFullTime);

    const updatedResult = {
      ...result,
      schedule_items: {
        ...result.schedule_items,
        [day]: {
          weekday_schedule_items: checked
            ? [
                {
                  start: '00:00',
                  end: '24:00',
                },
              ]
            : [],
        },
      },
    };

    setResult(updatedResult);

    store.changeField('schedule', updatedResult);
  };

  return (
    <Row className="schedule-date py-4 px-2">
      {days.map((day, index) => (
        <Col key={index}>
          <Form.Check type="checkbox" id={`day-${index + 1}`} label={day} className="btn w-100">
            <Form.Check.Input
              type="checkbox"
              className="d-none"
              checked={!!result.schedule_items[index + 1] || false}
              onChange={(e) => toggleDays(index + 1, e.target.checked)}
            />
            <Form.Check.Label className="w-100 cursor-pointer">{day}</Form.Check.Label>
          </Form.Check>
          {!!result.schedule_items[index + 1]?.weekday_schedule_items && (
            <>
              {!isFullTime[index + 1] && (
                <div className="mt-4">
                  {Object.entries(result.schedule_items[index + 1].weekday_schedule_items).map(([day, timeSlot]) => (
                    <div key={day} className="mb-2">
                      {timeSlot.start} - {timeSlot.end}
                    </div>
                  ))}
                  <a href="add" onClick={(event) => openAddHoursModal(event, index + 1)}>
                    {t('addSchedule')}
                  </a>
                </div>
              )}
              <Form.Check
                type="checkbox"
                id={`full-${index + 1}`}
                label={t('roundTheClock')}
                className="mt-4"
                checked={result?.schedule_items[index + 1]?.weekday_schedule_items[0]?.end === '24:00' || false}
                onChange={(e) => setFullHours(index + 1, e.target.checked)}
              />
            </>
          )}
        </Col>
      ))}

      <Modal
        show={isOpenModal}
        onHide={() => setIsOpenModal(false)}
        header={t('addWorkTimeApp') || ''}
        body={
          <Row>
            <Col>
              <InputMask mask={timeStartMask} onChange={handleTimeStart} value={timeStart}>
                <InputText label={t('startTime')} placeholder="00:00" />
              </InputMask>
            </Col>
            <Col>
              <InputMask mask={timeEndMask} onChange={handleTimeEnd} value={timeEnd}>
                <InputText label={t('endTime')} placeholder="00:00" />
              </InputMask>
            </Col>
          </Row>
        }
        footer={
          <div className="w-100 d-flex justify-content-center">
            <Button
              className="me-5"
              variant="primary"
              disabled={!isValid()}
              onClick={setHours}
              text={t('save', { ns: 'common' })}
            />
            <Button
              variant="outline"
              onClick={() => {
                setIsOpenModal(false);
                resetHours();
              }}
              text={t('cancel', { ns: 'common' })}
            />
          </div>
        }
      />
    </Row>
  );
}

export default forwardRef(ScheduleDate);
