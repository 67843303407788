import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import Button from '../Button';
import { forwardRef } from 'react';

type Props = {
  imgScr: string;
  show: boolean;
  isScanPreview?: boolean;
  isLoading?: boolean;
  onScanAgain?: () => void;
  onSuccess?: () => void;
  onClose?: () => void;
};

function ConfirmScanModal({ imgScr, show, isScanPreview, isLoading, onScanAgain, onSuccess, onClose }: Props) {
  const { t } = useTranslation(['common', 'transport']);

  const successHandler = () => {
    onSuccess && onSuccess();
  };

  const closeHandler = () => {
    if (isScanPreview && onClose) {
      onClose();
    }
  };

  return (
    <Modal
      show={show}
      hideCloseButton={!isScanPreview}
      onHide={closeHandler}
      header={t('scan_result', { ns: 'transport' }) || ''}
      body={<img className="scan-img" src={imgScr} alt="" />}
      footer={
        !isScanPreview && (
          <div className="d-flex flex-column flex-sm-row gap-3 gap-lg-4 w-100 mt-4">
            <Button type="button" text={t('confirm')} isLoading={isLoading} onClick={() => successHandler()} />
            <Button
              type="button"
              variant="outline"
              onClick={() => onScanAgain && onScanAgain()}
              text={t('scan_again')}
            />
          </div>
        )
      }
    />
  );
}

export default forwardRef(ConfirmScanModal);
