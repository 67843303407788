import { forwardRef, useRef } from 'react';
import classes from './Icon.module.scss';
import path from './icons.svg';
import classNames from 'classnames';

type IconProps = React.HTMLAttributes<HTMLElement> & {
  name: string;
  width?: number;
  height?: number;
  color?: string;
};

function Icon({ name, width, height, title, className, color, ...props }: IconProps) {
  const clsName = classNames('icon', classes.iconWrapper, className);
  const ref = useRef<HTMLElement>(null);
  return (
    <i ref={ref} className={clsName} title={title} {...props}>
      <svg className={classes.icon} width={width || 24} height={height || 24} color={color || 'currentColor'}>
        <use xlinkHref={`${path}#${name}`} />
      </svg>
    </i>
  );
}

export default forwardRef(Icon);
