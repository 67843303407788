import Country from './components/Country';
import OrgName from './components/OrgName';

function Info() {
  return (
    <div className="p-4 shadow-main rounded-4 custom-pointer bg-white d-grid gap-4 mt-2 mt-lg-0">
      <OrgName />
      <Country />
    </div>
  );
}

export default Info;
