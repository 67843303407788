import { action, makeAutoObservable } from 'mobx';
import { RootStore } from '../../store';
import Api from './api';

export class MappStore {
  rootStore: RootStore;

  mapps: Mapp[] = [];
  mapp: Mapp | null = null;
  isLoading = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  async fetchMapps(searchParams?: string, updateTotal = false) {
    this.setIsLoading(true);

    await Api.fetchMapps(searchParams)
      .then(
        action('fetchSuccess', (response: { data: Mapp[]; total: number }) => {
          this.setMapps(response.data || []);
          if (updateTotal) {
            this.rootStore.filterStore.update('total', String(response.total));
          }
        }),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  setMapps(mapps: Mapp[]) {
    this.mapps = mapps;
  }

  async fetchMappById(mappId: string) {
    this.setIsLoading(true);

    return await Api.fetchMappById(mappId)
      .then(
        action('fetchSuccess', (response: Mapp) => {
          this.setMapp(response);
          return response;
        }),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  setMapp(mapp: Mapp) {
    this.mapp = mapp;
  }

  async createMapp(mapp: AdminMapp) {
    this.setIsLoading(true);

    return await Api.createMapp(mapp)
      .then(
        action('fetchSuccess', () => true),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  async updateMapp(id: string, mapp: AdminMapp) {
    this.setIsLoading(true);

    return await Api.updateMapp(id, mapp)
      .then(
        action('fetchSuccess', () => true),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  async deleteMapp(id: string) {
    this.setIsLoading(true);

    return await Api.deleteMapp(id)
      .then(
        action('fetchSuccess', (response: { mapp: Mapp }) => response.mapp),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  async fetchTimezones() {
    this.setIsLoading(true);

    return await Api.fetchTimezones()
      .then(
        action('fetchSuccess', (response: TimezoneType[]) => response),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  get mappOptions(): SelectOptionExtended[] {
    return this.mapps.map((mapp) => ({
      label: mapp.name,
      value: mapp.id,
      ...mapp,
    }));
  }

  get mappOption(): SelectOptionExtended {
    return {
      label: this.mapp?.name || '',
      value: this.mapp?.id || '',
      ...this.mapp,
    };
  }
}
