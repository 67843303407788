import { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import InputText from '../../../../components/InputText';
import Button from '../../../../components/Button';

import { dictionaryCategories } from '../../../../static';

import rootStore from '../../../../store';
const { isFSEQ } = rootStore;

type RouterParams = {
  id: string;
};

function DictionaryCategoryList() {
  const { id } = useParams<RouterParams>();
  const navigate = useNavigate();
  const { t } = useTranslation(['dictionary', 'common']);
  const isTerminalClient = isFSEQ();

  const category = dictionaryCategories.find((category) => category.id === id);

  const [text, setText] = useState(category?.name || '');

  return (
    <Container
      className={`${!isTerminalClient ? 'flex-grow-1 ' : 'm-auto terminal-min-height transform-y-180px '}pb-3 pb-sm-5`}
    >
      <Row>
        <Col lg={isTerminalClient ? 12 : 9}>
          <Link to="/dictionary/category" className="link-back my-4">
            {t('back', { ns: 'common' })}
          </Link>
          <h2 className="mt-40px mb-4">{t('editCategory')}</h2>

          <div className="p-4 shadow-main rounded" style={{ marginBottom: '4rem' }}>
            <Row>
              <Col md={7}>
                <Row className="pb-4">
                  <Col md={6}>
                    <InputText
                      label={t('name')}
                      placeholder={t('name')}
                      value={text}
                      onChange={(e: any) => setText(e.target.value)}
                      // clear={() => onChangeHandler('grnz', '')}
                      // disabled={transportStore.isLoading}
                    />
                  </Col>
                </Row>
                <div>
                  <Button
                    type="submit"
                    variant="primary"
                    text={t('save', { ns: 'common' }) || ''}
                    className="me-4"
                    // disabled={!isValid()}
                    // isLoading={transportStore.isLoading}
                    onClick={() => navigate('/dictionary/category')}
                  />
                  <Button
                    variant="outline"
                    text={t('cancel', { ns: 'common' }) || ''}
                    onClick={() => navigate('/dictionary/category')}
                    // disabled={mappStore.isLoading}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default observer(DictionaryCategoryList);
