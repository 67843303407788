import { addDays, addYears, getMonth, getYear } from 'date-fns';
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Icon from '../components/Icon';

function Calendar() {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const years = [getYear(new Date()), getYear(addYears(new Date(), 1)), getYear(addYears(new Date(), 2))];
  const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];

  return (
    <div className="p-4 shadow-main bg-white rounded">
      <div className="skdf-calendar-container">
        <DatePicker
          locale="ru"
          selected={startDate}
          renderCustomHeader={({ date, changeYear, changeMonth }) => (
            <div className="mb-1 d-flex justify-content-center">
              <Dropdown align="end">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-language"
                  className="bg-transparent border-0 d-flex gap-2 p-2 lh-base text-primary"
                >
                  {months[getMonth(date)]}
                  <Icon name="small_down" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {months.map((option, index) => (
                    <Dropdown.Item key={option} value={option} onClick={() => changeMonth(Number(index))}>
                      {option}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown align="end">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-language"
                  className="bg-transparent border-0 d-flex gap-2 p-2 lh-base text-primary"
                  value={getYear(date)}
                >
                  {getYear(date)}
                  <Icon name="small_down" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {years.map((option) => (
                    <Dropdown.Item key={option} value={option} onClick={() => changeYear(Number(option))}>
                      {option}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          minDate={new Date()}
          excludeDates={[addDays(new Date(), 5), addDays(new Date(), 8)]}
          onChange={(date) => setStartDate(date)}
          inline
        />
      </div>
    </div>
  );
}

export default Calendar;
