import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';

import store from '../../../../../store';
import Select from '../../../../../components/Select';
import { useState } from 'react';
const { bookingStore } = store;

type Props = {
  show: boolean;
  booking: Booking | null;
  bookingStatuses: SelectOption[];
  onClose: () => void;
  onSuccess: () => void;
};

function ChangeStatusModal({ show, booking, bookingStatuses, onClose, onSuccess }: Props) {
  const { t } = useTranslation(['booking', 'common']);
  const [status, setStatus] = useState('');

  if (booking === null) return null;

  const updateStatusHandler = async (booking: Booking) => {
    const data = {
      id: booking.id,
      status: status,
    };
    const isSuccessChange = await bookingStore.changeStatus(data);

    if (isSuccessChange) {
      handleClose();
      onSuccess();
    }
  };

  const handleClose = () => {
    setStatus('');
    onClose();
  };

  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      header={t('changing_status') || ''}
      body={
        <div style={{ height: '350px' }}>
          <Select
            menuShouldScrollIntoView={false}
            isSearchable
            placeholder={t('not_chosen', { ns: 'booking' })}
            options={bookingStatuses}
            formatOptionLabel={({ label }) => <>{t(label, { ns: 'status' })}</>}
            value={bookingStatuses.find((option) => option.value === status)}
            onChange={(e) => e?.value && setStatus(e.value)}
          />
        </div>
      }
      footer={
        <div className="d-flex flex-column flex-sm-row gap-3 gap-lg-4 w-100">
          <Button
            text={t('confirm', { ns: 'common' })}
            onClick={() => updateStatusHandler(booking)}
            disabled={!status}
          />
          <Button variant="outline" onClick={() => handleClose()} text={t('cancel', { ns: 'common' })} />
        </div>
      }
    />
  );
}

export default ChangeStatusModal;
