import { NavLink, useRoutes } from 'react-router-dom';
import Buttons from './Buttons';
import Icons from './Icons';
import Inputs from './Inputs';
import Shadows from './Shadow';
import Spacing from './Spacing';
import Typography from './Typography';
import Modal from './Modal';
import Selects from './Selects';
import Checkbox from './Checkbox';
import Calendar from './Calendar';
import QuizCards from './QuizCards';
import Toasts from './Toasts';
import Paginations from './Pagination';

const pages = [
  {
    label: 'Кнопки',
    path: 'Buttons',
    element: <Buttons />,
  },
  {
    label: 'Поля ввода',
    path: 'Inputs',
    element: <Inputs />,
  },
  {
    label: 'Типография',
    path: 'Typography',
    element: <Typography />,
  },
  {
    label: 'Отсутпы',
    path: 'Spacing',
    element: <Spacing />,
  },
  {
    label: 'Тени',
    path: 'Shadows',
    element: <Shadows />,
  },
  {
    label: 'Модальное окно',
    path: 'Modal',
    element: <Modal />,
  },
  {
    label: 'Селекты',
    path: 'Selects',
    element: <Selects />,
  },
  {
    label: 'Иконки',
    path: 'Icons',
    element: <Icons />,
  },
  {
    label: 'Checkbox',
    path: 'Checkbox',
    element: <Checkbox />,
  },
  {
    label: 'Календарь',
    path: 'Calendar',
    element: <Calendar />,
  },
  {
    label: 'Квизы',
    path: 'QuizCards',
    element: <QuizCards />,
  },
  {
    label: 'Toasts',
    path: 'Toasts',
    element: <Toasts />,
  },
  {
    label: 'Paginations',
    path: 'Paginations',
    element: <Paginations />,
  },
];

function SkdfDesignRoutes() {
  return useRoutes([...pages]);
}

function UiKit() {
  const page = (page: { label: string; path: string; element: JSX.Element }) => (
    <NavLink key={page.path} to={page.path} className="nav-link me-2">
      {page.label}
    </NavLink>
  );

  return (
    <div className="container flex-grow-1">
      <h1 className="mb-3" style={{ marginTop: '2.5rem' }}>
        UiKit
      </h1>
      <div className="mt-3 mb-3">
        <nav className="nav nav-pills">{pages.map(page)}</nav>
        <hr className="mt-3 mb-3" />
        <SkdfDesignRoutes />
      </div>
    </div>
  );
}

export default UiKit;
