import { useTranslation } from 'react-i18next';

function TableHead() {
  const { t } = useTranslation('dictionary');

  return (
    <div className="d-flex">
      <div className="flex-grow-1 flex-shrink-0 text-secondary fs-7">{t('name')}</div>
      {/* <div style={{ width: '10%' }} className="flex-shrink-0 text-secondary fs-7">
        {t('add')}
      </div>
      <div style={{ width: '10%' }} className="flex-shrink-0 text-secondary fs-7 ms-4">
        {t('changed')}
      </div> */}
    </div>
  );
}

export default TableHead;
