import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation('footer');
  const currentYear = new Date().getFullYear();
  return (
    <footer className="fs-7 text-center p-3 w-100 text-muted bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 text-md-start text-sm-center">
            {' '}
            © {t('title')}, {currentYear}
          </div>
          <div className="col-md-6 col-sm-12 text-md-end text-sm-center">
            {t('support')}
            <a href="mailto:srv-help@z-it.ru" className="ms-2 text-muted lh-base">
              srv-help@z-it.ru
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
